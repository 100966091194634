import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { API_URL } from 'lib/constants';
import { refreshToken } from 'lib/helpers/refreshToken';
import { getTokenFromCookies } from 'lib/services/cookieService';
import queryString from 'query-string';
import { RootState } from 'store';

export const baseQuery = fetchBaseQuery({
  baseUrl: API_URL.baseUrl,
  fetchFn: async (input, init) => {
    let response = await fetch(input, init);
    const clonedResponse = response.clone();
    const clonedBody = await clonedResponse.json().catch(() => undefined);

    if (
      clonedBody &&
      clonedBody.statusCode === 401 &&
      clonedBody.message === 'Истек срок жизни токена'
    ) {
      const token = await refreshToken();

      if (token) {
        response = await fetch(input, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
    }

    return response;
  },
  paramsSerializer: (params) => queryString.stringify(params),
  prepareHeaders: (headers, { getState }) => {
    const substitutedToken = headers.get('authorization');

    if (substitutedToken) return headers;

    const tokenFromCookies = getTokenFromCookies();

    if (tokenFromCookies) {
      headers.set('authorization', `Bearer ${tokenFromCookies}`);
      return headers;
    }

    const { user } = (getState() as RootState).auth;
    const tokenFromState = user?.token;

    if (tokenFromState) {
      headers.set('authorization', `Bearer ${tokenFromState}`);
      return headers;
    }

    return headers;
  },
});
