import EventEmitter2 from 'eventemitter2';
import { sliceDataView, wait } from 'lib/helpers';
import {
  BluetoothConnection,
  Handler,
} from 'lib/services/bluetooth/bluetooth-connection';
import {
  Characteristics,
  uuidsByCharacteristicMap,
} from 'lib/services/constants';

enum EventTypes {
  NEW_REQUEST = 'new_request',
  END_REQUEST = 'end_request',
}

/**
 * @interface
 * @property command - бинарное представление команды.
 * @property resolve - функция резолв промиса.
 * @property reject - функция реджект промиса.
 * @property withoutResponse - флаг нужно ли ждать ответ на команду.
 */
interface IQueueItem {
  command: Uint8Array;
  resolve: (...args: any[]) => void;
  reject: (...args: any[]) => void;
  withoutResponse?: boolean;
}

/**
 * Класс инкапсулирует в себе работу с Nordic портом.
 *
 * Внутри реализованна очередь команд. Одновременно может выполнятся только одна команда.
 * Каждая команда промисифицируется для удобства клиентского кода. Для некоторых операций
 * используется запрос к характеристикам GATT(например getPuffCount, subscribePuffCount)
 *
 * Главный метод в интерфейсе sendRequest. С него запускается процесс обработки команды.
 *
 * Для обработки команд есть два метода: handleCommand и handleComplexCommand.
 *
 * handleComplexCommand используется только для команды getPuffArray.
 * Тк у команды нестандартный ответ с несколькими уведомлениями и маркером конца передачи.
 * Для остальных команд используется handleCommand.
 *
 * Для управления очередью используется eventEmitter. Управления
 * осуществляется на основе двух событий EventTypes.NEW_REQUEST, EventTypes.END_REQUEST и
 * флага isBusy.
 *
 * Для защиты от забивания очереди в обработке каждой команды присутствует таймер
 * на 3 секунды. Если на команду не был получен ответ за это время, ожидание ответа
 * прекращается, вызывается реджект промиса и переход к следующей команде.
 *
 * Если для команды не ожидается ответ, необходимо в метод sendRequest добавить
 * флаг withoutResponse.
 *
 * При переподключении устройства обновляются TX и RX характеристики.
 */
export class PlonqNordicConnection {
  private bluetoothConnection: BluetoothConnection;
  private rx;
  private tx;

  // Для управления очередью с помощью событий
  private emitter = new EventEmitter2();
  // Очередь для обработки не более одной команды единовременно
  private queue: IQueueItem[] = [];
  // Флаг запущенной команды
  private isBusy = false;

  constructor(bluetoothConnection: BluetoothConnection) {
    this.bluetoothConnection = bluetoothConnection;
    this.rx = this.getCharacteristic(Characteristics.NORDIC_RX);
    this.tx = this.getCharacteristic(Characteristics.NORDIC_TX);

    // Подписки на события начала и конца обработки команд
    this.emitter.on(EventTypes.NEW_REQUEST, this.handleNewRequest.bind(this));
    this.emitter.on(EventTypes.END_REQUEST, this.handleEndRequest.bind(this));
  }

  /**
   * Получение количества записей о затяжках.
   * Значение берется из GATT характеристики puffCounter.
   * @returns {number} Количество записей затяжках.
   */
  public async getPuffCount(): Promise<number> {
    const characteristic = await this.getCharacteristic(
      Characteristics.PUFF_COUNTER,
    );

    const value = await characteristic.readValue();

    return value.getUint16(0, true);
  }

  /**
   * Подписка на изменение количества записей о затяжках.
   * Фактически подписка идет на GATT характеристику puffCounter.
   *
   * @param handler - обработчик события изменения количества записей о затяжках.
   */
  public async subscribePuffCount(handler: (puffCount: number) => void) {
    const characteristic = await this.getCharacteristic(
      Characteristics.PUFF_COUNTER,
    );

    await characteristic.startNotifications();

    const eventHandler = (event: any) => {
      if (!event.target) {
        return;
      }

      handler(event.target.value.getUint16(0, true));
    };

    characteristic.addEventListener('characteristicvaluechanged', eventHandler);
  }

  /**
   * Отписка от изменение количества записей о затяжках.
   *
   * @param handler - зарегистрированный обработчик события изменения количества записей о затяжках.
   */
  public async unsubscribePuffCount(handler: () => void) {
    const characteristic = this.getCharacteristic(Characteristics.PUFF_COUNTER);

    await characteristic.stopNotifications();
    characteristic.removeEventListener('characteristicvaluechanged', handler);
  }

  // Добавление команды в очередь
  /**
   * Главный метод интерфейса класса.
   * Оборачивает события в промисы для удобства клиентского кода.
   * Для команд без ответа предусмотрен режим без ожидания ответа.
   *
   * @example
   * const childLockSettingsBinary = (await this.bleConnection.sendRequest(
   *   getChildLockSettingsCommand(),
   * )) as DataView;
   *
   * const childLockSettings = childLockSettingsResponse(
   *  childLockSettingsBinary,
   * )
   *
   * @param command - бинарное представление команды.
   * @param withoutResponse - флаг ожидания ответа, для команда без ответа устанавливать в true.
   * @returns Промис который зарезолвиться когда команда будет обработана.
   */
  public async sendRequest(command: Uint8Array, withoutResponse?: boolean) {
    return new Promise((resolve, reject) => {
      this.queue.push({ command, resolve, reject, withoutResponse });
      this.emitter.emit(EventTypes.NEW_REQUEST);
    });
  }

  /**
   * Метод обработки команд.
   * Для команд без ответа добавлено задержка в 100мс для
   * защиты GATT Server от перегруза.
   *
   * Для команд с ответом добавлен таймер в 3 секунды после
   * которого ожидание ответа прерывается. Добавлено для защиты
   * очереди от ошибочных команд.
   *
   * Логгирует в консоль время ожидания ответа на команду от устройства. (request time)
   *
   * После получения ответа на команду от устройства, полученный ответ резолвится в промисе.
   *
   * @param queueItem
   * @returns void
   */
  private async handleCommand(queueItem: IQueueItem): Promise<void> {
    if (queueItem.withoutResponse) {
      await this.rx.writeValue(queueItem.command);

      // Необходимая задержка для корректной работы на мобильных устройствах
      await wait(100);

      this.emitter.emit(EventTypes.END_REQUEST);
      return queueItem.resolve();
    }

    // Для корректной работы в PLONQ браузере необходимо делать для каждой в отдельности
    const characteristic = await this.tx.startNotifications();
    // Флаг полученного ответа
    let isAnswered = false;

    // Замер времени для мониторинга производительности
    const date = Date.now();

    const eventListener = async (event: any) => {
      isAnswered = true;

      console.log('request time: ', Date.now() - date);

      characteristic.removeEventListener(
        'characteristicvaluechanged',
        eventListener,
      );

      await characteristic.stopNotifications();

      this.emitter.emit(EventTypes.END_REQUEST);
      return queueItem.resolve(event.target.value);
    };

    // Сброс команды из очереди если устройство не отвечает
    setTimeout(async () => {
      if (!isAnswered) {
        characteristic.stopNotifications();
        characteristic.removeEventListener(
          'characteristicvaluechanged',
          eventListener,
        );

        this.emitter.emit(EventTypes.END_REQUEST);
        queueItem.reject();
      }
    }, 3000);

    characteristic.addEventListener(
      'characteristicvaluechanged',
      eventListener,
    );

    await this.rx.writeValue(queueItem.command);
  }

  /**
   * Метод для обработки команд с нестандартным ответом.
   * На данный момент используется только для getPuffArray команды,
   * тк у нее множественный ответ с маркером конца передачи.
   *
   * В остальном метод идентичен handleCommand.
   *
   * @param queueItem
   */
  private async handleComplexCommand(queueItem: IQueueItem): Promise<void> {
    try {
      const characteristic = await this.tx.startNotifications();
      let isAnswered = false;
      const response: DataView[] = [];

      const eventListener = async (event: any) => {
        isAnswered = true;

        const lastSevenBytes = new DataView(
          event.target.value.buffer.slice(-7),
        );

        if (this.isTransferEndMarker(lastSevenBytes)) {
          characteristic.removeEventListener(
            'characteristicvaluechanged',
            eventListener,
          );

          await characteristic.stopNotifications();
          this.emitter.emit(EventTypes.END_REQUEST);
          return queueItem.resolve(response);
        }

        response.push(event.target.value);
      };

      // Сброс команды из очереди если устройство не отвечает
      setTimeout(async () => {
        if (!isAnswered) {
          characteristic.stopNotifications();
          characteristic.removeEventListener(
            'characteristicvaluechanged',
            eventListener,
          );

          this.emitter.emit(EventTypes.END_REQUEST);
          queueItem.reject();
        }
      }, 3000);

      characteristic.addEventListener(
        'characteristicvaluechanged',
        eventListener,
      );

      await this.rx.writeValue(queueItem.command);
    } catch (err) {
      console.log('[HANDLE_COMPLEX_COMMAND]: error', err);
      this.emitter.emit(EventTypes.END_REQUEST);
      queueItem.reject(err);
    }
  }

  private getCharacteristic(
    characteristicName: Characteristics,
  ): BluetoothRemoteGATTCharacteristic {
    const connectionUUIDs = uuidsByCharacteristicMap[characteristicName];
    const characteristicId = connectionUUIDs[1];

    const characteristic =
      this.bluetoothConnection.getGATTCharacteristic(characteristicId);

    if (!characteristic) {
      throw new Error(`Cannot find ${characteristicName} characteristic`);
    }

    return characteristic;
  }

  /**
   * Метод для управления очередью.
   * Решает можно ли запустить новую команду или
   * отправить ее в очередь по флагу isBusy.
   */
  private handleNewRequest() {
    if (this.isBusy) {
      return;
    }

    const command = this.queue.shift();

    if (command) {
      this.isBusy = true;

      // Проверка на команду getPuffArray
      return command.command[0] === 0xfa
        ? this.handleComplexCommand(command)
        : this.handleCommand(command);
    }
  }

  /**
   * Метод управления очередью.
   * После завершения обработки команды запускает следующею
   * из очереди если она не пуста. Если очередь пуста флаг isBusy
   * устанавливается в false.
   */
  private handleEndRequest() {
    const command = this.queue.shift();

    if (command) {
      return this.handleCommand(command);
    }

    return (this.isBusy = false);
  }

  public onDisconnect(disconnectHandler: Handler): void {
    this.bluetoothConnection.onDisconnect(disconnectHandler);
  }

  public onReconnect(reconnectHandler: Handler): void {
    this.bluetoothConnection.onReconnect(() => {
      // Обновление характеристик для нового GATT сервера
      this.rx = this.getCharacteristic(Characteristics.NORDIC_RX);
      this.tx = this.getCharacteristic(Characteristics.NORDIC_TX);

      reconnectHandler();
    });
  }

  /**
   * Метод для мануального обновления подключения.
   * Нужен для устранения повышенного времени обработки команд
   * на устройстве.
   */
  public async refreshConnection(): Promise<void> {
    await this.bluetoothConnection.refreshGattConnection();

    this.rx = this.getCharacteristic(Characteristics.NORDIC_RX);
    this.tx = this.getCharacteristic(Characteristics.NORDIC_TX);
  }

  private isTransferEndMarker(dataView: DataView) {
    for (let i = dataView.byteLength - 7; i < dataView.byteLength; ++i) {
      if (dataView.getUint8(i) !== 255) {
        return false;
      }
    }

    return true;
  }

  private cutTransferEndMarker(dataView: DataView): DataView {
    return sliceDataView(dataView, dataView.byteLength - 7);
  }
}
