import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { PlonqDeviceIcon } from 'assets/svg/components';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { withRedirect } from 'lib/hocs';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import styled from 'styled-components';
import { Button, Logo } from 'ui';

import { useFeatureToggles } from '../../../components/FeatureToogleContext';
import { REGISTATION_FEATURE_TOGGLE } from '../../../lib/constants';

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 40px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.title1}
  margin-bottom: 29px;
  color: ${COLORS.darkBlue};
`;

const StyledPlonqDeviceIcon = styled(PlonqDeviceIcon)`
  margin-bottom: 55px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 17px;
`;

const StyledLink = styled(Link)`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
  transition: color 0.2s;

  &:hover,
  &:active {
    color: ${COLORS.black};
  }
`;
const Intro = () => {
  const navigate = useNavigate();
  const { isEnabledFeature } = useFeatureToggles();
  const isRegistrationEnabled = isEnabledFeature(REGISTATION_FEATURE_TOGGLE);

  return (
    <MainLayout>
      <ContentWrapper>
        <Header>
          <Logo />
        </Header>

        <Description>
          Приложение, которое поможет контролировать потребление никотина
        </Description>
        <StyledPlonqDeviceIcon />
        {!isRegistrationEnabled ? (
          <StyledButton
            onClick={() => navigate(ROUTES.LOGIN)}
            variant="primary"
          >
            Войти
          </StyledButton>
        ) : (
          <StyledButton
            onClick={() => navigate(ROUTES.REGISTRATION)}
            variant="primary"
          >
            Создать профиль
          </StyledButton>
        )}
        {isRegistrationEnabled && (
          <StyledLink to={ROUTES.LOGIN}>Войти</StyledLink>
        )}
      </ContentWrapper>
    </MainLayout>
  );
};

export default withRedirect(Intro);
