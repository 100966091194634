import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui';
import { ChildLockTimerValue } from 'ui/Menu/components/ChildLockTimerControl';

export interface ChildLockTimerProps {
  onClose: () => void;
  className?: string;
  timer?: ChildLockTimerValue;
  onChange: (value: ChildLockTimerValue) => void;
}

/**
 * Компонент дропдауна для функции ChildLock в Меню с
 * информацией о блокировке/разблокировке по таймеру.
 */
export const ChildLockTimer: FC<ChildLockTimerProps> = ({
  className,
  onClose,
  timer = '600',
  onChange,
}) => {
  const handleChangeChildLockTimer = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();
      event.stopPropagation();

      onChange(event.target.value as ChildLockTimerValue);
    },
    [onChange],
  );

  return (
    <Root className={className}>
      <CloseBtn onClick={onClose}>
        <Icon type="close" />
      </CloseBtn>
      <Title>
        Данная функция позволяет включать и выключать режим блокировки девайса
        по таймеру в целях защиты от использования устройства детьми.
      </Title>
      <Description>
        Выберите время, через которое устройство будет блокироваться.
      </Description>
      <select value={timer} onChange={handleChangeChildLockTimer}>
        <option value="300">5 минут</option>
        <option value="600">10 минут</option>
        <option value="900">15 минут</option>
      </select>
    </Root>
  );
};

const Root = styled.div`
  z-index: 200;
  width: 100%;
  max-width: 348px;
  padding: 18px 42px 11px 42px;
  background-color: ${COLORS.white};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 1px solid ${COLORS.lightBlue};
`;

const Title = styled.h4`
  ${TYPOGRAPHY.subline1};
  color: ${COLORS.black};
  text-align: center;
  margin-bottom: 10px;
  max-width: 265px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.subline1};
  color: ${COLORS.black};
  text-align: center;
  max-width: 270px;
  margin-bottom: 10px;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 7px;
  right: 7px;
`;
