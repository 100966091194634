import { ChildLockCartridgeInfo } from 'components/ChildLockCartridgeInfo';
import { useOnClickOutside } from 'lib/hooks';
import useChildLockSettings from 'lib/services/plonq/hooks/useChildLockSettings';
import { ChildLockSettings } from 'lib/services/plonq/plonq-child-lock';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui';
import { SwitchIcon } from 'ui/SwitchIcon';

export interface OverPuffControlProps {
  className?: string;
  isEnabled?: boolean;
}

export const ChildLockCartridgeControl: FC<OverPuffControlProps> = ({
  className,
  isEnabled = false,
}) => {
  const [isOpenEditLimit, setIsOpenEditLimit] = useState(false);
  const overPuffControlWrapperRef = useRef(null);
  useOnClickOutside(overPuffControlWrapperRef, () => setIsOpenEditLimit(false));
  const [{ getSettings, setSettings }, hasChildLock] = useChildLockSettings();

  const [childLockSettings, setChildLockSettings] =
    useState<ChildLockSettings>();

  const [isChecked, setIsChecked] = useState<boolean>(isEnabled);

  const handleGetChildLockSettings = async () => {
    const settings = await getSettings();

    setIsChecked(settings.cartridge);
    setChildLockSettings(settings);
  };

  const handleSetChildLockSettings = async (activate: boolean) => {
    if (childLockSettings) {
      const settings = await setSettings({
        ...childLockSettings,
        cartridge: activate,
      });

      setIsChecked(settings.cartridge);
      setChildLockSettings(settings);
    }
  };

  const toggleChildLockTimer = () => {
    if (isChecked) {
      handleSetChildLockSettings(false);
    } else {
      handleSetChildLockSettings(true);
    }
  };

  useEffect(() => {
    handleGetChildLockSettings();
  }, []);

  return (
    /* eslint-disable no-constant-condition */
    <Root
      disabled={!hasChildLock}
      className={className}
      ref={overPuffControlWrapperRef}
    >
      <StyledSwitch
        label="Child lock картридж"
        onChange={toggleChildLockTimer}
        checked={isChecked}
        enabledIcon="childLockSettingsEnabled"
        disabledIcon="childLockSettingsDisabled"
      />
      <button onClick={() => setIsOpenEditLimit(!isOpenEditLimit)}>
        <Icon type="questionMark" />
      </button>
      {isOpenEditLimit && (
        <EditLimitWrapper>
          <ChildLockCartridgeInfo
            onClose={() => setIsOpenEditLimit(!isOpenEditLimit)}
          />
        </EditLimitWrapper>
      )}
    </Root>
  );
};

const Root = styled.div<{ disabled: boolean }>`
  height: 56px;
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  align-items: center;
  padding-right: 2px;
  flex-wrap: wrap;
  position: relative;
  border-bottom: 1px solid #f2f1f7;
`;

const StyledSwitch = styled(SwitchIcon)`
  flex: 1 1 auto;
`;

const EditLimitWrapper = styled.div`
  position: absolute;
  top: calc(100%);
  left: 0;
`;
