import { ROTATE_RIGHT } from 'lib/constants';
import { FC } from 'react';
import styled from 'styled-components';

import { Icon } from '../Icon/Icon';

export const Spinner: FC = () => {
  return (
    <Root>
      <SpinnerIcon type="spinner" />
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpinnerIcon = styled(Icon)`
  animation: ${ROTATE_RIGHT} 1s linear infinite;
`;
