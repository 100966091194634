import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import styled from 'styled-components';
import { Button, Icon, Logo } from 'ui';

export const StepRestoreSuccess = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header>
        <Logo />
      </Header>
      <StyledIcon type="checkInCircle" />
      <Description>Пароль успешно изменён</Description>

      <StyledButton
        type="submit"
        variant="primary"
        onClick={() => navigate(ROUTES.LOGIN)}
      >
        Войти
      </StyledButton>
    </>
  );
};

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 167px;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 18px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.title1}
  color: ${COLORS.darkBlue};

  max-width: 222px;
  margin-bottom: 218px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 18px;
`;
