import { ERROR_TEXTS } from 'lib/constants';
import { useAuth } from 'lib/hooks';
import useDeviceConnect from 'lib/services/plonq/hooks/useDeviceConnect';
import useDeviceRequest from 'lib/services/plonq/hooks/useDeviceRequest';
import useDeviceState from 'lib/services/plonq/hooks/useDeviceState';
import { PlonqDevice } from 'lib/services/plonq/plonq-device';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import { usePostConnectionMutation } from 'store/api/auth';
import {
  useAttachDeviceMutation,
  useDetachDeviceMutation,
  useGetDeviceListQuery,
  useSyncDeviceInfoMutation,
} from 'store/api/devices';
import { useGetMeQuery } from 'store/api/users';
import { logOut } from 'store/features/auth';
import styled from 'styled-components';
import { CustomError } from 'types';
import {
  BluetoothModal,
  ErrorDrawer,
  LogOutModal,
  NotifyModal,
  Spinner,
} from 'ui';

import useDeviceTimestamp from '../../lib/services/plonq/hooks/useDeviceTimestamp';
import { Connection, Devices } from './components';

/**
 * Начальный страница со авторизированного пользователя.
 * Доступна только для авторизированных пользователей.
 *
 * Содержит экраны: списка устройств({@link Devices}), подключения({@link Connection})
 *
 * Отвечает за логику: добавления нового устройства, подключения к устройству, отвязки устройства.
 */
export const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useAuth();
  const [isBluetoothModalOpen, setIsBluetoothModalOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isDeviceConnectLoading, setIsDeviceConnectLoading] = useState(false);
  const [isConnectionShowed, setIsConnectionShowed] = useState(false);
  const [errorModalText, setErrorModalText] = useState('');
  const [errorDrawerText, setErrorDrawerText] = useState('');
  const [deviceState, setDeviceState] = useDeviceState();
  const hasModalError = Boolean(errorModalText);
  const hasDrawerError = Boolean(errorDrawerText);
  const [setDeviceCurrentTimestamp, hasSetDeviceTimestamp] =
    useDeviceTimestamp();

  const { data: user, isLoading: isUserLoading } = useGetMeQuery(null);
  const {
    data: dataDevices,
    isLoading: isDeviceListLoading,
    refetch,
  } = useGetDeviceListQuery();
  const requestDevice = useDeviceRequest();
  const connectDevice = useDeviceConnect();
  const [syncDeviceInfo] = useSyncDeviceInfoMutation();

  const [createConnection, { isLoading: isConnectionCreating }] =
    usePostConnectionMutation();

  const [attachDevice, { isLoading: isDeviceAttaching }] =
    useAttachDeviceMutation();

  const [detachDevice, { isLoading: isDeviceDetaching }] =
    useDetachDeviceMutation();

  const isPageLoading = isUserLoading || isDeviceListLoading;

  const isLoading =
    isDeviceAttaching ||
    isDeviceDetaching ||
    isDeviceConnectLoading ||
    isConnectionCreating;
  const devices = dataDevices?.devices;

  /**
   * Сброс bluetooth подключения.
   * Используется для отвязки устройства, иначе повторного подключение будет невозможно.
   */
  const disconnectBluetooth = () => {
    PlonqDevice.getInstance().disconnect();
  };

  /**
   * Обработка подключения нового устройства.
   *
   * Обращается к bluetoothApi и вызывает метод requestDevice с
   * фильтром на устройства PLONQ.
   *
   * Получает экземпляр {@link PlonqDevice} и сохраняет его в стейт.
   *
   * После подключения устройства открывает экран привязки устройства.
   *
   * Вещается как обработчик на кнопку добавления нового устройства.
   */
  const handleDeviceRequest = async () => {
    try {
      setIsDeviceConnectLoading(true);
      const device = await requestDevice();

      setDeviceState(device);

      const isCurrentDeviceAttached = devices?.some((deviceFromStore) => {
        return deviceFromStore?.macAddress === device?.getMacAddress();
      });

      setIsConnectionShowed(!isCurrentDeviceAttached);
    } catch (error) {
      setErrorModalText(ERROR_TEXTS.cantConnectToDevice);
    } finally {
      setIsDeviceConnectLoading(false);
    }
  };

  /**
   * Обработка подключения к устройству пользователя.
   *
   * Обращается к bluetoothApi и вызывает метод requestDevice с
   * фильтром на устройства PLONQ + имя устройства.
   *
   * Получает экземпляр {@link PlonqDevice}.
   * Создает запись о новой сессии подключения.
   * Синхронизует информацию о версии прошивки устройства,
   * необходимо для актуализации после обновления прошивки.
   *
   * После подключения устройства открывает экран статистики.
   *
   * Вещается как обработчик на кнопку подключения к устройству.
   */
  const handleDeviceConnect = async (id: string, name: string) => {
    try {
      setIsDeviceConnectLoading(true);
      const device = await connectDevice(name);
      await createConnection({ deviceId: id });
      await syncDeviceInfo({
        deviceId: id,
        firmwareVersion: device.getFirmware(),
      });

      // if (hasSetDeviceTimestamp) {
      setDeviceCurrentTimestamp();
      // }

      navigate(ROUTES.DEVICE_ID(id));
    } catch (error) {
      setErrorModalText(ERROR_TEXTS.cantConnectToDevice);
    } finally {
      setIsDeviceConnectLoading(false);
    }
  };

  /**
   * Сброс подключения.
   * Используется при отмене привязки устройства.
   * Необходимо применить для сброса подключения, иначе повторно
   * подключиться к устройству будет невозможно.
   *
   * Вещается как обработчик на кнопку отмены подключения.
   */
  const handleCancelConnection = () => {
    disconnectBluetooth();
    setIsConnectionShowed(false);
  };

  /**
   * Обработка привязки нового устройства.
   *
   * Отправляет на сервер информацию о новом устройстве.
   * После перезапрашивает список устройств для обновления списка.
   *
   * Используется после подтверждения пользователем привязки устройства.
   */
  const handleAttachDevice = async () => {
    try {
      if (!deviceState) {
        return setErrorModalText(ERROR_TEXTS.deviceIsNotConnected);
      }

      if (!isAuth) {
        return setErrorModalText(ERROR_TEXTS.userNotAuthorized);
      }

      await attachDevice({
        name: deviceState.name,
        modelName: deviceState.modelName,
        macAddress: deviceState.macAddress,
        firmwareVersion: deviceState.firmwareVersion,
        hardwareVersion: deviceState.hardwareVersion,
      }).unwrap();

      disconnectBluetooth();

      refetch();
    } catch (err) {
      const error = err as CustomError;

      setErrorDrawerText(error.data.message);
      disconnectBluetooth();
    } finally {
      setIsConnectionShowed(false);
    }
  };

  /**
   * Обработка отвязки нового устройства.
   *
   * Отправляет на сервер запрос об удалении устройства из списка.
   * После перезапрашивает список устройств для обновления списка.
   *
   * Вещается как обработчик на кнопку отвязки устройства.
   *
   * @param deviceId - id отвязываемого устройства.
   */
  const handleDetachDevice = async (deviceId: string) => {
    try {
      if (!isAuth) {
        return setErrorModalText(ERROR_TEXTS.userNotAuthorized);
      }

      await detachDevice({ deviceId }).unwrap();
      refetch();
    } catch (err) {
      const error = err as CustomError;

      setErrorDrawerText(error.data.message);
      disconnectBluetooth();
    }
  };

  /**
   * Обработка выхода из профиля.
   *
   * Вещается как обработчик на кнопку логаута.
   */
  const handleLogout = () => {
    dispatch(logOut());
    navigate(ROUTES.INTRO);
    disconnectBluetooth();
  };

  if (isPageLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      {isConnectionShowed ? (
        <Connection
          cartridgeId={deviceState?.cartridgeId}
          onTimerEnd={() => setIsConnectionShowed(false)}
          onOwnerConfirmed={handleAttachDevice}
          onCancel={handleCancelConnection}
        />
      ) : (
        <Devices
          username={user?.username}
          phone={user?.phone}
          devices={devices}
          isLoading={isLoading}
          onNewDeviceAddClick={handleDeviceRequest}
          onDeviceClick={handleDeviceConnect}
          onLogoutClick={() => setIsLogoutModalOpen(true)}
          onDeleteDevice={handleDetachDevice}
        />
      )}

      <LogOutModal
        isOpen={isLogoutModalOpen}
        closeCallback={() => setIsLogoutModalOpen(false)}
        onYesCallback={handleLogout}
      />

      <BluetoothModal
        isOpen={isBluetoothModalOpen}
        closeCallback={() => setIsBluetoothModalOpen(false)}
      />

      <ErrorDrawer
        text={errorDrawerText}
        isOpen={hasDrawerError}
        closeCallBack={() => setErrorDrawerText('')}
      />

      <NotifyModal
        text={errorModalText}
        isOpen={hasModalError}
        closeCallback={() => setErrorModalText('')}
      />
    </>
  );
};

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--100vh-height);
`;
