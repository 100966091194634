import './wheel.scss';

import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import dayjs from 'dayjs';
import { FORMATS, INPUT_MASKS } from 'lib/constants';
import { getDateFromString } from 'lib/helpers';
import { ChangeEvent, InputHTMLAttributes, forwardRef, useState } from 'react';
import { useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import DatePicker from 'react-mobile-datepicker';
import styled from 'styled-components';
import { Icon } from 'ui/Icon/Icon';

export interface DatePickerFormProps
  extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  isDisabled?: boolean;
  error?: string;
  className?: string;
  defaultDateString?: string;
  control: Control<any, any>;
}

export const DatePickerForForm = forwardRef<
  HTMLInputElement,
  DatePickerFormProps
>(
  (
    {
      placeholder,
      isDisabled = false,
      error,
      className,
      control,
      defaultDateString,
    },
    ref,
  ) => {
    const [isWheelOpen, setIsWheelOpen] = useState(false);
    const datePickerRef = useRef<HTMLDivElement>(null);

    const hasError = Boolean(error);
    const dateNow = new Date();
    const defaultValueDateDayJs = getDateFromString(defaultDateString);
    const defaultValueDate = defaultValueDateDayJs.toDate();
    const isDefaultValueValid = defaultValueDateDayJs.isValid();

    const [dateForDatePicker, setDateForDatePicker] = useState(
      isDefaultValueValid ? defaultValueDate : dateNow,
    );

    const handleInputMaskChange = (event: ChangeEvent<HTMLInputElement>) => {
      const dateString = event.target.value;
      const dayJsDate = getDateFromString(dateString);
      const isValidDate = dayJsDate.isValid();

      if (isValidDate) {
        setDateForDatePicker(dayJsDate.toDate());
      }
    };

    const onClose = () => {
      setIsWheelOpen(false);
    };

    return (
      <Controller
        control={control}
        name="age"
        render={({ field }) => {
          return (
            <Wrapper
              className={`datepicker__container ${className}`}
              ref={datePickerRef}
            >
              {hasError && <ErrorText>{error}</ErrorText>}
              <Label>
                <StyledInputMask
                  mask={INPUT_MASKS.date}
                  placeholder={placeholder}
                  id="age"
                  type="text"
                  $hasError={hasError}
                  disabled={isDisabled}
                  {...field}
                  inputRef={ref}
                  onChange={(event) => {
                    field.onChange(event);
                    handleInputMaskChange(event);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') onClose();
                  }}
                />

                {isWheelOpen && (
                  <DatePicker
                    name="picker"
                    value={dateForDatePicker || dateNow}
                    isOpen={true}
                    isPopup={false}
                    theme={'ios'}
                    min={new Date(1900, 0, 1)}
                    max={dateNow}
                    dateConfig={{
                      date: {
                        format: 'DD',
                        caption: 'Day',
                        step: 1,
                      },
                      month: {
                        format: 'MM',
                        caption: 'Mon',
                        step: 1,
                      },
                      year: {
                        format: 'YYYY',
                        caption: 'Year',
                        step: 1,
                      },
                    }}
                    confirmText={''}
                    cancelText={''}
                    showHeader={false}
                    onChange={(date: Date) => {
                      const dateValue = dayjs(date)
                        .format(FORMATS.date)
                        .toString();

                      field.onChange(dateValue);
                      setDateForDatePicker(date);
                    }}
                  />
                )}

                <IconWrap $hasError={hasError} $isWheelOpen={isWheelOpen}>
                  <Icon
                    type="calendar"
                    onClick={() => {
                      setIsWheelOpen((prevState) => !prevState);
                    }}
                  />
                </IconWrap>
              </Label>
            </Wrapper>
          );
        }}
      />
    );
  },
);

DatePickerForForm.displayName = 'DatePickerForForm';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  position: relative;
  width: 100%;
  max-width: 314px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledInputMask = styled(ReactInputMask)<{ $hasError: boolean }>`
  display: block;
  width: 100%;
  max-width: 314px;
  height: 50px;
  border-radius: 20px;
  border: 1px solid ${COLORS.lightBlue};
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.inputs}
  padding: 18px 22px 18px 50px;
  box-sizing: border-box;
  position: relative;
  background-color: white;
  z-index: 2;

  &::placeholder {
    ${TYPOGRAPHY.inputs}
    color: ${COLORS.darkBlue};
  }

  &:focus {
    outline: none;
  }

  ${({ $hasError }) =>
    $hasError &&
    `
    border-color: ${COLORS.red};
  `}
`;

const IconWrap = styled.div<{ $hasError: boolean; $isWheelOpen: boolean }>`
  position: absolute;
  display: flex;
  left: 16px;
  z-index: 2;
  padding: 4px;
  ${({ $isWheelOpen }) =>
    $isWheelOpen &&
    `
    background-color: #dcdcdc85;
    border-radius: 10px;
    
    
  `}
  ${({ $hasError }) =>
    $hasError &&
    `
    & svg path {
      stroke: ${COLORS.red};
    }
  `};
`;

const ErrorText = styled.span`
  ${TYPOGRAPHY.subline1}
  position: absolute;
  color: ${COLORS.red};
  bottom: calc(100% + 4px);
  left: 20px;
`;
