import { useLayoutEffect } from 'react';

export const useBodyBackground = (backgroundColor: string) => {
  useLayoutEffect(() => {
    let doc: HTMLElement | null = document.documentElement;

    if (backgroundColor) {
      doc?.style.setProperty('--body-bg-color', `${backgroundColor}`);
    }

    return () => {
      doc?.style.removeProperty('--body-bg-color');
      doc = null;
    };
  }, []);
};
