/**
 * Класс для инициализации GATT сервисов и характеристик.
 * Отвечает за хранение характеристик.
 */
export class GATTGateway {
  private gattServer: BluetoothRemoteGATTServer;
  private services: BluetoothRemoteGATTService[] = [];
  private characteristics: BluetoothRemoteGATTCharacteristic[] = [];

  constructor(gattServer: BluetoothRemoteGATTServer) {
    this.gattServer = gattServer;
  }

  public async initServicesAndCharacteristics(): Promise<void> {
    const services = await this.gattServer.getPrimaryServices();
    this.services = services;

    const characteristics = [];

    for (const service of services) {
      const serviceCharacteristics = await service.getCharacteristics();
      characteristics.push(...serviceCharacteristics);
    }

    this.characteristics = characteristics;
  }

  public getService(serviceUUID: string): BluetoothRemoteGATTService {
    const service = this.services.find(
      (service) => service.uuid === serviceUUID,
    );

    if (!service) {
      throw new Error('Service not found');
    }

    return service;
  }
  public disconnectGattServer(): void {
    this.gattServer.disconnect();
  }

  public getCharacteristic(
    characteristicUUID: string,
  ): BluetoothRemoteGATTCharacteristic {
    const characteristic = this.characteristics.find(
      (characteristic) => characteristic.uuid === characteristicUUID,
    );

    if (!characteristic) {
      throw new Error(`Characteristic not found. UUID - ${characteristicUUID}`);
    }

    return characteristic;
  }
}
