import {
  MessageResponseDto,
  UserDailyAvgPuffTimeResponseDto,
  UserSmokeStatistics,
  UserSmokeStatisticsNodeResponseDto,
  UserStatisticsUploadRequestDto,
} from 'api';
import { API_URL } from 'lib/constants';
import { setSmokeStats } from 'store/features/statistics/statisticsSlice';
import { HttpMethods, StatisticDateUnits } from 'types';

import { baseApi } from '../baseApi';

interface GetStatisticsParams {
  deviceId: string;
  startDateRange: string;
  endDateRange: string;
  dateUnit: StatisticDateUnits;
  timezoneOffset: number;
}

interface GetAveragePuffTimeParams {
  deviceId: string;
}

export const statisticApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatistics: builder.query<
      UserSmokeStatistics,
      Omit<GetStatisticsParams, 'deviceId'>
    >({
      query: (params) => ({
        url: API_URL.getStatistics,
        method: HttpMethods.get,
        params,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(setSmokeStats(data));
        });
      },
      providesTags: ['statistics'],
    }),

    getStatisticsDaily: builder.query<
      UserSmokeStatistics,
      Pick<GetStatisticsParams, 'timezoneOffset'>
    >({
      query: (params) => ({
        url: API_URL.getStatisticsDaily,
        method: HttpMethods.get,
        params,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(setSmokeStats(data));
        });
      },
      providesTags: ['statistics'],
    }),

    postStatistics: builder.mutation<
      MessageResponseDto,
      UserStatisticsUploadRequestDto
    >({
      query: (payload: UserStatisticsUploadRequestDto) => ({
        url: API_URL.postStatistics,
        method: HttpMethods.post,
        body: payload,
      }),
      invalidatesTags: ['statistics'],
    }),

    getLastSavedPuff: builder.query<
      UserSmokeStatisticsNodeResponseDto,
      Pick<GetStatisticsParams, 'deviceId'>
    >({
      query: (params: UserStatisticsUploadRequestDto) => ({
        url: API_URL.getLastSavedPuff(params.deviceId),
        method: HttpMethods.get,
      }),
    }),
    getAveragePuffTime: builder.query<
      UserDailyAvgPuffTimeResponseDto,
      GetAveragePuffTimeParams
    >({
      query: (params: GetAveragePuffTimeParams) => ({
        url: API_URL.getAveragePuffTime(params.deviceId),
        method: HttpMethods.get,
      }),
    }),
  }),
});

export const {
  useGetStatisticsDailyQuery,
  useGetStatisticsQuery,
  usePostStatisticsMutation,
  useGetAveragePuffTimeQuery,
} = statisticApi;
