import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { InputHTMLAttributes, forwardRef, useId } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui/Icon';
import { IconType } from 'ui/Icon/Icon';

export interface SwitchIconProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  enabledIcon: IconType;
  disabledIcon: IconType;
  onChange: () => void;
}

export const SwitchIcon = forwardRef<HTMLInputElement, SwitchIconProps>(
  (
    {
      className,
      label,
      checked = false,
      enabledIcon,
      disabledIcon,
      onChange,
      ...props
    },
    ref,
  ) => {
    const ownId = useId();
    const id = props.id || ownId;

    return (
      <Root className={className} htmlFor={id}>
        <Track
          ref={ref}
          id={id}
          {...props}
          $isChecked={checked}
          onClick={onChange}
        >
          <Tracker $isChecked={checked}>
            {checked ? (
              <Icon type={enabledIcon} />
            ) : (
              <Icon type={disabledIcon} />
            )}
          </Tracker>
        </Track>

        {label && <span>{label}</span>}
      </Root>
    );
  },
);

SwitchIcon.displayName = 'SwitchIcon';

const Root = styled.label`
  cursor: pointer;

  display: flex;
  align-items: center;

  & > span {
    ${TYPOGRAPHY.body1}
    margin-left: 15px;
    color: ${COLORS.black};
  }
`;

const Track = styled.div<{ $isChecked: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: content-box;
  width: 100%;
  padding: 1px;
  max-width: 50px;
  min-height: 26px;
  border-radius: 11px;

  user-select: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
  appearance: none;

  ${(props) =>
    props.$isChecked
      ? 'background-color: #323232;'
      : 'background-color: #F2F1F7;'}
`;

const Tracker = styled.div<{ $isChecked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 26px;
  min-height: 26px;
  border-radius: 10px;
  background-color: ${COLORS.white};

  font-family: 'Inter', 'Arial', sans-serif;
  letter-spacing: -0.1px;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 24px;
  ${(props) =>
    props.$isChecked ? 'transform: translateX(calc(50px - 100%));' : ''}
`;
