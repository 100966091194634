import { useAuth } from 'lib/hooks';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from './route-paths';

/**
 * Обертка для приватный роутов.
 * Доступен только пользователям с токеном авторизации.
 * Если токен отсутствует пользователь будет отправлен на начальный экран.
 */
export function PrivateOutlet() {
  const token = useAuth();
  const location = useLocation();

  return token ? (
    <Outlet />
  ) : (
    <Navigate to={ROUTES.INTRO} state={{ from: location }} />
  );
}
