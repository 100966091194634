// Вернет true если version1 ниже version2
export function CompareVersion(version1: string, version2: string): boolean {
  const version1Arr = VersionStringToArrayNumber(version1);
  const version2Arr = VersionStringToArrayNumber(version2);

  if (version1Arr[0] < version2Arr[0]) {
    return true;
  }

  if (version1Arr[0] === version2Arr[0] && version1Arr[1] < version2Arr[1]) {
    return true;
  }

  if (
    version1Arr[0] === version2Arr[0] &&
    version1Arr[1] === version2Arr[1] &&
    version1Arr[2] < version2Arr[2]
  ) {
    return true;
  }

  return false;
}

function VersionStringToArrayNumber(version: string): Array<number> {
  return version.split('.').map((i) => Number.parseInt(i));
}
