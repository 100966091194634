import './wheel.scss';

import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import dayjs from 'dayjs';
import { INPUT_MASKS, REGEX } from 'lib/constants';
import { checkIsEighteenYearsOld } from 'lib/helpers';
import { useOnClickOutside } from 'lib/hooks/useOnClickOutside';
import { ChangeEvent, FC, InputHTMLAttributes, useState } from 'react';
import { useRef } from 'react';
import ReactInputMask from 'react-input-mask';
import DatePicker from 'react-mobile-datepicker';
import styled from 'styled-components';
import { Icon } from 'ui/Icon/Icon';

export interface DatePickerInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  isDisabled?: boolean;
  id: string;
  error?: string;
  className?: string;
}

export const DatePickerInput: FC<DatePickerInputProps> = ({
  placeholder,
  isDisabled = false,
  id,
  error,
  className,
  onChange,
  ...props
}) => {
  const dateNow = new Date();
  const [hasError, setHasError] = useState(Boolean(error));
  const [date, setDate] = useState('');
  const [isWheelOpen, setIsWheelOpen] = useState(false);
  const [dateForDatePicker, setDateForDatePicker] = useState(dateNow);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
    const dateString = event.target.value;
    const dateReg = new RegExp(REGEX.date);
    const resultReg = dateReg.exec(dateString);
    const is18plus = checkIsEighteenYearsOld(dayjs(dateString));
    const isNotEmpty = dateString !== '__.__.____' && dateString !== '';

    if (resultReg && is18plus) {
      const dateParts = dateString.split('.');
      const dateObject = new Date(
        `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`,
      );
      setDateForDatePicker(dateObject);
      onChange?.(event);
      setHasError(false);
    } else {
      setHasError(isNotEmpty);
    }
  };

  const handleDatePickerChange = (date: Date) => {
    setDate(new Date(date).toLocaleDateString());
    setDateForDatePicker(date);
    const is18plus = checkIsEighteenYearsOld(dayjs(date));
    setHasError(!is18plus);
  };

  const onClose = () => {
    setIsWheelOpen(false);
  };

  const datePickerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(datePickerRef, onClose);

  return (
    <Root className={`datepicker__container ${className}`} ref={datePickerRef}>
      {hasError && <ErrorText>Неверная дата рождения</ErrorText>}

      <Label
        onClick={() => {
          setIsWheelOpen(true);
        }}
      >
        <StyledInputMask
          mask={INPUT_MASKS.date}
          placeholder={placeholder}
          id={id}
          type="text"
          value={date}
          onChange={handleChange}
          $hasError={hasError}
          disabled={isDisabled}
          {...props}
        />

        <IconWrap $hasError={hasError}>
          <Icon type="calendar" />
        </IconWrap>
      </Label>

      {isWheelOpen && (
        <DatePicker
          value={dateForDatePicker || dateNow}
          isOpen={true}
          isPopup={false}
          theme={'ios'}
          min={new Date(1900, 0, 1)}
          max={dateNow}
          dateConfig={{
            date: {
              format: 'DD',
              caption: 'Day',
              step: 1,
            },
            month: {
              format: 'MM',
              caption: 'Mon',
              step: 1,
            },
            year: {
              format: 'YYYY',
              caption: 'Year',
              step: 1,
            },
          }}
          confirmText={''}
          cancelText={''}
          showHeader={false}
          onChange={(event: Date) => {
            handleDatePickerChange(event);
          }}
        />
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const Label = styled.label`
  z-index: 10;
  position: relative;
  width: 100%;
  max-width: 314px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledInputMask = styled(ReactInputMask)<{ $hasError: boolean }>`
  display: block;
  width: 100%;
  max-width: 314px;
  height: 50px;
  border-radius: 20px;
  border: 1px solid ${COLORS.lightBlue};
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.inputs}
  padding: 18px 22px 18px 50px;
  box-sizing: border-box;
  position: relative;
  background-color: white;

  &::placeholder {
    ${TYPOGRAPHY.inputs}
    color: ${COLORS.darkBlue};
  }

  &:focus {
    outline: none;
  }

  ${({ $hasError }) =>
    $hasError &&
    `
    border-color: ${COLORS.red};
  `}
`;

const IconWrap = styled.div<{ $hasError: boolean }>`
  position: absolute;
  display: flex;
  left: 20px;
  ${({ $hasError }) =>
    $hasError &&
    `
    & svg path {
      stroke: ${COLORS.red};
    }
  `}
`;

const ErrorText = styled.span`
  ${TYPOGRAPHY.subline1}
  position: absolute;
  color: ${COLORS.red};
  bottom: calc(100% + 4px);
  left: 18px;
`;
