import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'ui/Icon/Icon';

interface BackButtonProps {
  onClick?: () => void;
  className?: string;
}

export const BackButton: FC<BackButtonProps> = ({ className, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick ? onClick() : navigate(-1);
  };

  return (
    <Root className={className} onClick={handleClick}>
      <Icon type="backArrow" />
    </Root>
  );
};

const Root = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;

  path {
    transition: all 0.3s;
  }

  &:hover,
  &:active {
    path {
      fill: #657786;
    }
  }
`;
