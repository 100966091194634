import { COLORS } from 'assets/styles/colors';
import { SIZE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { FC } from 'react';
import styled from 'styled-components';
import { Modal } from 'ui/Modal';

type BluetoothModalProps = {
  isOpen: boolean;
  closeCallback: () => void;
};

export const BluetoothModal: FC<BluetoothModalProps> = ({
  isOpen,
  closeCallback,
}) => {
  return (
    <Modal isOpen={isOpen} closeCallback={closeCallback}>
      <Content>
        <Title>Пожалуйста, включите функцию Bluetooth</Title>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 316px;
  height: 114px;
  border-radius: 20px;
  background-color: white;

  @media (max-width: ${SIZE.mobileM}) {
    max-width: 300px;
  }
`;

const Title = styled.p`
  ${TYPOGRAPHY.popUps}
  text-align: center;
  color: ${COLORS.black};
  max-width: 255px;
`;
