import { COLORS } from 'assets/styles/colors';
import { DEVICE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import styled from 'styled-components';
import { Button, Icon } from 'ui';

/**
 * Экран с информацией об установленном обновлении.
 */
export const CompleteState = () => {
  const navigate = useNavigate();

  return (
    <Root>
      <ContentWrapper>
        <StyledIcon type="checkInCircle" />
        <Description>Установлена актуальная версия ПО</Description>
      </ContentWrapper>

      <StyledButton
        type="submit"
        variant="primary"
        onClick={() => navigate(ROUTES.ROOT)}
      >
        Отлично
      </StyledButton>
    </Root>
  );
};

const Root = styled(MainLayout)`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media ${DEVICE.mobileM} {
    padding: 60px 50px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 30px;
`;
const StyledButton = styled(Button)`
  max-width: 294px;
  width: 100%;
  margin: 0 auto;
`;

const Description = styled.p`
  ${TYPOGRAPHY.body3}
  color: ${COLORS.black};
  text-align: center;
  max-width: 225px;
  margin-bottom: 24px;
`;
