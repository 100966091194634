import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from 'ui';

import { UserDailyAvgPuffTimeResponseDto } from '../../../../../../api';
import {
  getBatteryMinuteDeclension,
  getBatteryPuffsContDeclension,
} from '../../../../../../lib/helpers';

type BatteryProps = {
  userAveragePuffTime?: UserDailyAvgPuffTimeResponseDto;
  batteryPercent?: number;
  isCharging?: boolean;
  batteryChargingTime?: number;
};

/**
 * Функция адаптирует уровень батареи под UI компоненты
 * @param batteryChargePercentage - уровень заряда батареи, 0-100
 */
function calculateBatteryLevel(batteryChargePercentage?: number) {
  if (batteryChargePercentage) {
    switch (true) {
      case batteryChargePercentage >= 90:
        return 6;
      case batteryChargePercentage >= 75:
        return 5;
      case batteryChargePercentage >= 55:
        return 4;
      case batteryChargePercentage >= 40:
        return 3;
      case batteryChargePercentage >= 20:
        return 2;
      default:
        return 1;
    }
  } else {
    return 6;
  }
}

const isBatteryCharging = (batteryPercent?: number) =>
  batteryPercent && batteryPercent < 100;

/**
 * Компонент отображения уровня батареи.
 * @prop batteryPercent - уровень заряда батареи, 0-100.
 * @prop isCharging - флаг зарядки.
 * @prop batteryChargingTime - время до полной зарядки батареи.
 */
export const Battery = ({
  userAveragePuffTime,
  batteryChargingTime,
  batteryPercent,
  isCharging,
}: BatteryProps) => {
  const timeToCharge = useMemo(() => {
    if (batteryChargingTime && isCharging) {
      console.log('[TO_CHARGING_TIME_SEC]', batteryChargingTime);
      const minutes = Math.floor(batteryChargingTime / 60);
      console.log('[TO_CHARGING_TIME_MIN]', batteryChargingTime);
      return Number(String(Number(minutes)).padStart(2, '0'));
    }

    return null;
  }, [batteryChargingTime, isCharging]);

  const countOfPuffs = useMemo(() => {
    if (batteryChargingTime && !isCharging) {
      console.log('[PUFFS_SECS_REMAIN]', batteryChargingTime);
      const averageUserPuffTimeMs =
        userAveragePuffTime?.avgPuffTimePerWeek || 1500;
      const averageUserPuffTimeSec = averageUserPuffTimeMs / 1000;
      console.log('[AVERAGE_USER_PUFF_TIME]', averageUserPuffTimeSec);
      const puffs = batteryChargingTime / averageUserPuffTimeSec;
      const floorPuffsResult = Math.floor(puffs / 10) * 10;
      console.log('[COUNT_OF_PUFFS]', floorPuffsResult);
      return floorPuffsResult;
    }

    return null;
  }, [
    batteryChargingTime,
    isCharging,
    userAveragePuffTime?.avgPuffTimePerWeek,
  ]);

  return (
    <>
      {isCharging ? (
        <Icon type="batteryCharging" />
      ) : (
        <BatteryIconWrapper
          $batteryNumber={calculateBatteryLevel(batteryPercent)}
        >
          <Icon type="batteryFull" />
        </BatteryIconWrapper>
      )}
      <Text>{batteryPercent}%</Text>
      {isCharging && isBatteryCharging(batteryPercent) && timeToCharge && (
        <BatteryChargingTimeText>
          {getBatteryMinuteDeclension(timeToCharge)}
        </BatteryChargingTimeText>
      )}
      {!isCharging && countOfPuffs && (
        <BatteryChargingTimeText>
          {' '}
          ~ {getBatteryPuffsContDeclension(countOfPuffs)}
        </BatteryChargingTimeText>
      )}
    </>
  );
};

const Text = styled.p`
  ${TYPOGRAPHY.body2}
  margin-left: 21px;
  color: ${COLORS.black};
`;

const BatteryChargingTimeText = styled.p`
  ${TYPOGRAPHY.subline2}
  color: ${COLORS.darkBlue};
  margin-left: 5px;
`;

const BatteryIconWrapper = styled.div<{ $batteryNumber: number }>`
  ${({ $batteryNumber }) => {
    switch ($batteryNumber) {
      case 1:
        return css`
          & svg path {
            fill: none;
          }
          & svg path:nth-last-child(6) {
            fill: ${COLORS.red};
          }
        `;
      case 2:
        return css`
          & svg path {
            fill: none;
          }
          & svg path:nth-last-child(6) {
            fill: ${COLORS.black};
          }
          & svg path:nth-last-child(5) {
            fill: ${COLORS.black};
          }
        `;
      case 3:
        return css`
          & svg path:nth-last-child(3) {
            fill: none;
          }
          & svg path:nth-last-child(2) {
            fill: none;
          }
          & svg path:nth-last-child(1) {
            fill: none;
          }
        `;
      case 4:
        return css`
          & svg path:nth-last-child(2) {
            fill: none;
          }
          & svg path:nth-last-child(1) {
            fill: none;
          }
        `;
      case 5:
        return css`
          & svg path:nth-last-child(1) {
            fill: none;
          }
        `;
      default:
        break;
    }
    return;
  }}
`;
