import { UserProfileResponseDto } from 'api';
import { API_URL } from 'lib/constants';
import { setUser } from 'store/features/auth';
import { HttpMethods } from 'types';

import { baseApi } from '../baseApi';

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<UserProfileResponseDto, null>({
      query: () => ({
        url: API_URL.userProfile,
        method: HttpMethods.get,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(setUser(data));
        });
      },
      providesTags: ['users'],
    }),
  }),
});

export const { useGetMeQuery } = usersApi;
