import {
  UserSmokeStatisticsDateUnitEnum,
  UserSmokeStatisticsNodeResponseDto,
} from 'api';
import dayjs from 'dayjs';
import { StatisticDateUnits } from 'types';

const populateDay = (
  puffs: UserSmokeStatisticsNodeResponseDto[],
  startDate: string,
) => {
  let dataForPopulation = puffs;

  if (!puffs.length) {
    dataForPopulation = [{ puffCount: 0, nicotineLevel: 0, date: startDate }];
  }

  const HOURS = Array.from(new Array(24)).map((_, index) => index);
  const newPuffs = dataForPopulation.map((element) => ({
    ...element,
    hour: dayjs(element.date).hour(),
  }));
  const puffsFirstDate = dataForPopulation[0].date;
  const firstDateZeroHour = dayjs(puffsFirstDate).startOf('day');

  const result = HOURS.map((hour) => {
    const dateWithHour = firstDateZeroHour.add(hour, 'hours').toISOString();
    const requiredHour = dayjs(dateWithHour).hour();

    const currentNicotineLevel =
      newPuffs.find((element) => element.hour === requiredHour)
        ?.nicotineLevel || 0;

    const currentPuffCount =
      newPuffs.find((element) => element.hour === requiredHour)?.puffCount || 0;

    return {
      date: dateWithHour,
      nicotineLevel: currentNicotineLevel,
      puffCount: currentPuffCount,
    };
  });
  return result;
};

const populateWeek = (
  puffs: UserSmokeStatisticsNodeResponseDto[],
  startDate: string,
) => {
  let dataForPopulation = puffs;

  if (!puffs.length) {
    dataForPopulation = [{ puffCount: 0, nicotineLevel: 0, date: startDate }];
  }

  const DAYS = Array.from(new Array(7)).map((_, index) => index);
  const newPuffs = dataForPopulation.map((element) => ({
    ...element,
    weekDayNumber: dayjs(element.date).isoWeekday() - 1,
  }));

  const puffsFirstDate = dataForPopulation[0].date;
  const newPuffsWeekDayNumber = newPuffs[0].weekDayNumber;

  const startWeekDate = dayjs(puffsFirstDate).subtract(
    newPuffsWeekDayNumber,
    'day',
  );

  const firstDayDate =
    newPuffsWeekDayNumber === 0 ? puffsFirstDate : startWeekDate;

  const result = DAYS.map((day) => {
    const dateWithWeekDay = dayjs(firstDayDate).add(day, 'day').toISOString();
    const requiredWeekDayNumber = dayjs(dateWithWeekDay).isoWeekday() - 1;

    const currentNicotineLevel =
      newPuffs.find(
        (element) => element.weekDayNumber === requiredWeekDayNumber,
      )?.nicotineLevel || 0;

    const currentPuffCount =
      newPuffs.find(
        (element) => element.weekDayNumber === requiredWeekDayNumber,
      )?.puffCount || 0;

    return {
      date: dateWithWeekDay,
      nicotineLevel: currentNicotineLevel,
      puffCount: currentPuffCount,
    };
  });
  return result;
};

const populateMonth = (
  puffs: UserSmokeStatisticsNodeResponseDto[],
  startDate: string,
) => {
  let dataForPopulation = puffs;

  if (!puffs.length) {
    dataForPopulation = [{ puffCount: 0, nicotineLevel: 0, date: startDate }];
  }

  const puffsFirstDate = dataForPopulation[0].date;
  const DAYS = Array.from(new Array(dayjs(puffsFirstDate).daysInMonth())).map(
    (_, index) => index,
  );
  const newPuffs = dataForPopulation.map((element) => ({
    ...element,
    monthDayNumber: dayjs(element.date).date() - 1,
  }));

  const newPuffsFirstElementMonthDayNumber = newPuffs[0].monthDayNumber;
  const zeroDayOfMonth = dayjs(puffsFirstDate).subtract(
    newPuffsFirstElementMonthDayNumber,
    'day',
  );

  const firstDayDate =
    newPuffsFirstElementMonthDayNumber === 0 ? puffsFirstDate : zeroDayOfMonth;

  const result = DAYS.map((day) => {
    const dateWithMonthDay = dayjs(firstDayDate).add(day, 'day');
    const requiredMonthDayNumber = dateWithMonthDay.date() - 1;
    const currentNicotineLevel =
      newPuffs.find(
        (element) => element.monthDayNumber === requiredMonthDayNumber,
      )?.nicotineLevel || 0;

    const currentPuffCount =
      newPuffs.find(
        (element) => element.monthDayNumber === requiredMonthDayNumber,
      )?.puffCount || 0;

    return {
      date: dateWithMonthDay,
      nicotineLevel: currentNicotineLevel,
      puffCount: currentPuffCount,
    };
  });

  return result;
};

export function transformData(
  puffs: UserSmokeStatisticsNodeResponseDto[],
  dateUnit: UserSmokeStatisticsDateUnitEnum,
  startDate: string,
) {
  if (dateUnit === StatisticDateUnits.WEEK) {
    const newPuffs = populateWeek(puffs, startDate);
    const resWeek = newPuffs.map((element) => {
      const formatted = dayjs(element.date).format('DD.MM');

      return {
        time: formatted,
        amount: `${Math.round(element.nicotineLevel)}`,
        puffCount: element.puffCount,
      };
    });

    return resWeek;
  } else if (dateUnit === StatisticDateUnits.DAY) {
    const newPuffs = populateDay(puffs, startDate);

    const resDay = newPuffs.map((element) => {
      const thisHour = dayjs(element.date);

      return {
        time: thisHour.format('HH:mm'),
        amount: `${Math.round(element.nicotineLevel)}`,
        puffCount: element.puffCount,
      };
    });
    return resDay;
  } else if (dateUnit === StatisticDateUnits.MONTH) {
    const newPuffs = populateMonth(puffs, startDate);

    const resDay = newPuffs.map((element) => {
      const formatted = dayjs(element.date).format('DD.MM');

      return {
        time: formatted,
        amount: `${Math.round(element.nicotineLevel)}`,
        puffCount: element.puffCount,
      };
    });

    return resDay;
  }
}
