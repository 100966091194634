import { yupResolver } from '@hookform/resolvers/yup';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useStepperDispatcher } from 'components/Stepper';
import dayjs from 'dayjs';
import { ERROR_TEXTS, FORMATS, REGEX } from 'lib/constants';
import { checkIsEighteenYearsOld, getDateFromString } from 'lib/helpers';
import { StorageService } from 'lib/services';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import { useSignUpMutation } from 'store/api/auth';
import { selectSignUpContext } from 'store/features/auth';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import {
  CreateProfileSteps,
  CustomError,
  ElementInputType,
  StorageKeys,
} from 'types';
import { BackButton, Button, Checkbox, Icon, Input, Logo, Stepper } from 'ui';
import { DatePickerForForm } from 'ui';
import * as yup from 'yup';

export type RegistrationFormData = {
  name: string;
  age: string;
  password: string;
  passwordConfirmation: string;
  confirm: boolean;
};

const schema = yup
  .object({
    name: yup
      .string()
      .required(ERROR_TEXTS.emptyName)
      .matches(REGEX.name, { message: ERROR_TEXTS.name }),
    age: yup
      .string()
      .matches(REGEX.date, { message: ERROR_TEXTS.invalidDate })
      .test('check18yers', ERROR_TEXTS.notEighteenYearsOld, (value) =>
        checkIsEighteenYearsOld(dayjs(value, FORMATS.date)),
      )
      .required(),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password')], ERROR_TEXTS.confirmationPassword)
      .required(ERROR_TEXTS.required),
    password: yup
      .string()
      .required(ERROR_TEXTS.required)
      .matches(REGEX.notCyrillic, {
        message: ERROR_TEXTS.restrictCyrillicSymbols,
      })
      .matches(REGEX.noSpaces, {
        message: ERROR_TEXTS.needNoSpaces,
      })
      .min(6, ERROR_TEXTS.needLenghtMoreThenSix)
      .max(64, ERROR_TEXTS.needLenghtLessSixtyFour),

    confirm: yup.boolean().oneOf([true]),
  })
  .required();

export const StepProfileInfo = () => {
  const { switchStep } = useStepperDispatcher();
  const navigate = useNavigate();
  const [signUp, { isLoading }] = useSignUpMutation();
  const [backendErrorText, setBackendErrorText] = useState('');
  const signUpContext = useAppSelector(selectSignUpContext);

  const isBackendError = Boolean(backendErrorText);
  const dateFromLocalStorage =
    StorageService.get<string>(StorageKeys.DATE_OF_BIRTH) ?? '';

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isDirty, isValid },
  } = useForm<RegistrationFormData>({
    defaultValues: {
      name: '',
      age: dateFromLocalStorage,
      password: '',
      passwordConfirmation: '',
      confirm: false,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const dateField = watch('age');

  const isDateNotEmpty = dateField !== '__.__.____' && dateField !== '';

  const onSubmit: SubmitHandler<RegistrationFormData> = (data) => {
    setBackendErrorText('');
    const birthday = getDateFromString(data.age).set('hour', 15).toISOString();
    const trimmedName = data?.name?.trim();

    const userData = {
      phone: signUpContext.phone,
      code: signUpContext.code,
      password: data.password,
      username: trimmedName,
      dateOfBirth: birthday,
      privacyPolicy: data.confirm,
      dataProcessingAndPrivacyConsent: data.confirm,
    };

    signUp(userData)
      .unwrap()
      .then(() => {
        navigate(ROUTES.ROOT);
      })
      .catch((err) => {
        const error = err as CustomError;
        setBackendErrorText(error.data.message);
      });
  };

  return (
    <>
      <Header>
        <StyledBackButton
          onClick={() => switchStep(CreateProfileSteps.sendCode)}
        />
        <Logo />
      </Header>

      <StyledStepper activeStep={1} />

      <Title>Регистрация профиля</Title>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldsWrapper>
          <StyledInput
            id="name"
            placeholder="Имя"
            autoFocus
            spellCheck="false"
            maxLength={20}
            type={ElementInputType.text}
            error={errors.name?.message}
            icon={<Icon type="profile" />}
            {...register('name')}
          />

          <StyledDatePickerForForm
            placeholder="Дата рождения"
            error={isDateNotEmpty ? errors.age?.message : ''}
            defaultDateString={dateFromLocalStorage}
            control={control}
          />

          <StyledInput
            id="password"
            placeholder="Введите пароль"
            type={ElementInputType.password}
            error={errors.password?.message}
            icon={<Icon type="lock" />}
            {...register('password')}
          />

          <StyledInput
            id="passwordConfirmation"
            placeholder="Повторите пароль"
            type={ElementInputType.password}
            error={errors.passwordConfirmation?.message}
            icon={<Icon type="lock" />}
            {...register('passwordConfirmation')}
          />

          {isBackendError && <BackendError>{backendErrorText}</BackendError>}
        </FieldsWrapper>

        <StyledCheckbox
          id="confirm"
          text={
            <>
              Даю согласие на обработку персональных данных и соглашаюсь с{' '}
              <AgreementLink href="https://plonq.ru/privacy">
                политикой конфиденциальности
              </AgreementLink>
            </>
          }
          {...register('confirm')}
        />

        <StyledButton
          type="submit"
          variant="primary"
          isLoading={isLoading}
          isDisabled={!isDirty || !isValid}
        >
          Создать профиль
        </StyledButton>
      </Form>
      <Agreement>
        Нажимая “Создать профиль”, вы подтверждаете, что достигли возраста 18
        лет, и принимаете{' '}
        <AgreementLink href="https://plonq.ru/terms">
          пользовательское соглашение
        </AgreementLink>
      </Agreement>
    </>
  );
};

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 29px;
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: -10px;
  top: -2px;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1}
  color: ${COLORS.black};
  margin-bottom: 32px;
`;

const StyledStepper = styled(Stepper)`
  margin-bottom: 67px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FieldsWrapper = styled.div`
  position: relative;
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

const StyledDatePickerForForm = styled(DatePickerForForm)`
  width: 100%;
  margin-bottom: 24px;
`;

const StyledCheckbox = styled(Checkbox)`
  text-align: left;
  margin-left: 20px;
  margin-bottom: 14px;

  label {
    align-items: flex-start;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: 21px;
`;

const Agreement = styled.p`
  ${TYPOGRAPHY.subline1}
  color: ${COLORS.darkBlue};
  max-width: 265px;
`;

const AgreementLink = styled.a`
  text-decoration: underline;
`;

const BackendError = styled.p`
  ${TYPOGRAPHY.subline1}
  position: absolute;
  left: 20px;
  color: ${COLORS.red};
`;
