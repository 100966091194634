import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { FC } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui';

/**
 * @interface
 * @property onClose
 * @property className
 */
export interface ChildLockCartridgeInfoProps {
  onClose: () => void;
  className?: string;
}

/**
 * Компонент дропдауна для функции ChildLock в Меню с
 * информацией о блокировке/разблокировке по картриджу.
 */
export const ChildLockCartridgeInfo: FC<ChildLockCartridgeInfoProps> = ({
  className,
  onClose,
}) => {
  return (
    <Root className={className}>
      <CloseBtn onClick={onClose}>
        <Icon type="close" />
      </CloseBtn>
      <Title>
        Для блокировки и разблокировки устройства извлеките и установите
        картридж 5 раз в течение 3 сек
      </Title>
    </Root>
  );
};

const Root = styled.div`
  z-index: 200;
  width: 100%;
  max-width: 348px;
  padding: 18px 42px 11px 42px;
  background-color: ${COLORS.white};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border: 1px solid ${COLORS.lightBlue};
`;

const Title = styled.h4`
  ${TYPOGRAPHY.subline1};
  color: ${COLORS.black};
  text-align: center;
  margin-bottom: 10px;
  max-width: 265px;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 7px;
  right: 7px;
`;
