import {
  ConnectionCreateRequestDto,
  MessageResponseDto,
  UserPhoneVerificationCheckRequestDto,
  UserPhoneVerificationSendRequestDto,
  UserRestorePasswordByPhoneRequestDto,
  UserRestorePasswordByPhoneSendRequestDto,
  UserSendSmsResponseDto,
  UserSignInRequestDto,
  UserSignUpRequestDto,
  UserTokenizedResponseDto,
} from 'api';
import { API_URL } from 'lib/constants';
import { baseApi } from 'store/api/baseApi';
import { setUserWithToken } from 'store/features/auth';
import { HttpMethods } from 'types';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<UserTokenizedResponseDto, UserSignInRequestDto>({
      query: (body) => ({
        url: API_URL.signIn,
        method: HttpMethods.post,
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(setUserWithToken(data));
        });
      },
    }),

    signUp: builder.mutation<UserTokenizedResponseDto, UserSignUpRequestDto>({
      query: (body) => ({
        url: API_URL.signUp,
        method: HttpMethods.post,
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(({ data }) => {
          dispatch(setUserWithToken(data));
        });
      },
    }),

    sendRegistrationVerification: builder.mutation<
      UserSendSmsResponseDto,
      UserPhoneVerificationSendRequestDto
    >({
      query: (body) => ({
        url: API_URL.phoneSendVerification,
        method: HttpMethods.post,
        body,
      }),
    }),
    checkRegistrationVerification: builder.mutation<
      MessageResponseDto,
      UserPhoneVerificationCheckRequestDto
    >({
      query: (body) => ({
        url: API_URL.phoneCheckVerification,
        method: HttpMethods.post,
        body,
      }),
    }),

    sendRestorePasswordVerification: builder.mutation<
      UserSendSmsResponseDto,
      UserRestorePasswordByPhoneSendRequestDto
    >({
      query: (body) => ({
        url: API_URL.restorePasswordSendVerification,
        method: HttpMethods.post,
        body,
      }),
    }),
    checkRestorePasswordVerification: builder.mutation<
      MessageResponseDto,
      UserPhoneVerificationCheckRequestDto
    >({
      query: (body) => ({
        url: API_URL.restorePasswordCheckVerification,
        method: HttpMethods.post,
        body,
      }),
    }),
    changePassword: builder.mutation({
      query: (body: UserRestorePasswordByPhoneRequestDto) => ({
        url: API_URL.changePassword,
        method: HttpMethods.patch,
        body,
      }),
    }),
    postConnection: builder.mutation({
      query: (body: ConnectionCreateRequestDto) => ({
        url: API_URL.postConnection,
        method: HttpMethods.post,
        body,
      }),
    }),
  }),
});

export const {
  useSignInMutation,
  useSignUpMutation,
  useSendRegistrationVerificationMutation,
  useCheckRegistrationVerificationMutation,
  useSendRestorePasswordVerificationMutation,
  useCheckRestorePasswordVerificationMutation,
  useChangePasswordMutation,
  usePostConnectionMutation,
} = authApi;
