export function sliceDataView(dataView: DataView, index: number): DataView {
  return new DataView(dataView.buffer.slice(0, index));
}

export function divideDataView(
  dataView: DataView,
  divideIndex: number,
): { left: DataView; right: DataView } {
  return {
    left: new DataView(dataView.buffer.slice(0, divideIndex)),
    right: new DataView(dataView.buffer.slice(divideIndex)),
  };
}
