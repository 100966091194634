import Package from '../helpers/package';

export const useFilePackageWrap = () => {
  const wrapFile = async (file: File) => {
    try {
      const pack = new Package(file);
      await pack.load();

      return pack;
    } catch (error) {
      throw new Error('Не удалось подготовить файл обновления');
    }
  };

  return wrapFile;
};
