import { yupResolver } from '@hookform/resolvers/yup';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useStepperDispatcher } from 'components/Stepper';
import { ERROR_TEXTS, REGEX } from 'lib/constants';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useChangePasswordMutation } from 'store/api/auth';
import { selectPasswordRestoreContext } from 'store/features/auth';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { CustomError, ElementInputType, PasswordRestoreSteps } from 'types';
import { BackButton, Button, Icon, Input, Logo } from 'ui';
import * as yup from 'yup';

type FormData = {
  password: string;
  passwordConfirmation: string;
};

const schema = yup
  .object({
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password')], ERROR_TEXTS.confirmationPassword)
      .required(ERROR_TEXTS.required),
    password: yup
      .string()
      .required(ERROR_TEXTS.required)
      .matches(REGEX.notCyrillic, {
        message: ERROR_TEXTS.restrictCyrillicSymbols,
      })
      .matches(REGEX.noSpaces, {
        message: ERROR_TEXTS.needNoSpaces,
      })
      .min(6, ERROR_TEXTS.needLenghtMoreThenSix)
      .max(64, ERROR_TEXTS.needLenghtLessSixtyFour),
  })
  .required();

export const StepNewPassword = () => {
  const { switchStep } = useStepperDispatcher();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [backendErrorText, setBackendErrorText] = useState('');
  const isBackendError = Boolean(backendErrorText);
  const passwordRestoreContext = useAppSelector(selectPasswordRestoreContext);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormData>({
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormData> = async ({ password }) => {
    setBackendErrorText('');
    try {
      await changePassword({
        password,
        phone: passwordRestoreContext.phone,
        code: passwordRestoreContext.code,
      }).unwrap();

      switchStep(PasswordRestoreSteps.restoreSuccess);
    } catch (err) {
      const error = err as CustomError;
      setBackendErrorText(error.data.message);
    }
  };

  return (
    <>
      <Header>
        <StyledBackButton
          onClick={() => switchStep(PasswordRestoreSteps.getPhone)}
        />
        <Logo />
      </Header>

      <Title>Восстановление пароля</Title>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldsWrapper>
          <Label>Введите новый пароль</Label>
          <StyledPasswordInput
            id="password"
            placeholder="Пароль"
            autoFocus
            type={ElementInputType.password}
            icon={<Icon type="lock" />}
            error={errors.password?.message}
            {...register('password')}
          />

          <Label>Подтвердите новый пароль</Label>
          <StyledConfirmPasswordInput
            id="passwordConfirmation"
            placeholder="Пароль"
            type={ElementInputType.password}
            icon={<Icon type="lock" />}
            error={errors.passwordConfirmation?.message}
            {...register('passwordConfirmation')}
          />

          {isBackendError && <BackendError>{backendErrorText}</BackendError>}
        </FieldsWrapper>

        <StyledButton
          type="submit"
          variant="primary"
          isLoading={isLoading}
          isDisabled={!isDirty || !isValid}
        >
          Готово
        </StyledButton>
      </Form>
    </>
  );
};

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 98px;
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: -10px;
  top: -2px;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1}
  color: ${COLORS.black};
  margin-bottom: 42px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FieldsWrapper = styled.div`
  position: relative;
  margin-bottom: 98px;
`;

const StyledPasswordInput = styled(Input)`
  width: 100%;
  margin-bottom: 46px;

  .input_password-error {
    top: calc(100% + 4px);
  }
`;

const StyledConfirmPasswordInput = styled(Input)`
  width: 100%;

  .input_password-error {
    top: calc(100% + 4px);
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: 18px;
`;

const Label = styled.p`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
  margin-bottom: 18px;
  align-self: flex-start;
  text-align: left;
  padding-left: 50px;
`;

const BackendError = styled.p`
  ${TYPOGRAPHY.subline1}
  position: absolute;
  top: calc(100% + 4px);
  left: 20px;
  color: ${COLORS.red};
`;
