import { CompareVersion } from 'lib/helpers/compareVersions';
import { VERSION_WITH_CHILD_LOCK } from 'lib/services/constants';
import { useCallback, useMemo } from 'react';

import { PlonqDevice } from '../plonq-device';

/**
 * Хук для адаптации логики управления ChildLock для UI компонентов.
 * Использует экземпляр класса {@link PlonqDevice} для доступа к устройству.
 *
 * @example
 * const [activateChildLock, deactivateChildLock] = useChildLockStatus();
 *
 * await activateChildLock();
 * await deactivateChildLock();
 *
 * @returns объект с двумя функциями для вкл/выкл режима ChildLock.
 */
const useChildLockStatus = () => {
  const activate = useCallback(() => {
    PlonqDevice.getInstance().setChildLockIsActive(true);
  }, []);

  const deactivate = useCallback(() => {
    PlonqDevice.getInstance().setChildLockIsActive(false);
  }, []);

  const hasChildLock = useMemo(() => {
    try {
      return !CompareVersion(
        PlonqDevice.getInstance().getFirmware(),
        VERSION_WITH_CHILD_LOCK,
      );
    } catch (err) {
      return false;
    }
  }, []);

  return [activate, deactivate, hasChildLock] as const;
};

export default useChildLockStatus;
