import { Outlet } from 'react-router-dom';

import { useFeatureToggles } from '../components/FeatureToogleContext';
import { NotFound } from '../pages';

export function FeatureToggleOutlet({ featureId }: { featureId: string }) {
  const { isEnabledFeature } = useFeatureToggles();
  const isFeatureEnabled = isEnabledFeature(featureId);

  return isFeatureEnabled ? <Outlet /> : <NotFound text="Доступ запрешен" />;
}
