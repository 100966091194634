import { SIZE } from 'assets/styles/devices';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import styled from 'styled-components';
import { BackButton, ErrorBlock, Logo } from 'ui';

const ContentWrapper = styled.div`
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 115px;
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: 30px;
  top: 91px;
  @media (max-width: ${SIZE.mobileM}) {
    top: 62px;
  }
`;
export const NotFound = ({ text }: { text?: string }) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(ROUTES.ROOT);
  };

  return (
    <MainLayout>
      <ContentWrapper>
        <StyledBackButton onClick={handleBackButtonClick} />
        <StyledLogo />
        <ErrorBlock text={text ?? 'Страница не найдена'} />
      </ContentWrapper>
    </MainLayout>
  );
};
