import { getCartridgeById } from 'lib/helpers/getCartridgeById';

import { ICartridge } from '../interfaces';
import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqDeviceConnection } from './plonq-device-connection';

type UpdateHandler = (device: PlonqDevice) => void;

/**
 * Класс отвечает за работу с характеристиками устройствами
 * предоставляемыми GATT Server'ом. Предоставляет доступ к основной
 * информации об устройстве.
 *
 * Подписывается на нотификации характеристик и предоставляет эту подписку
 * UI компонентам для динамического отображения характеристик устройства.
 *
 * Хранит в себе timestampOffset необходимый для вычисления актуального
 * времени записей о затяжках.
 *
 * timestamp внутри устройства итерируется неравномерно, с этим могут быть связанны
 * проблемы синхронизации записей затяжек, тк вычисляемое время изменяется.
 *
 * Используется во всех проверках на подключение устройства.
 *
 * Используется в хуке {@link useDeviceState} для предоставления
 * данных для UI компонентов.
 *
 * Наиболее важный класс для работы приложения. От него
 * зависят остальные сервисы по работе с устройством, тк
 * данный класс предоставляет доступ к {@link BluetoothConnection}.
 *
 * Должен инициализироваться раньше остальных сервисов.
 *
 * Для работы необходим подключенный экземпляр PlonqDevice.
 *
 * Отвечает за получение и установку ChildLock Settings.
 * Отдельно передается таймер и маска настроек.
 *
 * Для интеграции c React компонентами нужно обернуть в хук.
 */
export class PlonqDevice {
  private name?: string;
  private modelName?: string;
  private macAddress?: string;
  private cartridgeId?: number;
  private firmwareVersion?: string;
  private hardwareVersion?: string;
  private batteryLevel?: number;
  private batteryChargingTime?: number;
  private isCharging?: boolean;
  private rssi?: number;
  private isBleConnected?: boolean;
  private bleConnection?: PlonqDeviceConnection;
  private timestampOffset = 0;
  private isChildLockActive?: boolean;

  private updateHandlers: UpdateHandler[] = [];

  private static instance?: PlonqDevice;

  public setBleConnection(bleConnection: PlonqDeviceConnection): void {
    this.bleConnection = bleConnection;
    this.isBleConnected = true;
  }

  public checkIsBleConnected(): boolean {
    return !!this.isBleConnected;
  }

  public static getInstance(): PlonqDevice {
    if (this.instance) {
      return this.instance;
    }
    this.instance = new PlonqDevice();
    return this.instance;
  }

  public getBleConnection(): PlonqDeviceConnection | undefined {
    return this.bleConnection;
  }

  public getName(): string {
    if (!this.name) {
      throw new Error('Device not initialized');
    }

    return this.name;
  }

  public getModelName(): string {
    if (!this.modelName) {
      throw new Error('Device not initialized');
    }

    return this.modelName;
  }

  public getMacAddress(): string {
    if (!this.macAddress) {
      throw new Error('Device not initialized');
    }

    return this.macAddress;
  }

  public getFirmware(): string {
    if (!this.firmwareVersion) {
      throw new Error('Device not initialized');
    }

    return this.firmwareVersion;
  }

  public getHardwareVersion(): string {
    if (!this.hardwareVersion) {
      throw new Error('Device not initialized');
    }

    return this.hardwareVersion;
  }

  public getCartridgeId(): number {
    if (this.cartridgeId === undefined) {
      throw new Error('Device not initialized');
    }

    return this.cartridgeId;
  }

  public getCartridge(): ICartridge | undefined {
    if (this.cartridgeId === undefined) {
      throw new Error('Device not initialized');
    }

    return getCartridgeById(this.cartridgeId);
  }

  public getBatteryLevel(): number {
    if (this.batteryLevel === undefined) {
      throw new Error('Device not initialized');
    }

    return this.batteryLevel;
  }

  public getBatteryChargingTime(): number {
    if (this.batteryChargingTime === undefined) {
      throw new Error('Device not initialized');
    }

    return this.batteryChargingTime;
  }

  public getIsCharging(): boolean {
    if (this.isCharging === undefined) {
      throw new Error('Device not initialized');
    }

    return this.isCharging;
  }

  public getRSSI(): number {
    if (this.rssi === undefined) {
      throw new Error('Device not initialized');
    }

    return this.rssi;
  }

  public getTimestampOffset(): number {
    return this.timestampOffset;
  }

  public getChildLockIsActive(): boolean {
    if (this.isChildLockActive === undefined) {
      throw new Error('Device not initialized');
    }

    return this.isChildLockActive;
  }

  public setChildLockIsActive(active: boolean): Promise<void> {
    if (this.isChildLockActive === undefined) {
      throw new Error('Device not initialized');
    }

    if (!this.bleConnection) {
      throw new Error('Device not connected');
    }

    return this.bleConnection.setChildLock(active);
  }

  public async setDeviceCurrentTimestamp(): Promise<void> {
    if (!this.bleConnection) {
      throw new Error('Device not initialized');
    }

    await this.bleConnection.setCurrentUnixTime();
  }

  /**
   * Подписка на изменение характеристик для UI компонентов.
   * Необходимо для изменения React State чтобы стриггерить рендер новых данных.
   */
  public subscribeOnUpdates(addingHandler: UpdateHandler): void {
    this.updateHandlers.push(addingHandler);
  }

  /** Отписка от изменений
   * Необходимо при переподключениях, иначе появится эффект глитча.
   */
  public unsubscribeFromUpdate(removingHandler: UpdateHandler): void {
    this.updateHandlers = this.updateHandlers.filter(
      (handler) => handler !== removingHandler,
    );
  }

  /**
   * Уведомления подписантов об изменения в характеристиках.
   */
  private dispatchUpdate(): void {
    for (const listener of this.updateHandlers) listener(this);
  }

  private onCartridgeIdChange(cartridgeId: number): void {
    this.cartridgeId = cartridgeId;
    this.dispatchUpdate();
  }

  private onChargingChange(isCharging: boolean): void {
    this.isCharging = isCharging;
    this.dispatchUpdate();
  }

  private onBatteryChargingChange(batteryChargingTime: number): void {
    this.batteryChargingTime = batteryChargingTime;
    this.dispatchUpdate();
  }

  private onBatteryLevelChange(batteryLevel: number): void {
    this.batteryLevel = batteryLevel;
    this.dispatchUpdate();
  }

  private onChildLockChange(childLockStatus: boolean): void {
    this.isChildLockActive = childLockStatus;
    this.dispatchUpdate();
  }

  private onRSSIChange(rssi: number): void {
    this.rssi = rssi;
    this.dispatchUpdate();
  }

  public disconnect = (): void => {
    if (this.isBleConnected === undefined) {
      throw new Error('BLE connection not installed');
    }

    this.bleConnection?.disconnect();
    this.clearDeviceData();
  };

  /**
   * Сброс данных при дисконнекте или при переподключении.
   * Необходимо для корректной работы с устройством.
   */
  public clearDeviceData = (): void => {
    PlonqDevice.instance = undefined;
    this.isBleConnected = false;
    this.bleConnection = undefined;
    this.name = undefined;
    this.modelName = undefined;
    this.macAddress = undefined;
    this.firmwareVersion = undefined;
    this.hardwareVersion = undefined;
    this.batteryLevel = undefined;
    this.cartridgeId = undefined;
    this.isCharging = undefined;
    this.rssi = undefined;
    this.dispatchUpdate();
    this.updateHandlers = [];
  };

  /**
   * Вызывается сразу после получения экземпляра BluetoothConnection.
   *
   * @example
   * const bleConnection = new BluetoothConnection();
   * const plonqDeviceConnection = new PlonqDeviceConnection(bleConnection);
   * const plonqDevice = PlonqDevice.getInstance().setBleConnection(plonqDeviceConnection);
   * plonqDevice.syncWithBLE('test name');
   *
   * @param name - алиас для устройства установленный пользователем.
   */
  public async syncWithBLE(name: string) {
    try {
      if (!this.bleConnection) {
        throw new BLEConnectionNotInstalled();
      }

      this.bleConnection.onDisconnect(this.clearDeviceData.bind(this));
      this.bleConnection.onReconnect(this.subscribeChanges.bind(this));

      this.name = name;
      this.modelName = await this.bleConnection.getModelName();
      this.macAddress = await this.bleConnection.getMacAddress();
      this.firmwareVersion = await this.bleConnection.getFirmwareVersion();
      this.hardwareVersion = await this.bleConnection.getHardwareVersion();
      this.batteryLevel = await this.bleConnection.getBatteryLevel();
      this.batteryChargingTime =
        await this.bleConnection.getBatteryChargingTime();
      this.cartridgeId = await this.bleConnection.getCartridgeId();
      this.isCharging = await this.bleConnection.getIsCharging();
      this.rssi = await this.bleConnection.getRSSI();
      this.isChildLockActive = await this.bleConnection.getChildLock();

      this.timestampOffset =
        new Date().getTime() - (await this.bleConnection.getUnixTime()) * 1000;

      await this.subscribeChanges();
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  /**
   * Пописка на изменения характеристик.
   * Необзодимо вызывать в syncWithBLE  методе.
   */
  private async subscribeChanges(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    await this.bleConnection.onRSSIChange(this.onRSSIChange.bind(this));
    await this.bleConnection.onBatteryLevelChange(
      this.onBatteryLevelChange.bind(this),
    );
    await this.bleConnection.onChargingChange(this.onChargingChange.bind(this));
    await this.bleConnection.onBatteryLifeTimeChange(
      this.onBatteryChargingChange.bind(this),
    );
    await this.bleConnection.onCartridgeIdChange(
      this.onCartridgeIdChange.bind(this),
    );
    await this.bleConnection.onChildLockChange(
      this.onChildLockChange.bind(this),
    );
  }
}
