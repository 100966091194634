import deviceImage from 'assets/images/device-image.png';
import { COLORS } from 'assets/styles/colors';
import { DEVICE, SIZE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import borderEnabled from 'assets/svg/gradient-border-enabled.svg';
import border from 'assets/svg/gradient-border.svg';
import { getCartridgeById } from 'lib/helpers/getCartridgeById';
import useChildLockStatus from 'lib/services/plonq/hooks/useChildLockStatus';
import useDeviceState from 'lib/services/plonq/hooks/useDeviceState';
import { PlonqDevice } from 'lib/services/plonq/plonq-device';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import { useGetDeviceListQuery } from 'store/api/devices';
import styled from 'styled-components';
import { FindDeviceModal, Icon, Logo, Spinner } from 'ui';
import { ConfirmModal } from 'ui/ConfirmModal';
import { Menu } from 'ui/Menu';

import { useGetAveragePuffTimeQuery } from '../../../../store/api/statistics';
import { Battery } from './components/Battery/Battery';
import { Signal } from './components/Signal/Signal';

const Root = styled.div`
  background: linear-gradient(
    180deg,
    rgba(242, 241, 246, 0.8) 0,
    rgba(210, 212, 218, 0.8) 67.19%
  );
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 100%;
  max-width: 414px;
  margin: 0 auto;
  height: 514px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 27px 22px 0 32px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 10px 0 10px;

  @media ${DEVICE.mobileM} {
    padding: 27px 16px 0 32px;
  }
`;

const Image = styled.img`
  flex-grow: 2;
  max-width: 159px;
  height: 380px;
`;

const InfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 67px;
  row-gap: 31px;
  flex-grow: 1;
`;

const InfoTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 14px;
`;

const Text = styled.p`
  ${TYPOGRAPHY.body2}
  margin-left: 16px;
  color: ${COLORS.black};
`;

const FindButton = styled.button`
  color: ${COLORS.black};
  ${TYPOGRAPHY.body2}
  border-radius: 22px;
  background-image: url(${border});
  width: 100%;
  max-width: 174px;
  height: 45px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: ${SIZE.mobileM}) {
    font-size: 14px;
    letter-spacing: -0.56px;
  }
`;

const ChildLockButton = styled.button<{
  disabled: boolean;
  $isEnabled?: boolean;
}>`
  color: ${({ $isEnabled }) => {
    return $isEnabled ? COLORS.white : COLORS.black;
  }};
  ${TYPOGRAPHY.body2}
  border-radius: 22px;
  background-image: url(${({ $isEnabled }) => {
    return $isEnabled ? borderEnabled : border;
  }});
  width: 100%;
  max-width: 174px;
  height: 45px;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  padding-left: 16px;
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  justify-content: start;

  @media (max-width: ${SIZE.mobileM}) {
    font-size: 14px;
    letter-spacing: -0.56px;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--100vh-height);
`;

const StyledChildLockButtonIcon = styled(Icon)`
  margin-right: 16px;
`;

const ExtraFunctionButton = styled.div``;

const AliasText = styled.p`
  ${TYPOGRAPHY.body2}
  color: ${COLORS.darkBlue};
  font-size: 10px;
`;

/**
 * Экран информации об устройстве.
 *
 * Отвечает за основную функциональность устройства.
 * На нем отображается главная информация об устройстве.
 * Расположен доступ в меню {@link Menu}
 * Происходит управление функциями child-lock, overpuff, location.
 *
 * Для отображения требует подключенное устройство и подключенный
 * к нему экземпляр класса {@link PlonqDevice}.
 *
 * Основная информация берется из {@link useDeviceState} и {@link PlonqDevice}.
 * Для работы Child Lock используется {@link useChildLockStatus}.
 */
export const DeviceIntro = () => {
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFindModalOpen, setIsFindModalOpen] = useState(false);
  const [isCloseDeviceModalOpen, setIsCloseDeviceModalOpen] = useState(false);
  const [deviceState, setDeviceState] = useDeviceState();
  const [activateChildLock, deactivateChildLock, hasChildLock] =
    useChildLockStatus();

  const { data: dataDevices, isLoading: isDeviceListLoading } =
    useGetDeviceListQuery();

  const { data: userAveragePuffTime, isLoading: isUserAveragePuffTimeLoading } =
    useGetAveragePuffTimeQuery({ deviceId: deviceId as string });

  const currentDevice = useMemo(
    () => dataDevices?.devices.find((device) => device.id === deviceId),
    [deviceId, dataDevices],
  );

  const currentCartridge = getCartridgeById(deviceState?.cartridgeId);
  const isPageLoading =
    isDeviceListLoading || !deviceState || isUserAveragePuffTimeLoading;

  const currentAlias = currentDevice?.alias || currentDevice?.modelName;

  const handleCloseDevicePage = () => {
    setIsCloseDeviceModalOpen(false);

    PlonqDevice.getInstance().disconnect();
    navigate(ROUTES.ROOT);
  };

  /**
   * Проверка подключения экземпляра {@link PlonqDevice}.
   * Если подключения нет пользователь переходт на страницу списка устройств.
   */
  useEffect(() => {
    if (!deviceState) {
      const deviceInstance = PlonqDevice.getInstance();

      if (deviceInstance?.checkIsBleConnected()) {
        setDeviceState(deviceInstance);
      } else {
        navigate(ROUTES.ROOT);
      }
    }
  }, []);

  /**
   * Включение/выключение режима Child Lock.
   */
  const toggleChildLock = useCallback(async () => {
    if (deviceState) {
      await (deviceState.isChildLockActive
        ? deactivateChildLock()
        : activateChildLock());
    }
  }, [deviceState, activateChildLock, deactivateChildLock]);

  const handleMenuOpen = useCallback(() => {
    setIsMenuOpen(true);
    const jivoBtn = document.getElementById('btn-logo-zendesk');

    if (jivoBtn) {
      jivoBtn.style.display = 'none';
    }
  }, []);

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false);
    const jivoBtn = document.getElementById('btn-logo-zendesk');

    if (jivoBtn) {
      jivoBtn.style.display = 'block';
    }
  }, []);

  if (isPageLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Root>
      <Header>
        <HeaderRow>
          <Logo width="135px" />
          <button onClick={handleMenuOpen}>
            <Icon type="burger" />
          </button>
        </HeaderRow>
        {currentAlias && <AliasText>{currentAlias}</AliasText>}
      </Header>
      <ContentWrapper>
        <InfoColumn>
          <InfoTextContainer>
            <Signal signalLevel={deviceState?.rssi} />
          </InfoTextContainer>
          <InfoTextContainer>
            <Battery
              userAveragePuffTime={userAveragePuffTime}
              batteryChargingTime={deviceState?.batteryChargingTime}
              batteryPercent={deviceState?.batteryLevel}
              isCharging={deviceState?.isCharging}
            />
          </InfoTextContainer>
          <InfoTextContainer>
            <Icon type="flavor" />
            <Text>{currentCartridge?.readableRuName || 'Не подключено'}</Text>
          </InfoTextContainer>
          <ExtraFunctionButton>
            <ChildLockButton
              disabled={!hasChildLock}
              onClick={toggleChildLock}
              $isEnabled={deviceState.isChildLockActive}
            >
              <StyledChildLockButtonIcon
                type={
                  deviceState.isChildLockActive
                    ? 'childLockEnabled'
                    : 'childLockDisabled'
                }
              />
              Child lock
            </ChildLockButton>
            <FindButton onClick={() => setIsFindModalOpen(true)}>
              <Icon type="findButton" /> Найти Plonq X
            </FindButton>
          </ExtraFunctionButton>
        </InfoColumn>
        <Image src={deviceImage} />
      </ContentWrapper>

      {currentDevice && deviceId && isMenuOpen && (
        <Menu
          isOpen={isMenuOpen}
          closeCallBack={handleMenuClose}
          onExitClick={() => setIsCloseDeviceModalOpen(true)}
          onAddNewPlonqClick={handleCloseDevicePage}
          deviceData={{
            deviceId,
            activatedAt: currentDevice.activatedAt,
            deviceVersion: deviceState?.hardwareVersion,
            firmwareVersion: deviceState?.firmwareVersion,
            MACAddress: deviceState?.macAddress,
          }}
        />
      )}

      {isFindModalOpen && (
        <FindDeviceModal
          isOpen={isFindModalOpen}
          closeCallback={() => setIsFindModalOpen(false)}
        />
      )}

      <ConfirmModal
        text="Вы точно хотите выйти со страницы устройства?"
        isOpen={isCloseDeviceModalOpen}
        onYesCallback={handleCloseDevicePage}
        closeCallback={() => setIsCloseDeviceModalOpen(false)}
      />
    </Root>
  );
};
