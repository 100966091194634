import {
  MessageResponseDto,
  UserAddDeviceRequestDto,
  UserDeviceListResponseDto,
  UserDeviceSyncRequestDto,
  UserRenameDeviceRequestDto,
} from 'api';
import { API_URL } from 'lib/constants';
import { HttpMethods } from 'types';

import { baseApi } from '../baseApi';

export const deviceApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    attachDevice: builder.mutation<MessageResponseDto, UserAddDeviceRequestDto>(
      {
        query: (payload: UserAddDeviceRequestDto) => ({
          url: API_URL.attachDevice,
          method: HttpMethods.post,
          body: payload,
        }),
      },
    ),
    detachDevice: builder.mutation<MessageResponseDto, { deviceId: string }>({
      query: ({ deviceId }) => ({
        url: API_URL.detachDevice(deviceId),
        method: HttpMethods.delete,
      }),
    }),
    getDeviceList: builder.query<UserDeviceListResponseDto, void>({
      query: () => ({
        url: API_URL.deviceList,
        method: HttpMethods.get,
      }),
      providesTags: ['devices'],
    }),
    syncDeviceInfo: builder.mutation<
      MessageResponseDto,
      UserDeviceSyncRequestDto & { deviceId: string }
    >({
      query: (payload: UserDeviceSyncRequestDto & { deviceId: string }) => ({
        url: API_URL.syncDeviceInfo(payload.deviceId),
        method: HttpMethods.patch,
        body: {
          firmwareVersion: payload.firmwareVersion,
        },
      }),
    }),
    renameDevice: builder.mutation<
      MessageResponseDto,
      UserRenameDeviceRequestDto & { deviceId: string }
    >({
      query: ({ deviceId, ...body }) => ({
        url: API_URL.renameDevice(deviceId),
        method: HttpMethods.patch,
        body,
      }),
      onQueryStarted: ({ deviceId, alias }, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          deviceApi.util.updateQueryData(
            'getDeviceList',
            undefined,
            (draft) => {
              const updatedDevices = draft.devices.map((device) => {
                if (device.id === deviceId) {
                  return { ...device, alias: alias || undefined };
                }

                return device;
              });

              return { devices: updatedDevices };
            },
          ),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
  }),
});

export const {
  useAttachDeviceMutation,
  useGetDeviceListQuery,
  useDetachDeviceMutation,
  useSyncDeviceInfoMutation,
  useRenameDeviceMutation,
} = deviceApi;
