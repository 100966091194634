import { useCallback } from 'react';

import { PlonqLocation } from '../plonq-location';

/**
 * Хук инкапсулирует логику функции поиска устройства.
 * Использует экземпляр {@link PlonqLocation} для доступа к устройству.
 *
 * @example
 * const callDeviceSearchFunction = useDeviceLocationSignal();
 * await callDeviceSearchFunction();
 *
 * @returns функцию, которая отправляет на устройство соответствующую команду.
 */
const useDeviceLocationSignal = () => {
  const location = useCallback(() => {
    PlonqLocation.getInstance().sendLocationSignal();
  }, []);

  return location;
};

export default useDeviceLocationSignal;
