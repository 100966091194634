export class DeviceException extends Error {
  public message: string;
  public title?: string;
  public status?: number;

  constructor(message: string, title?: string, status?: number) {
    super();

    this.message = message;
    this.status = status;
    this.title = title;
  }
}
