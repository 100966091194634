import { getTokenFromCookies } from 'lib/services/cookieService';
import { useDispatch } from 'react-redux';
import { useGetMeQuery } from 'store/api/users';
import { selectToken, setToken } from 'store/features/auth';
import { useAppSelector } from 'store/hooks';

/**
 * Хук для авторизации пользователя.
 * Если в LocalStorage или CookieStorage присутствует токен
 * с сервера подгрузися профиль пользователя.
 */
export const useAuth = () => {
  const dispatch = useDispatch();
  const tokenFromState = useAppSelector(selectToken);
  const tokenFromCookies = getTokenFromCookies();
  useGetMeQuery(null, { skip: !tokenFromState });

  if (!tokenFromState && tokenFromCookies) {
    dispatch(setToken(tokenFromCookies));
  }

  return tokenFromState || tokenFromCookies;
};
