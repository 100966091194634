import {
  UserSmokeStatisticsDateUnitEnum,
  UserSmokeStatisticsNodeResponseDto,
} from 'api';
import { COLORS, TRANSPARENCY_COLORS } from 'assets/styles/colors';
import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  CoreScaleOptions,
  LinearScale,
  Scale,
  Tooltip,
} from 'chart.js';
import { transformData } from 'lib/helpers/transformData';
import { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { StatisticDateUnits } from 'types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

type HistogramProps = {
  width: number;
  height: number;
  puffs: UserSmokeStatisticsNodeResponseDto[];
  dateUnit: UserSmokeStatisticsDateUnitEnum;
  isFetching: boolean;
  startDate: string;
};

type HistogramElementData = {
  time: string;
  amount: string;
  puffCount: number;
};

function shouldShowLabel(
  this: Scale<CoreScaleOptions>,
  value: number,
  index: number,
  dateUnit: UserSmokeStatisticsDateUnitEnum,
  nicotineLevelData: HistogramElementData[] | undefined,
) {
  const getHistogramLabelsIntervalInMonth = (daysInMonth: number) => {
    switch (daysInMonth) {
      case 28:
        return 9;
      case 29:
        return 7;
      case 30:
        return 6;
      case 31:
        return 5;
      default:
        return 5;
    }
  };

  if (nicotineLevelData !== undefined) {
    if (dateUnit === StatisticDateUnits.DAY) {
      const HOURS_INTERVAL_IN_DAY = 6;
      const isHourShowed = !(index % HOURS_INTERVAL_IN_DAY);
      const isLastDate = index === nicotineLevelData?.length - 1;

      const shouldDisplayCaption = isHourShowed || isLastDate;

      return shouldDisplayCaption ? this.getLabelForValue(value) : '';
    } else if (dateUnit === StatisticDateUnits.MONTH) {
      const isFirstDate = !index;
      const isLastDate = index === nicotineLevelData?.length - 1;
      const isDayShowed = !(
        index % getHistogramLabelsIntervalInMonth(nicotineLevelData?.length)
      );

      const shouldDisplayCaption = isFirstDate || isLastDate || isDayShowed;

      return shouldDisplayCaption ? this.getLabelForValue(value) : '';
    }
  }

  return this.getLabelForValue(value);
}

export const Histogram = ({
  width,
  height,
  puffs,
  dateUnit,
  isFetching,
  startDate,
}: HistogramProps) => {
  const [nicotineLevelData, setNicotineLevelData] = useState<
    HistogramElementData[] | undefined
  >([]);

  useEffect(() => {
    setNicotineLevelData(transformData(puffs, dateUnit, startDate));
  }, [puffs, dateUnit, startDate]);

  const data: ChartData<'bar', string[] | undefined, string> = useMemo(() => {
    const histogramLabels = nicotineLevelData?.map(
      (nicotineLevel) => nicotineLevel.time,
    );
    const histogramNicotineData = nicotineLevelData?.map(
      (nicotineLevel) => nicotineLevel.amount,
    );
    const histogramPuffsData = nicotineLevelData?.map((nicotineLevel) =>
      nicotineLevel.puffCount.toString(),
    );

    return {
      labels: histogramLabels,
      datasets: [
        {
          label: 'Затяжки',
          data: histogramPuffsData,
          barThickness: 0,
          borderRadius: 0,
        },
        {
          label: 'Никотин',
          data: histogramNicotineData,
          backgroundColor: isFetching
            ? TRANSPARENCY_COLORS.barHalf
            : COLORS.bar,
          barThickness: 2,
          borderSkipped: 'bottom',
          borderRadius: 2,
        },
      ],
    };
  }, [nicotineLevelData]);

  const options: ChartOptions<'bar'> = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
      axis: 'x',
    },
    scales: {
      y: {
        beginAtZero: true,
        position: 'right',
        grid: {
          drawBorder: false,
        },
        ticks: {
          color: COLORS.darkBlue,
          font: {
            family: 'Inter',
            size: 11,
            weight: 'lighter',
          },
        },
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxRotation: 0,
          autoSkip: false,
          color: COLORS.darkBlue,
          font: {
            family: 'Inter',
            size: 11,
            weight: 'lighter',
          },
          callback: function (value, index) {
            return shouldShowLabel.call(
              this,
              Number(value),
              index,
              dateUnit,
              nicotineLevelData,
            );
          },
        },
      },
    },
    plugins: {
      tooltip: {
        cornerRadius: 4,
        backgroundColor: '#7D9BB4',
        displayColors: false,
        filter: ({ formattedValue }) => {
          return formattedValue !== '0';
        },
      },
    },
  };

  return <Bar height={height} width={width} options={options} data={data} />;
};
