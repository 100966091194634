import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { INPUT_MASKS } from 'lib/constants';
import { InputHTMLAttributes, forwardRef, useState } from 'react';
import InputMask from 'react-input-mask';
import styled, { css } from 'styled-components';
import { ElementInputType } from 'types';
import { Icon } from 'ui/Icon/Icon';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
  isError?: boolean;
  id: string;
  type: ElementInputType;
  isSecure?: boolean;
  error?: string;
  className?: string;
  defaultValue?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      icon,
      isDisabled = false,
      id,
      type,
      isSecure = false,
      error,
      isError,
      className,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    const hasError = Boolean(isError || error);
    const [isHidden, setIsHidden] = useState(isSecure);

    const [innerInputType, setInnerInputType] = useState(type);

    const isPasswordAndHidden = type === ElementInputType.password && isHidden;

    const onSecure = () => {
      setIsHidden((prevState) => !prevState);
      !isPasswordAndHidden
        ? setInnerInputType(ElementInputType.text)
        : setInnerInputType(ElementInputType.password);
    };

    const inputComponents = {
      tel: (
        <Root className={className} $hasError={hasError}>
          {hasError && <ErrorText>{error}</ErrorText>}

          <StyledInputMask
            mask={INPUT_MASKS.phone}
            placeholder={placeholder}
            $hasError={hasError}
            id={id}
            type={innerInputType}
            defaultValue={defaultValue}
            disabled={isDisabled}
            {...props}
            inputRef={ref}
          />
          {icon && <IconWrap $hasError={hasError}>{icon}</IconWrap>}
        </Root>
      ),
      password: (
        <Root className={className} $hasError={hasError}>
          {hasError && (
            <ErrorText className="input_password-error">{error}</ErrorText>
          )}
          <CustomPasswordInput
            placeholder={placeholder}
            $hasError={hasError}
            id={id}
            type={innerInputType}
            autoComplete="new-password"
            disabled={isDisabled}
            maxLength={32}
            {...props}
            ref={ref}
          />
          {icon && <IconWrap $hasError={hasError}>{icon}</IconWrap>}
          {isPasswordAndHidden && (
            <PasswordButtonIconWrap type="button" onClick={() => onSecure()}>
              <Icon type="eyeOpen" />
            </PasswordButtonIconWrap>
          )}
          {!isPasswordAndHidden && (
            <PasswordButtonIconWrap type="button" onClick={() => onSecure()}>
              <Icon type="eyeClosed" />
            </PasswordButtonIconWrap>
          )}
        </Root>
      ),
      text: (
        <Root className={className} $hasError={hasError} $isTextInput={true}>
          {hasError && <ErrorText>{error}</ErrorText>}
          <CustomInput
            placeholder={placeholder}
            $hasError={hasError}
            id={id}
            type={innerInputType}
            disabled={isDisabled}
            {...props}
            ref={ref}
          />
          {icon && <IconWrap $hasError={hasError}>{icon}</IconWrap>}
        </Root>
      ),
    };

    return <>{inputComponents[type]}</>;
  },
);

Input.displayName = 'Input';

const Root = styled.div<{ $hasError: boolean; $isTextInput?: boolean }>`
  position: relative;
  width: 100%;
  max-width: 314px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 18px 50px;
  height: 50px;
  border-radius: 20px;
  border: 1px solid ${COLORS.lightBlue};

  ${({ $hasError }) =>
    $hasError &&
    `
    border-color: ${COLORS.red};
  `}

  ${({ $isTextInput }) =>
    $isTextInput &&
    `
    padding: 18px 22px 18px 50px;
  `}
`;

const inputDefaultStyle = css`
  position: relative;
  box-sizing: border-box;
  display: block;
  width: 100%;
  max-width: 314px;
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.inputs}

  &:focus {
    outline: none;
  }

  &::placeholder {
    ${TYPOGRAPHY.inputs}
    color: ${COLORS.darkBlue};
  }
`;

const CustomPasswordInput = styled.input<{ $hasError: boolean }>`
  ${inputDefaultStyle}
  ${({ $hasError }) =>
    $hasError &&
    `
    color: ${COLORS.red};
    &::placeholder {
    color: ${COLORS.red};
  }
  `}
`;

const CustomInput = styled.input<{ $hasError: boolean }>`
  ${inputDefaultStyle}
  ${({ $hasError }) =>
    $hasError &&
    `
    color: ${COLORS.red};
    &::placeholder {
    color: ${COLORS.red};
  }
  `}
`;

const StyledInputMask = styled(InputMask)<{ $hasError: boolean }>`
  ${inputDefaultStyle}
  ${({ $hasError }) =>
    $hasError &&
    `
    color: ${COLORS.red};
    &::placeholder {
    color: ${COLORS.red};
  }
  `}
`;

const IconWrap = styled.div<{ $hasError: boolean }>`
  position: absolute;
  display: flex;
  left: 20px;
  height: 15px;

  ${({ $hasError }) =>
    $hasError &&
    `
    & svg path {
      stroke: ${COLORS.red};
    }
  `};
`;

const PasswordButtonIconWrap = styled.button`
  position: absolute;
  right: 1rem;
`;

const errorTextStyles = css`
  ${TYPOGRAPHY.subline1}
  position: absolute;
  left: 20px;
  text-align: left;
  color: ${COLORS.red};
`;

const ErrorText = styled.span`
  ${errorTextStyles}
  bottom: calc(100% + 4px);
`;
