import dayjs from 'dayjs';

export const getDiffNowAndDateInSeconds = (
  date: string | Date | dayjs.Dayjs,
) => {
  const dateNow = dayjs();
  const dateFromString = dayjs(date);
  const isDateValid = dateFromString.valueOf();
  const diffInSeconds = dateFromString.diff(dateNow, 'seconds');

  if (isDateValid && diffInSeconds > 0) {
    return diffInSeconds;
  }
  return 0;
};
