import {
  bootloaderCommand,
  clearPuffArrayCommand,
  getPuffArrayCommand,
  getPuffCommand,
  locationCommand,
  rebootCommand,
  resetCommand,
  stopLocalisationCommand,
} from './bluetooth/commands';

// Services
export const DEVICE_NAME_PREFIX = 'PLONQ';
export const DEVICE_INFORMATION_GATT_SERVICE_UUID =
  '0000180a-0000-1000-8000-00805f9b34fb';
export const BATTERY_INFORMATION_GATT_SERVICE_UUID =
  '0000180f-0000-1000-8000-00805f9b34fb';
export const PLONQ_GATT_SERVICE_UUID = '00005523-1212-efde-1523-785feabcd133';
export const NORDIC_GATT_SERVICE_UUID = '6e400001-b5a3-f393-e0a9-e50e24dcca9e';

// Characteristics
export const FIRMWARE_UUID = '00002a26-0000-1000-8000-00805f9b34fb';
export const HARDWARE_UUID = '00002a27-0000-1000-8000-00805f9b34fb';
export const MANUFACTURE_NAME_UUID = '00002a29-0000-1000-8000-00805f9b34fb';
export const MODEL_NAME_UUID = '00002a24-0000-1000-8000-00805f9b34fb';
export const BATTERY_LEVEL_UUID = '00002a19-0000-1000-8000-00805f9b34fb';
export const CHARGE_UUID = '00001540-1212-efde-1523-785feabcd133';
export const PUFF_COUNTER_UUID = '00001235-1212-efde-1523-785feabcd133';
export const MAC_ADDRESS_UUID = '00001570-1212-efde-1523-785feabcd133';
export const CARTRIDGE_ID_UUID = '00001580-1212-efde-1523-785feabcd133';
export const CARTRIDGE_RESISTANCE_UUID = '00001590-1212-efde-1523-785feabcd133';
export const PUFF_START_VOLTAGE_UUID = '000015b0-1212-efde-1523-785feabcd133';
export const PUFF_END_VOLTAGE_UUID = '000015c0-1212-efde-1523-785feabcd133';
export const RSSI_UUID = '000015a0-1212-efde-1523-785feabcd133';
export const NORDIC_RX_UUID = '6e400042-b5a3-f393-e0a9-e50e24dcca9e';
export const NORDIC_TX_UUID = '6e400043-b5a3-f393-e0a9-e50e24dcca9e';
export const UNIX_TIME = '00001526-1212-efde-1523-785feabcd133';
export const CHILD_LOCK = '00001240-1212-efde-1523-785feabcd133';

export enum Characteristics {
  FIRMWARE_VERSION = 'firmware_version',
  HARDWARE_VERSION = 'hardware_version',
  MANUFACTURER_NAME = 'manufacturer_name',
  MODEL_NAME = 'model_name',
  BATTERY_LEVEL = 'battery_level',
  CHARGING = 'charging',
  PUFF_COUNTER = 'puff_counter',
  MAC_ADDRESS = 'mac_address',
  CARTRIDGE_ID = 'cartridge_id',
  CARTRIDGE_RESISTANCE = 'cartridge_resistance',
  LAST_PUFF_VOLTAGE_START = 'last_puff_voltage_start',
  LAST_PUFF_VOLTAGE_END = 'last_puff_voltage_end',
  RSSI = 'rssi',
  NORDIC_RX = 'nordic_rx',
  NORDIC_TX = 'nordic_tx',
  UNIX_TIME = 'unix_time',
  CHILD_LOCK = 'child_lock',
}

export const uuidsByCharacteristicMap = {
  [Characteristics.FIRMWARE_VERSION]: [
    DEVICE_INFORMATION_GATT_SERVICE_UUID,
    FIRMWARE_UUID,
  ],
  [Characteristics.HARDWARE_VERSION]: [
    DEVICE_INFORMATION_GATT_SERVICE_UUID,
    HARDWARE_UUID,
  ],
  [Characteristics.MODEL_NAME]: [
    DEVICE_INFORMATION_GATT_SERVICE_UUID,
    MODEL_NAME_UUID,
  ],
  [Characteristics.MANUFACTURER_NAME]: [
    DEVICE_INFORMATION_GATT_SERVICE_UUID,
    MANUFACTURE_NAME_UUID,
  ],
  [Characteristics.BATTERY_LEVEL]: [
    BATTERY_INFORMATION_GATT_SERVICE_UUID,
    BATTERY_LEVEL_UUID,
  ],
  [Characteristics.CHARGING]: [PLONQ_GATT_SERVICE_UUID, CHARGE_UUID],
  [Characteristics.PUFF_COUNTER]: [PLONQ_GATT_SERVICE_UUID, PUFF_COUNTER_UUID],
  [Characteristics.MAC_ADDRESS]: [PLONQ_GATT_SERVICE_UUID, MAC_ADDRESS_UUID],
  [Characteristics.CARTRIDGE_ID]: [PLONQ_GATT_SERVICE_UUID, CARTRIDGE_ID_UUID],
  [Characteristics.CARTRIDGE_RESISTANCE]: [
    PLONQ_GATT_SERVICE_UUID,
    CARTRIDGE_RESISTANCE_UUID,
  ],
  [Characteristics.LAST_PUFF_VOLTAGE_START]: [
    PLONQ_GATT_SERVICE_UUID,
    PUFF_START_VOLTAGE_UUID,
  ],
  [Characteristics.LAST_PUFF_VOLTAGE_END]: [
    PLONQ_GATT_SERVICE_UUID,
    PUFF_END_VOLTAGE_UUID,
  ],
  [Characteristics.RSSI]: [PLONQ_GATT_SERVICE_UUID, RSSI_UUID],
  [Characteristics.NORDIC_RX]: [NORDIC_GATT_SERVICE_UUID, NORDIC_RX_UUID],
  [Characteristics.NORDIC_TX]: [NORDIC_GATT_SERVICE_UUID, NORDIC_TX_UUID],
  [Characteristics.UNIX_TIME]: [PLONQ_GATT_SERVICE_UUID, UNIX_TIME],
  [Characteristics.CHILD_LOCK]: [PLONQ_GATT_SERVICE_UUID, CHILD_LOCK],
};

export enum PlonqDeviceCommands {
  READ_PUFF_ARRAY = 'read_puff_array',
  READ_PUFF = 'read_puff',
  CLEAR_PUFF = 'clear_puff',
  RESET = 'reset',
  REBOOT = 'reboot',
  BOOTLOADER = 'bootloader',
  LOCATION = 'location',
  STOP_LOCALISATION = 'stop_localisation',
}

export const BLECommandsMap = {
  [PlonqDeviceCommands.READ_PUFF_ARRAY]: getPuffArrayCommand,
  [PlonqDeviceCommands.READ_PUFF]: getPuffCommand,
  [PlonqDeviceCommands.CLEAR_PUFF]: clearPuffArrayCommand,
  [PlonqDeviceCommands.RESET]: resetCommand,
  [PlonqDeviceCommands.REBOOT]: rebootCommand,
  [PlonqDeviceCommands.BOOTLOADER]: bootloaderCommand,
  [PlonqDeviceCommands.LOCATION]: locationCommand,
  [PlonqDeviceCommands.STOP_LOCALISATION]: stopLocalisationCommand,
};

export const VERSION_WITH_PACKAGE_GPA = '1.17.16';
export const VERSION_WITH_GPA = '1.16.26';
export const VERSION_FRACTURE = '1.16.28';
export const VERSION_WITH_CHILD_LOCK = '1.16.30';
export const VERSION_WITH_BATTERY_CHARGING_TIME = '1.17.31';
export const VERSION_WITH_SET_DEVICE_TIMESTAMP = '1.17.31';
