import { API_URL, APP_URL } from 'lib/constants';
import {
  getRefreshTokenFromCookies,
  removeRefreshTokenFromCookies,
  removeTokenFromCookies,
  setRefreshTokenToCookies,
  setTokenToCookies,
} from 'lib/services/cookieService';

export async function refreshToken(): Promise<string | null> {
  const refreshToken = getRefreshTokenFromCookies();

  if (!refreshToken) {
    return null;
  }

  const response = await fetch(`${API_URL.baseUrl}/auth/refresh`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refreshToken }),
  });

  const body = await response.json();

  if (body.statusCode === 401 && body.message === 'Токен не найден') {
    removeRefreshTokenFromCookies();
    removeTokenFromCookies();

    window.location.href = APP_URL || '';

    return null;
  }

  setTokenToCookies(body.token);
  setRefreshTokenToCookies(body.refreshToken);

  return body.token;
}
