import { yupResolver } from '@hookform/resolvers/yup';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { ERROR_TEXTS, REGEX } from 'lib/constants';
import { getPhonePasteValue } from 'lib/helpers/getPhonePasteValue';
import { withRedirect } from 'lib/hocs';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import { useSignInMutation } from 'store/api/auth';
import styled, { css } from 'styled-components';
import { ElementInputType } from 'types';
import { BackButton, Button, Icon, Input, Logo, UserBannedModal } from 'ui';
import * as yup from 'yup';

type FormData = {
  phone: string;
  password: string;
};

const BANNED_ERROR_TEXT = 'Пользователь заблокирован';

const schema = yup
  .object({
    phone: yup
      .string()
      .matches(REGEX.phone, { message: ERROR_TEXTS.phone })
      .required(ERROR_TEXTS.required),
    password: yup.string().required(ERROR_TEXTS.emptyPassword),
  })
  .required();

const Login = () => {
  const navigate = useNavigate();
  const [signIn, { isLoading }] = useSignInMutation();
  const [isError, setIsError] = useState(false);
  const [isBanned, setIsBanned] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty, isValid, isSubmitted, touchedFields },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const phoneField = watch('phone');
  const passwordField = watch('password');

  const onSubmit: SubmitHandler<FormData> = (values) => {
    setIsError(false);
    signIn(values)
      .unwrap()
      .then(() => {
        navigate(ROUTES.ROOT);
      })
      .catch((err) => {
        if (
          err.data?.statusCode === 403 &&
          err.data?.message === BANNED_ERROR_TEXT
        ) {
          setIsBanned(true);
        }
        setIsError(true);
      });
  };

  useEffect(() => {
    setIsError(false);
  }, [phoneField, passwordField]);

  const handleUserBannedModalClose = () => {
    setIsBanned(false);
    setIsError(false);
  };

  return (
    <MainLayout>
      <ContentWrapper>
        <Header>
          <StyledBackButton />
          <Logo />
        </Header>

        <Description>
          Приложение, которое поможет контролировать потребление никотина
        </Description>
        <Title>Войти в профиль</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldsWrapper>
            <StyledPhoneInput
              id="phone"
              autoFocus
              placeholder="Номер телефона"
              type={ElementInputType.tel}
              icon={<Icon type="call" />}
              isError={isError}
              onPaste={(event) => {
                setValue('phone', getPhonePasteValue(event));
              }}
              error={touchedFields.phone ? errors.phone?.message : ''}
              {...register('phone')}
            />
            <StyledPasswordInput
              id="password"
              placeholder="Пароль"
              type={ElementInputType.password}
              icon={<Icon type="lock" />}
              isError={isError}
              error={errors.password?.message}
              {...register('password')}
            />

            {isError && !isBanned && (
              <BackendError>{ERROR_TEXTS.invalidPhoneOrPassword}</BackendError>
            )}
          </FieldsWrapper>

          <StyledRestoreLink to={ROUTES.RESTORE_PASSWORD}>
            Забыли пароль?
          </StyledRestoreLink>
          <StyledButton
            type="submit"
            variant="primary"
            isLoading={isLoading}
            isDisabled={(!isDirty || !isValid) && !isSubmitted}
          >
            Войти в профиль
          </StyledButton>
          <RegistrationLinkWrapper>
            <RegistrationText>Нет учетной записи?</RegistrationText>
            <StyledRegisterLink to={ROUTES.REGISTRATION}>
              Регистрация
            </StyledRegisterLink>
          </RegistrationLinkWrapper>
        </Form>
      </ContentWrapper>

      <UserBannedModal
        isOpen={isBanned}
        closeCallBack={handleUserBannedModalClose}
      />
    </MainLayout>
  );
};

const ContentWrapper = styled.div`
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: -10px;
  top: -2px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
  margin-bottom: 68px;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1}

  color: ${COLORS.black};
  margin-bottom: 28px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FieldsWrapper = styled.div`
  position: relative;
  margin-bottom: 28px;
`;

const BackendError = styled.p`
  ${TYPOGRAPHY.subline1}
  position: absolute;
  top: calc(100% + 4px);
  left: 20px;
  color: ${COLORS.red};
`;

const StyledPhoneInput = styled(Input)`
  margin-bottom: 24px;
  width: 100%;
`;

const StyledPasswordInput = styled(Input)`
  width: 100%;
`;

const linkDefaultStyle = css`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
  transition: color 0.2s;

  &:hover,
  &:active {
    color: ${COLORS.black};
  }
`;

const StyledRestoreLink = styled(Link)`
  ${linkDefaultStyle}
  margin: 0 auto 110px;
`;

const StyledButton = styled(Button)`
  margin-bottom: 18px;
`;

const RegistrationLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const RegistrationText = styled.p`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
  margin-right: 13px;
`;

const StyledRegisterLink = styled(Link)`
  ${linkDefaultStyle}
  font-weight: 400;
`;

export default withRedirect(Login);
