import { TYPOGRAPHY } from 'assets/styles/typography';
import { FC } from 'react';
import styled from 'styled-components';
import { Modal } from 'ui/Modal';

type NotifyModalProps = {
  isOpen: boolean;
  text?: string;
  closeCallback: () => void;
};

export const NotifyModal: FC<NotifyModalProps> = ({
  isOpen,
  text,
  closeCallback,
}) => {
  return (
    <Modal isOpen={isOpen} closeCallback={closeCallback}>
      <Content>
        <Title>{text}</Title>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 316px;
  min-height: 114px;
  border-radius: 20px;
  padding: 0 30px;
  background-color: white;
`;

const Title = styled.p`
  text-align: center;
  ${TYPOGRAPHY.popUps}
`;
