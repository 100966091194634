import { COLORS } from 'assets/styles/colors';
import { DEVICE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import {
  IUpdateDeviceState,
  UpdateDeviceStates,
} from 'pages/UpdateDevice/UpdateDevice';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import styled from 'styled-components';
import { Button } from 'ui';

import { StepItem } from './components';

interface Props extends IUpdateDeviceState {
  deviceId?: string;
  isCharging: boolean;
  isFirmwareFilePrepared: boolean;
  updateDevice: () => void;
  prepareUpdate: () => void;
  setErrorText: (errorText: string) => void;
}

/**
 * Экран подготовки к установки новой прошивки.
 *
 * После подтверждения установки устройство переходит в режим bootloader.
 * Для продолжения установки требуется переподключиться к устройству под новым именем.
 */
export const PrepareState: FC<Props> = ({
  deviceId,
  isCharging,
  isFirmwareFilePrepared,
  setErrorText,
  updateDevice,
  changeState,
  prepareUpdate,
}) => {
  const navigate = useNavigate();

  const handleConfirm = async () => {
    try {
      if (!isFirmwareFilePrepared) {
        return await prepareUpdate();
      }

      await updateDevice();
      changeState(UpdateDeviceStates.PROCESS);
    } catch (error: any) {
      setErrorText(error.message);
      changeState(UpdateDeviceStates.FAILED);
    }
  };

  const handleCancel = () => {
    return navigate(deviceId ? ROUTES.DEVICE_ID(deviceId) : ROUTES.ROOT);
  };

  const isButtonDisabled = !isCharging && !isFirmwareFilePrepared;

  return (
    <Root>
      <Title>
        Подготовка <br /> к установке...
      </Title>
      <Subtitle>Пожалуйста, убедитесь, что:</Subtitle>

      <StepsWrapper>
        <StyledStepItem
          stepNumber={1}
          isChecked={isCharging || isFirmwareFilePrepared}
          text={
            <>
              Устройство Plonq X поставлено на <BoldSpan>зарядку</BoldSpan> и
              заряжается
            </>
          }
        />
        <StepItem
          stepNumber={2}
          isChecked={isFirmwareFilePrepared}
          text={
            <>
              <BoldSpan>Перед началом установки</BoldSpan>, убедитесь что
              индикатор на устройстве загорелся <BoldSpan>синим</BoldSpan>{' '}
              цветом
            </>
          }
        />
      </StepsWrapper>

      <ButtonWrapper>
        <SubmitButton
          onClick={handleConfirm}
          variant="primary"
          disabled={isButtonDisabled}
        >
          {!isFirmwareFilePrepared ? 'Продолжить' : 'Выбрать устройство'}
        </SubmitButton>
        <CancelButton onClick={handleCancel}>Отмена</CancelButton>
      </ButtonWrapper>
    </Root>
  );
};

const Root = styled(MainLayout)`
  position: relative;
  @media ${DEVICE.mobileM} {
    padding: 109px 43px 20px 43px;
  }
`;

const Title = styled.h2`
  color: ${COLORS.black};
  ${TYPOGRAPHY.title2}
  margin-bottom: 48px;
`;

const Subtitle = styled.p`
  color: ${COLORS.black};
  ${TYPOGRAPHY.body1}
  margin-bottom: 49px;
`;

const StepsWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledStepItem = styled(StepItem)`
  margin-bottom: 27px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

const SubmitButton = styled(Button)`
  margin-bottom: 17px;
  max-width: 294px;
  width: 100%;
  background: ${COLORS.black};

  &:disabled {
    background: ${COLORS.gray4};
  }
`;

const CancelButton = styled.button`
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.body3}
  max-width: 165px;
  width: 100%;

  &:hover,
  &:active {
    color: ${COLORS.black};
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;
