import { UserSmokeStatisticsDateUnitEnum } from 'api';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled from 'styled-components';
import { LocalizeTimeType, StatisticDateUnits } from 'types';
import { Icon } from 'ui/Icon';

type QuestionMarkModalProps = {
  isPuffs?: boolean;
  onCloseCallback: () => void;
  dateUnit: UserSmokeStatisticsDateUnitEnum;
};

export const QuestionMarkModal = ({
  isPuffs = false,
  onCloseCallback,
  dateUnit,
}: QuestionMarkModalProps) => {
  return (
    <Root>
      <ModalContentContainer>
        <CloseButton onClick={onCloseCallback}>
          <Icon type="questionClose" />
        </CloseButton>

        {isPuffs ? (
          <>
            <ModalTitle>
              <Bold>Средний показатель затяжек</Bold> <br /> за{' '}
              {LocalizeTimeType[dateUnit]} на данное время
            </ModalTitle>
            <Description>
              {`Расчет среднего значения выводится из количества затяжек за ${
                dateUnit === StatisticDateUnits.WEEK
                  ? 'предыдущую'
                  : 'предыдущий'
              }
              ${LocalizeTimeType[dateUnit]}`}
            </Description>
          </>
        ) : (
          <>
            <ModalTitle>
              <Bold>
                Средний показатель <br /> никотина{' '}
              </Bold>{' '}
              за {LocalizeTimeType[dateUnit]}
            </ModalTitle>
            <Description>
              {`Расчет среднего значения выводится из количества мкг никотина за
              ${
                dateUnit === StatisticDateUnits.WEEK
                  ? 'предыдущую'
                  : 'предыдущий'
              }
              ${LocalizeTimeType[dateUnit]}`}
            </Description>
          </>
        )}
      </ModalContentContainer>
    </Root>
  );
};

const Root = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 25px;
  left: 0;
  top: 0;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  padding: 30px 49px 0;
`;

const ModalTitle = styled.p`
  ${TYPOGRAPHY.subtitle1}
  font-weight: 300;
  color: ${COLORS.darkBlue};
  text-align: center;
`;

const Bold = styled.span`
  ${TYPOGRAPHY.buttons}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 22px;
  right: 22px;
`;

const Description = styled.div`
  margin-top: 14px;
  text-align: center;
  color: ${COLORS.black};
  ${TYPOGRAPHY.body1}
`;
