import { yupResolver } from '@hookform/resolvers/yup';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { ERROR_TEXTS, REGEX } from 'lib/constants';
import { getPhonePasteValue } from 'lib/helpers/getPhonePasteValue';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ElementInputType } from 'types';
import { BackButton, Button, Icon, Input, Logo, Stepper } from 'ui';
import * as yup from 'yup';

type FormData = {
  phone: string;
};

interface StepGetPhoneProps {
  title?: string;
  defaultPhoneValue?: string;
  error?: string;
  isLoading?: boolean;
  withSteper?: boolean;
  onSubmit: (value: FormData) => void;
}

const schema = yup
  .object({
    phone: yup
      .string()
      .matches(REGEX.phone, { message: ERROR_TEXTS.phone })
      .required(ERROR_TEXTS.required),
  })
  .required();

export const StepGetPhone: FC<StepGetPhoneProps> = ({
  title,
  defaultPhoneValue,
  isLoading,
  withSteper,
  error,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isValid, isSubmitted, touchedFields },
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const hasError = Boolean(error);

  useEffect(() => {
    if (hasError) {
      setError('phone', {
        type: 'manual',
        message: error,
      });
    }
  }, [hasError]);

  return (
    <>
      <Header $withSteper={withSteper}>
        <StyledBackButton />
        <Logo />
      </Header>

      {withSteper && <StyledStepper />}

      <Title> {title}</Title>
      <Description>Введите номер телефона</Description>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FieldWrapper>
          <StyledPhoneInput
            id="phone"
            placeholder="Номер телефона"
            defaultValue={defaultPhoneValue}
            type={ElementInputType.tel}
            autoFocus
            icon={<Icon type="call" />}
            onPaste={(event) => {
              setValue('phone', getPhonePasteValue(event));
            }}
            error={touchedFields.phone ? errors.phone?.message : ''}
            {...register('phone')}
          />
        </FieldWrapper>

        <StyledButton
          type="submit"
          variant="primary"
          isLoading={isLoading}
          isDisabled={!isValid && !isSubmitted}
        >
          Получить код
        </StyledButton>
      </Form>
    </>
  );
};

const Header = styled.div<{ $withSteper?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ $withSteper }) => ($withSteper ? '29px' : '98px')};
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: -10px;
  top: -2px;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1}
  color: ${COLORS.black};
  margin-bottom: 28px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
  margin-bottom: 32px;
`;

const StyledStepper = styled(Stepper)`
  margin-bottom: 67px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FieldWrapper = styled.div`
  position: relative;
  margin-bottom: 235px;
`;

const StyledPhoneInput = styled(Input)`
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-bottom: 18px;
`;
