import { Z_INDEX_VARIABLES } from 'assets/styles/zIndex';
import { useHandleEscapePress, useLockBodyScroll } from 'lib/hooks';
import { FC } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

type ModalProps = {
  isOpen: boolean;
  closeCallback: () => void;
  className?: string;
  children?: React.ReactNode;
};

export const Modal: FC<ModalProps> = ({
  isOpen,
  closeCallback,
  className,
  children,
}) => {
  useHandleEscapePress(closeCallback);
  useLockBodyScroll(isOpen);

  return ReactDOM.createPortal(
    <>
      {isOpen && (
        <Root className={className}>
          <Backdrop onClick={closeCallback} />
          {children}
        </Root>
      )}
    </>,
    document.body,
  );
};

const Root = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: ${Z_INDEX_VARIABLES.zIndexFixedEl};
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  visibility: visible;
  align-items: center;
  justify-content: center;
  overscroll-behavior-y: contain;
  opacity: 1;
`;

const Backdrop = styled.div`
  z-index: ${Z_INDEX_VARIABLES.hidden};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.108);
`;
