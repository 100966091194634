/**
 * Корневой файл проекта
 */

import 'assets/styles/core.scss';
import 'dayjs/locale/ru';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from 'dayjs/plugin/isoWeek';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { createRoot } from 'react-dom/client';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { Provider } from 'react-redux';
import { RootRoutes } from 'routes';
import { store } from 'store';

import { FeatureToggleProvider } from './components/FeatureToogleContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? '1.0',
  ),
});

const gtmId = process.env.REACT_APP_GTM_ID;
if (gtmId) {
  const tagManagerArgs: TagManagerArgs = { gtmId };
  TagManager.initialize(tagManagerArgs);
}

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'через %s',
    past: '%s назад',
    s: 'несколько секунд',
    m: '1 мин.',
    mm: '%d мин.',
    h: 'час',
    hh: '%d ч.',
    d: '1 дн.',
    dd: '%d дн.',
    M: '1 мес.',
    MM: '%d мес.',
    y: '1 г.',
    yy: '%d г.',
  },
});
dayjs.extend(isoWeek);
const container = document.getElementById('root');

// eslint-disable-next-line  @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <FeatureToggleProvider>
      <RootRoutes />
    </FeatureToggleProvider>
  </Provider>,
);

serviceWorkerRegistration.register();
