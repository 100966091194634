import JSZip from 'jszip';

function Package(buffer) {
  this.buffer = buffer;
  this.zipFile = null;
  this.manifest = null;
}

Package.prototype.load = function () {
  return JSZip.loadAsync(this.buffer)
    .then((zipFile) => {
      this.zipFile = zipFile;
      try {
        return this.zipFile.file('manifest.json').async('string');
      } catch (e) {
        throw new Error(
          'Unable to find manifest, is this a proper DFU package?',
        );
      }
    })
    .then((content) => {
      this.manifest = JSON.parse(content).manifest;
      return this;
    });
};

Package.prototype.getImage = function (types) {
  for (const type of types) {
    if (this.manifest[type]) {
      const entry = this.manifest[type];

      const result = {
        type: type,
        initFile: entry.dat_file,
        imageFile: entry.bin_file,
      };

      return this.zipFile
        .file(result.initFile)
        .async('arraybuffer')
        .then((data) => {
          result.initData = data;
          return this.zipFile.file(result.imageFile).async('arraybuffer');
        })
        .then((data) => {
          result.imageData = data;
          return result;
        });
    }
  }
};

Package.prototype.getBaseImage = function () {
  return this.getImage(['softdevice', 'bootloader', 'softdevice_bootloader']);
};

Package.prototype.getAppImage = function () {
  return this.getImage(['application']);
};

export default Package;
