export function getPhonePasteValue(
  event: React.ClipboardEvent<HTMLInputElement>,
) {
  const text = event.clipboardData.getData('Text');
  const textWithoutSimbols = text.replace(/[- )(]/g, '');
  if (textWithoutSimbols.length > 10) {
    return textWithoutSimbols.split('').splice(-10).join('');
  }
  return textWithoutSimbols;
}
