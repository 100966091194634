import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserSmokeStatistics } from 'api';

import { StatisticsState } from './types';

const initialState: StatisticsState = {
  smokeStats: {
    periodStats: [],
    dateUnit: 'day',
    totalForPeriod: 0,
    averageForPeriod: 0,
  },
};

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setSmokeStats: (state, { payload }: PayloadAction<UserSmokeStatistics>) => {
      state.smokeStats.periodStats = payload.full.nodes;
      state.smokeStats.dateUnit = payload.dateUnit;
      state.smokeStats.totalForPeriod = payload.full.totalForPeriod;
      state.smokeStats.averageForPeriod = payload.full.averageForPeriod;
    },
  },
});

export const { setSmokeStats } = statisticsSlice.actions;
