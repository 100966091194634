import { useCallback, useMemo } from 'react';

import { CompareVersion } from '../../../helpers/compareVersions';
import { VERSION_WITH_SET_DEVICE_TIMESTAMP } from '../../constants';
import { PlonqDevice } from '../plonq-device';

const useDeviceTimestamp = () => {
  const setDeviceCurrentTimestamp = useCallback(() => {
    PlonqDevice.getInstance().setDeviceCurrentTimestamp().then();
  }, []);

  const hasSetDeviceTimestamp = useMemo(() => {
    try {
      return CompareVersion(
        PlonqDevice.getInstance().getFirmware(),
        VERSION_WITH_SET_DEVICE_TIMESTAMP,
      );
    } catch (err) {
      return false;
    }
  }, []);
  return [setDeviceCurrentTimestamp, hasSetDeviceTimestamp] as const;
};

export default useDeviceTimestamp;
