import { COLORS } from 'assets/styles/colors';
import { SIZE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled from 'styled-components';
import { Icon } from 'ui/Icon';
import { SwipeableBottomDrawer } from 'ui/SwipeableBottomDrawer';

type ErrorDrawerProps = {
  isOpen: boolean;
  closeCallBack: () => void;
  text: string;
};

export const ErrorDrawer = ({
  isOpen,
  closeCallBack,
  text,
}: ErrorDrawerProps) => {
  return (
    <SwipeableBottomDrawer isOpen={isOpen} onCloseCallback={closeCallBack}>
      <ContentWrapper>
        <IconWrap>
          <Icon type="cone" />
        </IconWrap>
        <ModalTitle>Ошибка</ModalTitle>
        <ModalText>{text}</ModalText>
      </ContentWrapper>
    </SwipeableBottomDrawer>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 28px;
  padding: 86px 39px 230px 39px;

  @media (max-width: ${SIZE.mobileM}) {
    padding: 86px 28px 200px 28px;
  }
`;

const ModalTitle = styled.p`
  ${TYPOGRAPHY.title1}
  color: ${COLORS.red};
`;

const ModalText = styled.p`
  color: ${COLORS.red};
  ${TYPOGRAPHY.body1}
  text-align: center;
  max-width: 314px;
`;

const IconWrap = styled.div`
  background-color: ${COLORS.red};
  width: 52px;
  height: 52px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
