import { CompareVersion } from 'lib/helpers/compareVersions';
import { debounce } from 'lodash';

import {
  clearPuffArrayCommand,
  getPuffArrayCommand,
  getPuffCommand,
  puffPackageResponse,
  puffResponse,
} from '../bluetooth/commands';
import {
  VERSION_FRACTURE,
  VERSION_WITH_GPA,
  VERSION_WITH_PACKAGE_GPA,
} from '../constants';
import { IPuff } from '../interfaces';
import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqDevice } from './plonq-device';
import { PlonqNordicConnectedService } from './plonq-nordic-connected-service';

type UpdateHandler = (device: PlonqPuffArray) => void;

/**
 * Класс отвечает за реализацию работы со списком записей о затяжках.
 * Для работы необходим подключенный экземпляр PlonqDevice.
 *
 * На устройстве записи о затяжках хранятся с неправильным таймстемпом,
 * поэтому класс хранит в себе timestampOffset. timestampOffset вычисляется
 * на этапе первичной синхронизации в классе {@link PlonqDevice}
 *
 * Содержит несколько методов синхронизации затяжек, тк механизм
 * отличается для разных версий прошивок.
 *
 * @deprecated
 * Для обновления данных во время подключения используется подписка
 * на характеристику puffCounter.
 *
 * @updated
 * Для обновления данных во время подключения используется long pulling
 * записей о затяжках раз в 3 секунды. Очищение устройства сделано через
 * debounced в 5 секунд. Для работы добавлено поле lastSyncCount для выгрузки
 * только нужных записей внутри цикла debounce. После каждой выгрузки полученное
 * количество записей(puffCount) сравнивается с lastSyncCount. В случае если записей больше lastSyncCount
 * выгружаются записи с lastSyncCount + 1 по puffCount
 *
 * Класс хранит в себе кэшированный массив записей о затяжках и
 * предоставляет его клиентскому коду. При добавлении новой записи в
 * массив класс уведомляет всех подписантов на это событие.
 *
 * Основное место использование класса хук {@link usePuffUpload}.
 *
 * Для интеграции c React компонентами нужно обернуть в хук.
 *
 * Используется в хуке {@link usePuffArrayUpload}
 */
export class PlonqPuffArray extends PlonqNordicConnectedService {
  private puffArray: IPuff[] = [];
  private static instance?: PlonqPuffArray;
  private updateHandlers: UpdateHandler[] = [];
  private timestampOffset = 0;
  private isSynchronizing = false;
  private lastSyncCount = 0;

  // Для создания необходимо чтобы PlonqDevice был инициализирован
  private constructor() {
    super();

    // Необходимо для корректировки UNIX time установленного на устройстве
    this.timestampOffset = PlonqDevice.getInstance().getTimestampOffset();
  }

  public static getInstance(): PlonqPuffArray {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new PlonqPuffArray();

    return this.instance;
  }

  public getPuffArrayLength(): number {
    return this.puffArray.length;
  }

  public getPuff(index: number) {
    return this.puffArray[index];
  }

  public getPuffArray(from?: number, to?: number): IPuff[] {
    return this.puffArray.slice(from, to);
  }

  public getTimestampOffset(): number {
    return this.timestampOffset;
  }

  public deletePuffs(index = 0) {
    this.puffArray = this.puffArray.slice(index);
  }

  public async clearFromDevice(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    console.log('clear device');
    this.bleConnection.sendRequest(clearPuffArrayCommand(), true);
    this.lastSyncCount = 0;
  }

  private async loadLastPuff(puffCount: number): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    // Необходимо для игнорирования затяжек на время синхронизации
    if (this.isSynchronizing) {
      return;
    }

    if (puffCount === 0) {
      return;
    }

    const puffBinary = await this.bleConnection.sendRequest(
      getPuffCommand(puffCount),
    );

    const puff = puffResponse(puffBinary as DataView);
    console.log('[loadLastPuff, ble puff]', puff);
    this.puffArray.unshift({
      ...puff,
      timestamp: puff.timestamp + this.timestampOffset,
      number: 0,
    });

    this.dispatchUpdate();
  }

  private dispatchUpdate(): void {
    for (const listener of this.updateHandlers) listener(this);
  }

  public subscribeOnUpdates(addingHandler: UpdateHandler): void {
    this.updateHandlers.push(addingHandler);
  }

  public unsubscribeFromUpdate(removingHandler: UpdateHandler): void {
    this.updateHandlers = this.updateHandlers.filter(
      (handler) => handler !== removingHandler,
    );
  }

  private clearPuffArrayData = (): void => {
    PlonqPuffArray.instance = undefined;
    this.isConnected = false;
    this.bleConnection = undefined;
    this.puffArray = [];
    this.updateHandlers = [];
    this.timestampOffset = 0;
  };

  public async syncWithBLE(): Promise<void> {
    console.log('Sync start');
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    this.bleConnection.onDisconnect(this.clearPuffArrayData.bind(this));

    console.log('Sync end');

    if (
      // Проверка на наличие package GPA
      CompareVersion(
        PlonqDevice.getInstance().getFirmware(),
        VERSION_WITH_PACKAGE_GPA,
      )
    ) {
      console.log('skip sync. (cause: firmware version is not actual)');
      return;
    }

    setTimeout(this.updatePuffArray.bind(this), 3000);
  }

  private async syncPuff(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    this.isSynchronizing = true;
    const puffCount = await this.bleConnection.getPuffCount();

    console.log('puff count: ', puffCount);

    if (
      // Проверка на наличие GPA
      CompareVersion(PlonqDevice.getInstance().getFirmware(), VERSION_FRACTURE)
    ) {
      console.log('skip sync. (cause: firmware version)');
      return;
    }

    if (
      // Проверка на наличие GPA
      CompareVersion(PlonqDevice.getInstance().getFirmware(), VERSION_WITH_GPA)
    ) {
      await this.syncPuffSeparately(puffCount);
    } else {
      await this.syncPuffWithGPA(puffCount);
    }

    this.dispatchUpdate();
    this.isSynchronizing = false;
  }

  // Синхронизация для getPuffArray
  private async syncPuffWithGPA(puffCount: number) {
    console.log('Sync with GPA');

    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    if (puffCount <= 0) {
      return;
    }

    const puffBinaries = (await this.bleConnection.sendRequest(
      getPuffArrayCommand(1, puffCount),
    )) as DataView[];

    this.puffArray = puffBinaries.map((puffBinary) => {
      const puff = puffResponse(puffBinary);

      return {
        ...puff,
        timestamp: puff.timestamp + this.timestampOffset,
        number: 0,
      };
    });
  }

  // Синхронизация по-штучно
  private async syncPuffSeparately(puffCount: number) {
    console.log('Sync without GPA');

    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    for (let i = 1; i <= puffCount; ++i) {
      // Необходимо для сброса тротлинга GATT сервера.
      if (i % 50 === 0) {
        await this.refreshConnection();
      }

      console.log('puff number: ', i);
      const puffBinary = await this.bleConnection.sendRequest(
        getPuffCommand(i),
      );

      const puff = puffResponse(puffBinary as DataView);

      this.puffArray.unshift({
        ...puff,
        timestamp: puff.timestamp + this.timestampOffset,
        number: 0,
      });
    }
  }

  // Подписка на изменение количества затяжек
  private async subscribeChanges(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    await this.bleConnection.subscribePuffCount(this.loadLastPuff.bind(this));
  }

  debouncedDeviceClear = debounce(this.clearFromDevice.bind(this), 5000);

  private async updatePuffArray(): Promise<void> {
    try {
      console.log('[PULLING_PUFFS]: start');
      if (!this.bleConnection) {
        throw new BLEConnectionNotInstalled();
      }

      const puffCount = await this.bleConnection.getPuffCount();
      console.log(
        `[PULLING_PUFFS]: count ${puffCount}, last sync puff ${this.lastSyncCount}`,
      );

      if (puffCount > this.lastSyncCount) {
        console.log(`[PULLING_PUFFS]: pull puffs`);
        const puffPackageBinaries = (await this.bleConnection.sendRequest(
          getPuffArrayCommand(this.lastSyncCount + 1, puffCount),
        )) as DataView[];
        console.log(
          `[PULLING_PUFFS]: pulled ${puffPackageBinaries.length} puff packages`,
        );

        console.log(
          `[PULLING_PUFFS]: pulled puff packages`,
          JSON.stringify(puffPackageBinaries),
        );
        for (const puffPackageBinary of puffPackageBinaries) {
          const puffsPackage = puffPackageResponse(puffPackageBinary);
          console.log('[PUFFS_PACKAGE]:', puffsPackage);
          this.puffArray = this.puffArray.concat(
            puffsPackage.map((puff) => ({
              ...puff,
              timestamp: Math.min(
                puff.timestamp + this.timestampOffset,
                Date.now(),
              ),
            })),
          );
        }

        this.lastSyncCount = puffCount;
        this.debouncedDeviceClear();
      } else {
        console.log('[PULLING_PUFFS]: no new puffs');
      }

      console.log('[PULLING_PUFFS]: end');
    } catch (err) {
      console.log('[PULLING_PUFFS]: error', err);
      throw err;
    } finally {
      setTimeout(this.updatePuffArray.bind(this), 3000);
    }
  }
}
