import { UserFirmwareDownloadFileKey } from 'api';
import { API_URL } from 'lib/constants';
import { HttpMethods } from 'types';

import { baseApi } from '../baseApi';

export const firmwareApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFirstAvailableFirmware: builder.query<
      UserFirmwareDownloadFileKey,
      { deviceId: string }
    >({
      query: ({ deviceId }) => ({
        url: API_URL.getFirstAvailableFirmware(deviceId),
        method: HttpMethods.get,
      }),
      providesTags: ['firmwares'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useGetFirstAvailableFirmwareQuery } = firmwareApi;
