export const COLORS = {
  darkBlue: '#657786',
  black: '#323232',
  lightBlue: '#D2D8DD',
  lightViolet: '#F2F1F7',
  darkViolet: '#D3D3D3',
  red: '#D0736D',
  grey: '#ECECEC',
  gray2: '#EFEFEF',
  gray3: '#DCDCDC',
  gray4: '#ADADAD',
  grey5: '#AAA8B7',
  white: '#ffffff',
  bar: '#BDC8CC',
};

export const TRANSPARENCY_COLORS = {
  darkBlueHalf: 'rgba(101, 119, 134, 0.5)',
  darkBlueSeventy: 'rgba(101, 119, 134, 0.75)',
  darkBlueSixty: 'rgba(101, 119, 134, 0.6)',
  darkBlueThirty: 'rgba(101, 119, 134, 0.3)',
  lightBlue: 'rgba(210, 216, 221, 0.3)',
  redThirty: 'rgba(208, 115, 109, 0.3)',
  blackHalf: 'rgba(50, 50, 50, 0.5)',
  barHalf: 'rgba(189, 200, 204, 0.5)',
  blackThirty: 'rgba(50, 50, 50, 0.3)',
};
