import { useCallback } from 'react';

import { PlonqLocation } from '../plonq-location';

/**
 * Хук инкапсулирует логику функции поиска устройства.
 * Использует экземпляр {@link PlonqLocation} для доступа к устройству.
 *
 * @example
 * const callDeviceSearchFunction = useDeviceLocationSignal();
 * await callDeviceSearchFunction();
 *
 * @returns функцию, которая отправляет на устройство соответствующую команду.
 */
const useDeviceStopLocationSignal = () => {
  return useCallback(() => {
    PlonqLocation.getInstance().sendStopLocationSignal().then();
  }, []);
};

export default useDeviceStopLocationSignal;
