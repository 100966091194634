import { TRANSPARENCY_COLORS } from 'assets/styles/colors';
import { FC } from 'react';
import styled from 'styled-components';

interface StepperProps {
  steps?: number;
  activeStep?: number;
  className?: string;
}

export const Stepper: FC<StepperProps> = ({
  steps = 2,
  activeStep = 0,
  className,
}) => {
  return (
    <Root className={className}>
      {new Array(steps).fill(0).map((_, index) => {
        return <Step key={index} isActive={activeStep === index} />;
      })}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 2px;
`;

const Step = styled.div<{ isActive?: boolean }>`
  width: 83px;
  height: 4px;
  border-radius: 4px;
  border: 0.5px solid ${TRANSPARENCY_COLORS.darkBlueHalf};
  ${({ isActive }) =>
    isActive && `background-color: ${TRANSPARENCY_COLORS.darkBlueHalf};`}
`;
