import { StorageService } from 'lib/services';
import { ComponentType, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import { StorageKeys } from 'types';

/**
 * Компонент высокого порядка для реализации логики редиректов связанных с авторизацией.
 */
export const withRedirect = <T extends Record<string, any>>(
  Component: ComponentType<T>,
) => {
  function WithRedirect(props: T) {
    const navigate = useNavigate();
    const birthday = StorageService.get<string>(StorageKeys.DATE_OF_BIRTH);

    useEffect(() => {
      if (!birthday) {
        navigate(ROUTES.AGE_CHECK, { replace: true });
      }
    }, [birthday]);

    return <Component {...props} />;
  }

  const wrappedName = Component.displayName || Component.name || 'Component';
  WithRedirect.displayName = `withRedirect(${wrappedName})`;

  return WithRedirect;
};
