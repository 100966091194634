import { UserSmokeStatisticsDateUnitEnum } from 'api';
import { COLORS, TRANSPARENCY_COLORS } from 'assets/styles/colors';
import { SIZE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import dayjs from 'dayjs';
import { ROTATE_RIGHT } from 'lib/constants';
import { useState } from 'react';
import styled from 'styled-components';
import { StatisticDateUnits } from 'types';
import { Icon } from 'ui/Icon/Icon';

import { OneAverageValue } from './components/OneAverageValue';
import { QuestionMarkModal } from './components/QuestionMarkModal';

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

type AverageValuesProps = {
  puffsValue: number;
  puffsAverageValue: number;
  nicotineValue: number;
  nicotineAverageValue: number;
  isArrowUp: boolean;
  isLoading?: boolean;
  dateUnit: UserSmokeStatisticsDateUnitEnum;
  intervalStart: dayjs.Dayjs;
  intervalEnd: dayjs.Dayjs;
};

export const AverageValues = ({
  puffsValue,
  puffsAverageValue,
  nicotineValue,
  nicotineAverageValue,
  isArrowUp,
  isLoading,
  dateUnit,
  intervalStart,
  intervalEnd,
}: AverageValuesProps) => {
  const localDateNow = dayjs().locale('ru');

  const [isAverageNicotineModalOpen, setIsAverageNicotineModalOpen] =
    useState(false);

  const [isAveragePuffsModalOpen, setIsAveragePuffsModalOpen] = useState(false);

  return (
    <Root>
      {isLoading && (
        <Blur>
          <SpinnerWrap>
            <StyledSpinnerIcon type="buttonLoader" />
            Синхронизация
          </SpinnerWrap>
        </Blur>
      )}
      <DateContainer>
        <DatePeriod>
          {dateUnit === StatisticDateUnits.DAY
            ? 'Сегодня, '
            : dateUnit === StatisticDateUnits.WEEK
            ? 'Неделя, '
            : ''}
          <Bold>
            {dateUnit === StatisticDateUnits.DAY
              ? intervalStart.format('D MMMM')
              : dateUnit === StatisticDateUnits.WEEK
              ? `${intervalStart.format('D')} - ${intervalEnd.format('D MMMM')}`
              : capitalizeFirstLetter(intervalStart.format('MMMM'))}
          </Bold>
        </DatePeriod>
        <Time>{localDateNow.format('H:mm')}</Time>
      </DateContainer>
      <Line>
        <OneAverageValue
          isArrowUp={isArrowUp}
          value={puffsValue}
          icon={<Icon type="puffs" />}
          text={'Затяжки'}
          openModalCallback={() => {}}
        />
        <Separator />
        <OneAverageValue
          isLight
          value={puffsAverageValue}
          text={'Среднее'}
          isAverage
          openModalCallback={() => setIsAveragePuffsModalOpen(true)}
        />
      </Line>
      <Line>
        <OneAverageValue
          isArrowUp={isArrowUp}
          value={nicotineValue}
          icon={<Icon type="nicotine" />}
          text={'Никотин'}
          isNicotine
        />
        <Separator />
        <OneAverageValue
          isLight
          value={nicotineAverageValue}
          text={'Среднее'}
          isAverage
          isNicotine
          openModalCallback={() => setIsAverageNicotineModalOpen(true)}
        />
      </Line>
      {isAverageNicotineModalOpen && (
        <QuestionMarkModal
          onCloseCallback={() => {
            setIsAverageNicotineModalOpen(false);
          }}
          dateUnit={dateUnit}
        />
      )}
      {isAveragePuffsModalOpen && (
        <QuestionMarkModal
          dateUnit={dateUnit}
          isPuffs
          onCloseCallback={() => {
            setIsAveragePuffsModalOpen(false);
          }}
        />
      )}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
  max-width: 366px;
  min-height: 202px;
  border-radius: 25px;
  background-color: ${COLORS.white};
  display: flex;
  flex-direction: column;
  padding: 18px 25px 10px 27px;
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DatePeriod = styled.p`
  ${TYPOGRAPHY.body2}
  color: ${COLORS.black};
`;

const Bold = styled.span`
  ${TYPOGRAPHY.buttons}
`;

const Time = styled.p`
  ${TYPOGRAPHY.subline2}
  color: ${TRANSPARENCY_COLORS.darkBlueHalf};
  align-self: center;
`;

const Line = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Separator = styled.div`
  height: 40px;
  margin: auto 15px auto 25px;
  opacity: 0.2;
  border-left: 1.8px solid ${TRANSPARENCY_COLORS.darkBlueSeventy};
  border-radius: 5px;
  @media (max-width: ${SIZE.mobileM}) {
    margin: auto 10px;
  }
`;

const SpinnerWrap = styled.div`
  margin: auto;
`;

const StyledSpinnerIcon = styled(Icon)`
  margin: 0 auto;
  margin-bottom: 10px;
  width: 30px;
  height: 30px;

  animation: ${ROTATE_RIGHT} 2s linear infinite;

  > path {
    stroke: ${COLORS.darkBlue};
  }
`;

const Blur = styled.div`
  color: ${COLORS.darkBlue};
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 25px;
  backdrop-filter: blur(15px);
`;
