import { CompareVersion } from 'lib/helpers/compareVersions';

import {
  childLockSettingsResponse,
  getChildLockSettingsCommand,
  setChildLockSettingsCommand,
} from '../bluetooth/commands';
import { VERSION_WITH_CHILD_LOCK } from '../constants';
import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqDevice } from './plonq-device';
import { PlonqNordicConnectedService } from './plonq-nordic-connected-service';

export type ChildLockSettings = {
  time: number;
  cartridge: boolean;
};

/**
 * Класс отвечает за реализацию функции ChildLock.
 * Для работы необходим подключенный экземпляр PlonqDevice.
 *
 * Отвечает за получение и установку ChildLock Settings.
 * Отдельно передается таймер и маска настроек.
 *
 * Для интеграции c React компонентами нужно обернуть в хук.
 *
 * Используется в хуке {@link useDeviceChildLockSettings}
 */
export class PlonqChildLock extends PlonqNordicConnectedService {
  private static instance?: PlonqChildLock;

  constructor() {
    super();

    this.bleConnection?.onDisconnect(this.clearChildLockData.bind(this));
  }

  public static getInstance(): PlonqChildLock {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new PlonqChildLock();

    return this.instance;
  }

  private clearChildLockData = (): void => {
    PlonqChildLock.instance = undefined;
    this.isConnected = false;
    this.bleConnection = undefined;
  };

  public async getChildLockSettings(): Promise<ChildLockSettings> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    if (
      CompareVersion(
        PlonqDevice.getInstance().getFirmware(),
        VERSION_WITH_CHILD_LOCK,
      )
    ) {
      return {
        time: 0,
        cartridge: false,
      };
    }

    const childLockSettingsBinary = (await this.bleConnection.sendRequest(
      getChildLockSettingsCommand(),
    )) as DataView;

    const childLockSettings = childLockSettingsResponse(
      childLockSettingsBinary,
    );

    return {
      time: childLockSettings.time,
      cartridge: childLockSettings.cartridge.activate,
    };
  }

  /**
   * Функция актуальна начиная с прошивки 1.16.30.
   * На более старых прошивках функция работать не будет, тк
   * у команды нет ответа.
   */
  public async setChildLockSettings(
    settings: ChildLockSettings,
  ): Promise<ChildLockSettings> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    if (
      CompareVersion(
        PlonqDevice.getInstance().getFirmware(),
        VERSION_WITH_CHILD_LOCK,
      )
    ) {
      return {
        time: 0,
        cartridge: false,
      };
    }

    const childLockSettingsBinary = (await this.bleConnection.sendRequest(
      setChildLockSettingsCommand({
        time: settings.time,
        mask: {
          cartridge: {
            activate: settings.cartridge,
            deactivate: settings.cartridge,
          },
          application: {
            activate: true,
            deactivate: true,
          },
        },
      }),
    )) as DataView;

    const childLockSettings = childLockSettingsResponse(
      childLockSettingsBinary,
    );

    return {
      time: childLockSettings.time,
      cartridge: childLockSettings.cartridge.activate,
    };
  }
}
