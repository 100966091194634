import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { MILLISECONDS_IN_SECOND } from 'lib/constants';
import { useMemo } from 'react';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Spinner } from 'ui/Spinner';

type TimerProps = {
  initialSeconds: number;
  isStart: boolean;
  onEndTimer: () => void;
  isOnlyBigSeconds?: boolean;
};

export const Timer: FC<TimerProps> = ({
  initialSeconds,
  onEndTimer,
  isStart,
  isOnlyBigSeconds,
}) => {
  const minutes = useMemo(() => {
    return new Date(initialSeconds * MILLISECONDS_IN_SECOND).getMinutes();
  }, [initialSeconds]);

  const seconds = useMemo(() => {
    return new Date(initialSeconds * MILLISECONDS_IN_SECOND).getSeconds();
  }, [initialSeconds]);

  const [secondsTimer, setSecondsTimer] = useState(seconds);
  const [minutesTimer, setMinutesTimer] = useState(minutes);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    let min = 0;
    let sec = 0;

    if (isStart) {
      const finish =
        new Date().getTime() + initialSeconds * MILLISECONDS_IN_SECOND;

      const interval = setInterval(() => {
        const timer = finish - new Date().getTime();

        min = new Date(timer).getMinutes();
        sec = new Date(timer).getSeconds();

        intervalRef.current = interval;

        if (min === 0 && sec === 0) {
          clearInterval(intervalRef.current);
          onEndTimer();
        }

        setSecondsTimer(sec);
        setMinutesTimer(min);
      }, 1000);
    }

    return () => clearInterval(intervalRef.current as NodeJS.Timeout);
  }, [isStart]);

  const formatNumber = (num: number) => {
    return num.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
  };

  return isOnlyBigSeconds ? (
    <RootSeconds>{formatNumber(secondsTimer)}</RootSeconds>
  ) : (
    <Root>
      <Spinner />
      {formatNumber(minutesTimer)}:{formatNumber(secondsTimer)}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.body1}
`;

const RootSeconds = styled.div`
  color: ${COLORS.black};
  ${TYPOGRAPHY.big}
`;
