import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={151}
    height={286}
    fill="none"
    viewBox="0 0 151 286"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m130 128.766 10.638 8.535 9.362-10"
      stroke="#D2D8DD"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 129.365c2.776-32.831 23.477-51.064 58.361-51.064h12.976c37.54 0 58.663 21.14 58.663 59M21 157.836l-10.61-8.535-9.39 10"
      stroke="#D2D8DD"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M140 157.236c-2.776 32.832-23.477 51.065-58.361 51.065H68.663c-37.54 0-58.663-21.141-58.663-59"
      stroke="#D2D8DD"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102 263.344c-.095-.097-54 0-54 0v1.644c0 1.548 0 3.192.19 4.74.19 1.547.57 3.192 1.139 4.643.57 1.451 1.328 2.901 2.277 4.159a18.53 18.53 0 0 0 3.322 3.385c1.234.968 2.657 1.741 4.08 2.322 1.33.483 2.658.87 4.082 1.064h23.82c1.424-.194 2.753-.581 4.081-1.064 1.424-.581 2.847-1.354 4.081-2.322a18.53 18.53 0 0 0 3.322-3.385c.949-1.258 1.708-2.708 2.277-4.159.57-1.451.949-3.096 1.139-4.643.19-1.548.19-3.192.19-4.74v-1.644Z"
      fill="#fff"
      stroke="#D2D8DD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.755 52.226c.383.191.766.477 1.245.668.383.191.862.382 1.34.573.48.19.958.382 1.437.477.574.19 1.148.286 1.819.477h.191c.67.096 1.436.191 2.107.286h.478c.48 0 .958.096 1.436.096h17.809c.479 0 .957 0 1.532-.096h.479c.67-.095 1.34-.19 2.106-.286h.192c.574-.095 1.244-.286 1.819-.477.383-.096.861-.286 1.149-.382a3.543 3.543 0 0 0 1.053-.477c.287-.19.67-.286.957-.477.288-.191.575-.286.862-.477l.862-.573.861-.573c.288-.19.575-.477.766-.668.288-.286.575-.477.766-.763.287-.286.575-.573.766-.859.287-.286.479-.573.766-.954.192-.287.479-.668.67-.955.192-.286.383-.572.48-.859a2.9 2.9 0 0 0 .382-.763c.096-.191.192-.477.383-.668.096-.191.192-.382.287-.477.096-.191.192-.287.288-.478.095-.095.191-.286.191-.381.096-.096.192-.191.192-.287.095-.095.095-.19.191-.286l.192-.19.095-.096.096.095v219.407l-.096.095-.383.382c-.095.095-.191.286-.383.382-.383.382-.957.954-1.532 1.622-.095.096-.095.191-.191.191l-.575.573c-.191.191-.383.381-.574.668-.192.191-.383.477-.575.668-.191.191-.383.477-.67.668-.478.477-.957.859-1.532 1.336l-1.723 1.145c-.575.382-1.245.668-1.915.955-.67.286-1.34.477-2.01.668-.67.19-1.341.381-2.107.477-.67.095-1.436.191-2.106.286-.383 0-.67.095-1.053.095-.383 0-.767.096-1.054.096-2.872.191-5.744.191-8.521.191-2.872 0-5.745 0-8.521-.191-.383 0-.766-.096-1.053-.096-.383 0-.67-.095-1.054-.095-1.436-.191-2.776-.382-4.212-.763-1.34-.382-2.681-.955-3.926-1.623l-1.723-1.145c-.575-.382-1.054-.859-1.532-1.336l-.67-.668c-.192-.191-.383-.477-.575-.668-.191-.191-.383-.382-.574-.668l-.575-.573c-.096-.095-.096-.191-.191-.191-.67-.668-1.15-1.24-1.532-1.622l-.383-.382-.479-.477V42.396l.096-.095s.096.095.096.19c0 .096.095.096.191.191.096.096.096.191.191.287.096.095.096.19.192.286.096.096.191.191.287.382.096.095.192.286.287.477l.288.573c.095.19.191.381.383.668.095.286.287.477.383.763.191.286.287.573.478.86.192.285.383.572.67.953a6.7 6.7 0 0 0 .767.955c.287.286.478.572.766.859.287.286.574.477.765.763.288.191.575.477.766.668l.862.573c-.191.095.096.286.287.477Z"
      fill="#fff"
      stroke="#D2D8DD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M74.197 136.801h1.492c1.951 0 3.313.53 4.352 1.449.925 1.029 1.459 2.378 1.459 4.311v1.48c0 1.955-.545 3.399-1.45 4.302-1.04.924-2.404 1.458-4.361 1.458h-1.492c-1.855 0-3.316-.54-4.23-1.44-.93-1.03-1.467-2.382-1.467-4.32v-1.48c0-1.962.549-3.409 1.459-4.312.915-.908 2.267-1.448 4.238-1.448Z"
      stroke="#D2D8DD"
    />
    <path
      d="M74.369 138.301h1.172c1.509 0 2.551.408 3.344 1.107.704.786 1.115 1.818 1.115 3.311v1.163c0 1.516-.422 2.619-1.105 3.303-.794.704-1.839 1.116-3.354 1.116h-1.172c-1.437 0-2.553-.418-3.245-1.098-.71-.787-1.124-1.821-1.124-3.321v-1.163c0-1.522.426-2.628 1.114-3.311.695-.689 1.726-1.107 3.255-1.107Z"
      stroke="#D2D8DD"
    />
    <path
      d="M68.179 1.3c-1.516 0-3.126 0-4.642.19-1.516.19-3.032.475-4.547 1.045-1.422.569-2.843 1.328-4.074 2.277-1.232.95-2.274 2.088-3.221 3.322-.853 1.234-1.61 2.657-2.084 4.176a24.464 24.464 0 0 0-1.043 4.555c-.19 1.518-.19 3.132-.19 4.65-.094 7.023-.283 14.046-.378 21.069v.095l.095.094s.095.095.095.19.094.095.189.19.095.19.19.285c.094.095.094.19.189.285.095.095.19.19.284.38.095.094.19.284.284.474l.285.57c.094.189.189.379.379.663.094.285.284.475.378.76.19.284.285.57.474.854.19.285.38.57.663.949a6.7 6.7 0 0 0 .758.949c.284.285.474.57.758.854.284.285.569.474.758.76.284.189.568.474.758.664l.852.569.853.57c.38.19.758.474 1.232.664.379.19.852.38 1.326.57.474.189.947.379 1.421.474.569.19 1.137.284 1.8.474h.19c.663.095 1.42.19 2.084.285h.473c.474 0 .948.095 1.421.095h17.622c.473 0 .947 0 1.515-.095h.474c.663-.095 1.326-.19 2.084-.285h.19c.568-.095 1.231-.285 1.8-.474.379-.095.852-.285 1.136-.38a3.498 3.498 0 0 0 1.043-.474c.284-.19.663-.285.947-.475.284-.19.568-.285.853-.474l.852-.57.853-.57c.284-.19.568-.474.758-.664.284-.284.568-.474.758-.759.284-.285.568-.57.758-.854.284-.285.473-.57.758-.95.189-.284.473-.663.663-.948.19-.285.379-.57.473-.854a2.81 2.81 0 0 0 .379-.76c.095-.19.19-.474.379-.664.095-.19.19-.38.284-.474.095-.19.19-.285.285-.475.094-.095.189-.284.189-.38.095-.094.19-.19.19-.284.094-.095.094-.19.189-.285l.19-.19c0-.095.094-.095.094-.095l.095-.095c-.095-7.022-.284-14.045-.379-21.163 0-1.518-.095-3.132-.189-4.65v-.19c-.095-.38-.095-.854-.19-1.234a19.186 19.186 0 0 0-.853-3.131c-.473-1.424-1.231-2.848-2.084-4.176-.852-1.234-1.99-2.373-3.22-3.322A15.534 15.534 0 0 0 91.01 2.63c-1.42-.569-2.936-.854-4.547-1.044-1.516-.19-3.126-.19-4.642-.19-3.79-.094-10.137-.094-13.642-.094Z"
      fill="#fff"
      stroke="#D2D8DD"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
