/**
 * Словарь с путями для неавторизированных пользователей.
 */
const GUEST_PATHS = {
  LOGIN: '/login',
  REGISTRATION: '/registration',
  RESTORE_PASSWORD: '/restore-password',
  AGE_CHECK: '/age-check',
  AGE_LIMIT: '/age-limit',
  INTRO: '/intro',
  BROWSER_CHECK: '/browser-check',
};

/**
 * Словарь с путями для авторизированных пользователей
 */
const AUTH_PATHS = {
  ROOT: '/',
  DEVICE: '/device',
  DEVICE_ID: (id: string) => `/device/${id}`,
  UPDATE: '/update',
  UPDATED_DEVICE_ID: (id: string) => `/update/${id}`,
  UPDATE_SUCCESS: '/update/update-success',
};

/**
 * Экспорт слитых путей.
 */
export const ROUTES = {
  ...GUEST_PATHS,
  ...AUTH_PATHS,
};
