import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface StepItemProps {
  isChecked: boolean;
  stepNumber: number;
  text: string | ReactNode;
  className?: string;
}

export const StepItem: FC<StepItemProps> = ({
  isChecked,
  text,
  className,
  stepNumber,
}) => {
  return (
    <Root className={className}>
      <Check $isChecked={isChecked}>
        <StepNumber>{stepNumber}</StepNumber>
      </Check>
      <Text>{text}</Text>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const Check = styled.div<{ $isChecked?: boolean }>`
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 5px solid ${COLORS.gray2};
  background: ${COLORS.gray3};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 19px;

  ${({ $isChecked }) =>
    $isChecked &&
    css`
      background: ${COLORS.darkBlue};
    `}
`;

const StepNumber = styled.div`
  ${TYPOGRAPHY.title2}
  color: ${COLORS.white};
`;

const Text = styled.p`
  margin-top: 6px;
  ${TYPOGRAPHY.body3}
  color: ${COLORS.black};
  max-width: 233px;
  letter-spacing: -0.2px;
`;
