import { ScrollToTop } from 'components';
import { use100vhHeight } from 'lib/hooks';
import {
  AgeCheck,
  DownloadBrowser,
  Home,
  Intro,
  Login,
  NotFound,
  Registration,
  RestorePassword,
  UpdateDevice,
} from 'pages';
import { AgeLimit } from 'pages/auth/AgeLimit';
import { DeviceDetail } from 'pages/DeviceDetail';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { REGISTATION_FEATURE_TOGGLE } from '../lib/constants';
import { FeatureToggleOutlet } from './FeatureToggleOutlet';
import { PrivateOutlet } from './PrivateOutlet';
import { PublicOutlet } from './PublicOutlet';
import { ROUTES } from './route-paths';

/**
 * Корневой роутер приложения.
 * Разделяет пользовательский флоу на приватный и публичный.
 *
 * Если отсутствует токен авторизации, Пользователю будут доступны только страницы:
 * логина, регистрации, проверки возраста, страница для несовершеннолетних, восстановления пароля, начальный экран.
 *
 * Если токен авторизации в наличии, Пользователю будут доступны только страницы:
 * выбора устройства, устройства, обновления прошивки.
 */
export const RootRoutes = () => {
  use100vhHeight();

  useEffect(() => {
    const handleContextmenu = (event: MouseEvent) => {
      event.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
    return () => {
      document.removeEventListener('contextmenu', handleContextmenu);
    };
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route path={ROUTES.ROOT} element={<PublicOutlet />}>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.AGE_CHECK} element={<AgeCheck />} />
          <Route path={ROUTES.AGE_LIMIT} element={<AgeLimit />} />
          <Route path={ROUTES.RESTORE_PASSWORD} element={<RestorePassword />} />
          <Route path={ROUTES.INTRO} element={<Intro />} />
        </Route>
        <Route
          path={ROUTES.REGISTRATION}
          element={
            <FeatureToggleOutlet featureId={REGISTATION_FEATURE_TOGGLE} />
          }
        >
          <Route path="" element={<Registration />} />
        </Route>
        <Route path={ROUTES.BROWSER_CHECK} element={<DownloadBrowser />} />
        <Route path={ROUTES.ROOT} element={<PrivateOutlet />}>
          <Route index element={<Home />} />

          <Route path={ROUTES.DEVICE}>
            <Route index element={<NotFound />} />
            <Route path=":deviceId" element={<DeviceDetail />} />
          </Route>

          <Route path={ROUTES.UPDATE}>
            <Route path=":deviceId" element={<UpdateDevice />} />
            <Route index element={<NotFound />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
