import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import styled from 'styled-components';

type SwipeableBottomDrawerProps = {
  isOpen: boolean;
  onCloseCallback: () => void;
  children?: React.ReactNode;
};

export const SwipeableBottomDrawer = ({
  isOpen,
  onCloseCallback,
  children,
}: SwipeableBottomDrawerProps) => {
  return (
    <StyledDrawer
      swipeAreaWidth={isOpen ? 80 : 0}
      anchor="bottom"
      open={isOpen}
      onOpen={() => {}}
      onClose={onCloseCallback}
    >
      {children}
    </StyledDrawer>
  );
};

const StyledDrawer = styled(SwipeableDrawer)`
  && {
    .MuiPaper-root {
      width: 100%;
      max-width: 414px;
      margin: 0 auto;
      border-radius: 40px 40px 0px 0px;
      background: white;
    }
  }
`;
