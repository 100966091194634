import { sliceDataView } from 'lib/helpers';

import { mergeHexCodes, toHexArray, toHexCode } from '../plonq/utils';

export type ChildLockSettingsMaskParams = {
  cartridge: {
    activate: boolean;
    deactivate: boolean;
  };
  application: {
    activate: boolean;
    deactivate: boolean;
  };
};

export type SetChildLockSettingsParam = {
  time: number;
  mask: ChildLockSettingsMaskParams;
};

export const getPuffArrayCommand = (from: number, to: number) => {
  if (from > to) {
    throw new Error(
      'from index must be lesser than to index while getting puff array',
    );
  }

  const [fromMSB, fromLSB] = toHexArray(from, 2);
  const [toMSB, toLSB] = toHexArray(to, 2);

  return new Uint8Array([
    0xfa,
    Number.parseInt(fromMSB),
    Number.parseInt(fromLSB),
    Number.parseInt(toMSB),
    Number.parseInt(toLSB),
    0xfa,
  ]);
};

export const getOverpuffSettingsCommand = () => {
  return new Uint8Array([0x80, 0x99]);
};

export const getPuffCommand = (index: number) => {
  const [MSB, LSB] = toHexArray(index, 2);

  return new Uint8Array([
    0xfd,
    Number.parseInt(MSB),
    Number.parseInt(LSB),
    0xfd,
  ]);
};

export const clearPuffArrayCommand = () => {
  return new Uint8Array([0xda]);
};

export const resetCommand = (force = false) => {
  return force ? new Uint8Array([0x33]) : new Uint8Array([0x34]);
};

export const rebootCommand = (force = false) => {
  return force ? new Uint8Array([0x44]) : new Uint8Array([0x45]);
};

export const bootloaderCommand = (force = false) => {
  return force ? new Uint8Array([0x56]) : new Uint8Array([0x55]);
};

export const locationCommand = () => {
  return new Uint8Array([0xaa]);
};

export const stopLocalisationCommand = () => {
  return new Uint8Array([0xac]);
};

export const setOverpuffSettingsCommand = (
  isEnabled: boolean,
  puffCount: number,
  timeout = 10000,
) => {
  const [pcMSB, pcLSB] = toHexArray(puffCount, 2);
  const [tMSB, t1, t2, tLSB] = toHexArray(timeout, 4);
  const enabledHex = isEnabled ? 0x01 : 0x00;

  return new Uint8Array([
    0x99,
    enabledHex,
    Number.parseInt(tMSB),
    Number.parseInt(t1),
    Number.parseInt(t2),
    Number.parseInt(tLSB),
    Number.parseInt(pcMSB),
    Number.parseInt(pcLSB),
  ]);
};

export const overpuffSettingsResponse = (dataView: DataView) => {
  const bytes = new Array(dataView.byteLength).fill(undefined);

  for (let i = 0; i < dataView.byteLength; ++i) {
    bytes[i] = toHexCode(dataView.getUint8(i));
  }

  const isEnabled = Number.parseInt(bytes[0]) == 0x01;

  const timeout = Number.parseInt(mergeHexCodes(bytes.slice(1, 5), false));

  const puffCount = Number.parseInt(mergeHexCodes(bytes.slice(5, 7), false));

  return {
    isEnabled,
    timeout,
    puffCount,
  };
};

export const puffResponse = (dataView: DataView) => {
  // TODO
  const temp = 0.02792867573;

  const bytes = new Array(dataView.byteLength).fill(undefined);

  for (let i = 0; i < dataView.byteLength; ++i) {
    bytes[i] = toHexCode(dataView.getUint8(i));
  }

  const timestamp =
    Number.parseInt(mergeHexCodes(bytes.slice(0, 4), true)) * 1000;
  const cartridgeId = Number.parseInt(bytes[4]);
  const puffTime = Number.parseInt(mergeHexCodes(bytes.slice(5), true));

  return {
    timestamp,
    cartridgeId,
    puffTime,
    nicotineLevel: (puffTime / 1000) * temp,
  };
};

export const puffPackageResponse = (dataView: DataView) => {
  const counterDataView = puffCounterResponse(sliceDataView(dataView, 4));
  const puffsBuffer = dataView.buffer.slice(4);
  const puffsData = [];

  for (let i = 0; i < puffsBuffer.byteLength; i += 7) {
    puffsData.push(puffResponse(new DataView(puffsBuffer.slice(i, i + 7))));
  }

  return puffsData.map((puffData, index) => ({
    ...puffData,
    number: index + counterDataView,
  }));
};

export const puffCounterResponse = (dataView: DataView) => {
  const bytes = new Array(dataView.byteLength).fill(undefined);

  for (let i = 0; i < dataView.byteLength; ++i) {
    bytes[i] = toHexCode(dataView.getUint8(i));
  }

  return Number.parseInt(mergeHexCodes(bytes, true));
};

export const childLockStatusResponse = (status: number): boolean => {
  const statusByteString = Number(status).toString(2).padStart(8, '0');

  return statusByteString[7] === '1';
};

export const getChildLockSettingsCommand = () => {
  return new Uint8Array([0xcf, 0xcf]);
};

export const setChildLockSettingsCommand = (
  settings: SetChildLockSettingsParam,
) => {
  const [timeMSB, timeLSB] = toHexArray(settings.time, 2);
  const maskByte = convertParamsToByteChildLockSettings(settings.mask);

  return new Uint8Array([
    0xcd,
    maskByte,
    Number.parseInt(timeLSB),
    Number.parseInt(timeMSB),
    0xcd,
  ]);
};

export const childLockSettingsResponse = (dataView: DataView) => {
  const bytes = new Array(dataView.byteLength).fill(undefined);

  for (let i = 0; i < dataView.byteLength; ++i) {
    bytes[i] = toHexCode(dataView.getUint8(i));
  }

  const settings = convertByteToParamsChildLockSettings(
    Number.parseInt(bytes[1]),
  );
  const time = Number.parseInt(mergeHexCodes(bytes.slice(2, 4), true));

  return {
    ...settings,
    time,
  };
};

export const convertParamsToByteChildLockSettings = (
  params: ChildLockSettingsMaskParams,
): number => {
  const maskByte = [0, 0, 0, 0, 0, 0, 0, 0];

  if (params.cartridge.activate) {
    maskByte[6] = 1;
  }

  if (params.cartridge.deactivate) {
    maskByte[7] = 1;
  }

  if (params.application.activate) {
    maskByte[4] = 1;
  }

  if (params.application.deactivate) {
    maskByte[5] = 1;
  }

  return Number.parseInt(maskByte.join(''), 2);
};

export const convertByteToParamsChildLockSettings = (
  byte: number,
): ChildLockSettingsMaskParams => {
  const params = {
    cartridge: {
      activate: false,
      deactivate: false,
    },
    application: {
      activate: false,
      deactivate: false,
    },
  };

  const mask = byte
    .toString(2)
    .padStart(8, '0')
    .split('')
    .map((char: string) => Number.parseInt(char));

  params.cartridge.activate = !!mask[6];

  params.cartridge.deactivate = !!mask[7];

  params.application.activate = !!mask[4];

  params.application.deactivate = !!mask[5];

  return params;
};
