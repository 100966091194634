import Cookies from 'js-cookie';
import { AUTH_REFRESH_TOKEN_KEY, AUTH_TOKEN_KEY } from 'lib/constants';

const ONE_DAY = 1;

export const setCookie = (key: string, value: string) => {
  Cookies.set(key, value, {
    expires: ONE_DAY,
    path: '/',
  });
};

export const getCookie = (key: string) => {
  return Cookies.get(key);
};

export const removeCookie = (key: string) => {
  Cookies.remove(key, {
    expires: ONE_DAY,
  });
};

export const setTokenToCookies = (token: string) => {
  return Cookies.set(AUTH_TOKEN_KEY, token, {
    secure: true,
    sameSite: 'strict',
    expires: ONE_DAY,
    path: '/',
  });
};

export const setRefreshTokenToCookies = (token: string) => {
  return Cookies.set(AUTH_REFRESH_TOKEN_KEY, token, {
    secure: true,
    sameSite: 'strict',
    expires: ONE_DAY * 30,
    path: '/',
  });
};

export const getTokenFromCookies = () => {
  return Cookies.get(AUTH_TOKEN_KEY);
};

export const getRefreshTokenFromCookies = () => {
  return Cookies.get(AUTH_REFRESH_TOKEN_KEY);
};

export const removeTokenFromCookies = () => {
  return Cookies.remove(AUTH_TOKEN_KEY);
};

export const removeRefreshTokenFromCookies = () => {
  return Cookies.remove(AUTH_REFRESH_TOKEN_KEY);
};
