import { useStepperConsumer } from 'components/Stepper';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import styled from 'styled-components';

export const StepsWrapper = () => {
  const { activeComponent } = useStepperConsumer();

  return (
    <MainLayout>
      <ContentWrapper>{activeComponent}</ContentWrapper>
    </MainLayout>
  );
};

const ContentWrapper = styled.div`
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
