import { bootloaderCommand, resetCommand } from '../bluetooth/commands';
import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqNordicConnectedService } from './plonq-nordic-connected-service';

/**
 * Класс отвечает за реализацию функции bootloader.
 *
 * Для работы необходим подключенный экземпляр PlonqDevice.
 *
 * Для интеграции c React компонентами нужно обернуть в хук.
 *
 * Используется в хуке {@link useDeviceUpdate}
 */
export class PlonqBootLoader extends PlonqNordicConnectedService {
  private static instance?: PlonqBootLoader;

  constructor() {
    super();

    this.bleConnection?.onDisconnect(this.clearBootLoaderData.bind(this));
  }

  public static getInstance(): PlonqBootLoader {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new PlonqBootLoader();

    return this.instance;
  }

  private clearBootLoaderData = (): void => {
    PlonqBootLoader.instance = undefined;
    this.isConnected = false;
    this.bleConnection = undefined;
  };

  public async setBootLoaderMode(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    this.bleConnection.sendRequest(bootloaderCommand(), true);
  }

  public async reset(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    this.bleConnection.sendRequest(resetCommand(), true);
  }
}
