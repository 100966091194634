import { BluetoothConnection } from 'lib/services/bluetooth/bluetooth-connection';
import {
  BATTERY_INFORMATION_GATT_SERVICE_UUID,
  DEVICE_INFORMATION_GATT_SERVICE_UUID,
  NORDIC_GATT_SERVICE_UUID,
  PLONQ_GATT_SERVICE_UUID,
} from 'lib/services/constants';
import { useCallback } from 'react';

/**
 * Хук инкапсулирует логику подключения к устройству.
 * Использует экземпляр {@link BluetoothConnection} для доступа к bluetoothApi.
 *
 * Работает в паре с хуком {@link useDeviceState}.
 *
 * @example
 * const [deviceState, setDeviceState] = useDeviceState();
 * const connectDevice = useDeviceConnect();
 *
 * const plonqDevice = await connectDevice('PLONQ_SAMPLE');
 * setDeviceState(plonqDevice);
 *
 * @returns функцию, которая принимает имя подключаемого устройства и
 * возвращает экземпляр класса {@link PlonqDevice}.
 */
const useDeviceConnect = () => {
  const connectDevice = useCallback(async (deviceName: string) => {
    const bluetoothConnection = new BluetoothConnection();
    const device = await bluetoothConnection.requestDevice(deviceName, [
      DEVICE_INFORMATION_GATT_SERVICE_UUID,
      BATTERY_INFORMATION_GATT_SERVICE_UUID,
      PLONQ_GATT_SERVICE_UUID,
      NORDIC_GATT_SERVICE_UUID,
    ]);

    return device;
  }, []);

  return connectDevice;
};

export default useDeviceConnect;
