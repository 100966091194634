import { COLORS, TRANSPARENCY_COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import styled from 'styled-components';

export interface CodeInputProps {
  error?: string;
  className?: string;
  isDisabled?: boolean;
  onComplete: (value: string) => void;
}
export const CodeInput = ({
  error,
  className,
  isDisabled,
  onComplete,
}: CodeInputProps) => {
  const [code, setCode] = useState<string[]>();
  const hasError = Boolean(error);

  const handleInputChange = (value: string) => {
    setCode(value.split(''));
  };

  return (
    <Root className={className}>
      {hasError && <ErrorText>{error}</ErrorText>}

      <StyledReactCodeInput
        type="number"
        values={code}
        fields={6}
        disabled={isDisabled}
        $hasError={hasError}
        onComplete={onComplete}
        onChange={handleInputChange}
      />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const StyledReactCodeInput = styled(ReactCodeInput)<{ $hasError: boolean }>`
  width: 100% !important;
  & div > input {
    margin-right: 5px;
    background-color: ${({ $hasError }) =>
      $hasError
        ? TRANSPARENCY_COLORS.redThirty
        : TRANSPARENCY_COLORS.lightBlue};
    border-radius: 10px;
    border: none;
    outline: none;
    width: 38px !important;
    height: 46px !important;
    ${TYPOGRAPHY.code}
    color: ${({ $hasError }) => ($hasError ? COLORS.red : COLORS.black)};
    &:focus {
      border: none;
      outline: none;
    }
  }

  & div > input:last-child {
    border: none;
    outline: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  & div > input:first-child {
    border: none;
    outline: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  @media (min-width: 400px) {
    & div > input {
      margin-right: 8px;
      width: 42px !important;
      height: 50px !important;
    }
  }
`;

const ErrorText = styled.p`
  ${TYPOGRAPHY.subline1}
  position: absolute;
  color: ${COLORS.red};
  bottom: calc(100% + 4px);

  /* stylelint-disable value-no-vendor-prefix */
  /* stylelint-disable property-no-vendor-prefix */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
