import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui/Icon';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  isDisabled?: boolean;
  className?: string;
  text?: ReactNode | string;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ isDisabled = false, className, text, id, ...props }, ref) => {
    return (
      <Root className={className}>
        <Label>
          <input
            className="visually-hidden"
            ref={ref}
            id={id}
            name={id}
            disabled={isDisabled}
            type="checkbox"
            tabIndex={0}
            {...props}
          />
          <CheckboxIcon>
            <StyledCheckLineIcon type="checkline" />
          </CheckboxIcon>
          {text && <Title>{text}</Title>}
        </Label>
      </Root>
    );
  },
);

Checkbox.displayName = 'Checkbox';

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledCheckLineIcon = styled(Icon)`
  display: none;
`;

const CheckboxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid ${COLORS.darkBlue};
  border-radius: 6.5px;
  background: #ffffff;
  flex-shrink: 0;

  input:checked + & {
    ${StyledCheckLineIcon} {
      display: flex;
      padding-right: 0.5px;
    }
  }
  input:disabled + & {
    border-color: ${COLORS.darkViolet};
  }

  input:enabled + & {
    &:hover {
      border-color: ${COLORS.lightBlue};
      background: #ffffff;
    }
  }

  input:checked:disabled + & {
    background: ${COLORS.grey};
    & svg path {
      stroke: ${COLORS.darkViolet};
    }
  }
`;

const Title = styled.p`
  ${TYPOGRAPHY.subline1}
  word-break: break-word;
  color: ${COLORS.darkBlue};
  margin-left: 13px;
  text-align: left;
  user-select: none;
`;
