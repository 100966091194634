import {
  locationCommand,
  stopLocalisationCommand,
} from '../bluetooth/commands';
import { BLEConnectionNotInstalled } from './exception/ble-connection-not-installed';
import { PlonqNordicConnectedService } from './plonq-nordic-connected-service';

/**
 * Класс отвечающий за реализацию функции поиска устройства.
 * Для работы необходим подключенный экземпляр PlonqDevice.
 *
 * Для интеграции c React компонентами нужно обернуть в хук.
 *
 * Используется в хуке {@link useDeviceLocationSignal}
 */
export class PlonqLocation extends PlonqNordicConnectedService {
  private static instance?: PlonqLocation;

  constructor() {
    super();

    this.bleConnection?.onDisconnect(this.clearLocationData.bind(this));
  }

  public static getInstance(): PlonqLocation {
    if (this.instance) {
      return this.instance;
    }

    this.instance = new PlonqLocation();

    return this.instance;
  }

  private clearLocationData = (): void => {
    PlonqLocation.instance = undefined;
    this.isConnected = false;
    this.bleConnection = undefined;
  };

  public async sendLocationSignal(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    await this.bleConnection.sendRequest(locationCommand(), true);
  }

  public async sendStopLocationSignal(): Promise<void> {
    if (!this.bleConnection) {
      throw new BLEConnectionNotInstalled();
    }

    await this.bleConnection.sendRequest(stopLocalisationCommand(), true);
  }
}
