import { COLORS } from 'assets/styles/colors';
import { SIZE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { ReactComponent as Arrow } from 'assets/svg/arrow-date-back.svg';
import { FC } from 'react';
import { useGetFirstAvailableFirmwareQuery } from 'store/api/firmware';
import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';
import { Spinner } from 'ui/Spinner';

interface Props {
  deviceId: string;
  currentVersion: string;
  onSubmit: () => void;
  onClose: () => void;
  hideCloseButton?: boolean;
}

export const FirmwareUpdateModal: FC<Props> = ({
  currentVersion,
  deviceId,
  onSubmit,
  onClose,
  hideCloseButton = false,
}) => {
  const { data: firmware, isLoading: isFirmwareLoading } =
    useGetFirstAvailableFirmwareQuery(
      { deviceId: deviceId as string },
      { skip: !deviceId },
    );

  if (isFirmwareLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
        <div>Проверяем доступные обновления...</div>
      </SpinnerWrapper>
    );
  }

  return (
    <Modal isOpen closeCallback={onClose}>
      {!firmware ? (
        <>
          <Content>
            <ModalText>
              {'У вас уже установлена\n актуальная версия ПО'}
            </ModalText>
            <StyledButton variant="primary" onClick={onClose}>
              Отлично
            </StyledButton>
          </Content>
        </>
      ) : (
        <>
          <Content>
            <Title>Доступно обновление</Title>
            <VersionsWrapper>
              <SubTitle>Текущая версия</SubTitle>
              <Version>{currentVersion}</Version>
              <ArrowDown />
              <SubTitle $isDark>Новая версия</SubTitle>
              <Version $isDark>{firmware.firmwareVersion}</Version>
            </VersionsWrapper>
            <Button variant="primary" onClick={onSubmit}>
              Обновить ПО
            </Button>
            {!hideCloseButton && (
              <CustomButton onClick={onClose}>Обновить позже</CustomButton>
            )}
          </Content>
        </>
      )}
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 366px;
  border-radius: 25px;
  background-color: white;
  padding: 36px 36px 9px;
`;

const ModalText = styled.p`
  text-align: center;
  white-space: pre-wrap;
  margin: 4px 55px 36px 55px;

  @media (max-width: ${SIZE.mobileM}) {
    margin: 4px 30px 36px 30px;
  }
`;

const Title = styled.p`
  text-align: center;
  ${TYPOGRAPHY.title2}
  color: ${COLORS.black};
  margin: 0 18px;
  @media (max-width: ${SIZE.mobileM}) {
    margin: 0 0;
    font-size: 22px;
  }
`;

const VersionsWrapper = styled.div`
  margin: 25px 0 40px;
`;

const SubTitle = styled.p<{ $isDark?: boolean }>`
  text-align: center;
  ${TYPOGRAPHY.subtitle1}
  font-weight: 300;
  color: ${({ $isDark }) => ($isDark ? COLORS.black : COLORS.darkBlue)};
  margin-bottom: 2px;
`;

const Version = styled.p<{ $isDark?: boolean }>`
  text-align: center;
  ${TYPOGRAPHY.subtitle3}
  font-weight: 300;
  color: ${COLORS.darkBlue};
  color: ${({ $isDark }) => ($isDark ? COLORS.black : COLORS.darkBlue)};
`;

const ArrowDown = styled(Arrow)`
  margin: 16px auto;
  transform: rotate(270deg);
  path {
    fill: ${COLORS.black};
  }
`;

const CustomButton = styled.button`
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.body3}
  margin-top: 16px;
  padding-bottom: 15px;
  height: 35px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: var(--100vh-height);
`;

const StyledButton = styled(Button)`
  margin-bottom: 32px;
`;
