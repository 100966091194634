import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { baseApi } from './api/baseApi';
import { authReducer } from './features/auth';
import { statisticsSlice } from './features/statistics';
import { authMiddleware } from './middlewares';

export const appReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth: authReducer,
  statistics: statisticsSlice.reducer,
});

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([authMiddleware, baseApi.middleware]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
