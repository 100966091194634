import { useLayoutEffect } from 'react';

export const useLockBodyScroll = (isBlocked: boolean) => {
  useLayoutEffect(() => {
    isBlocked
      ? document.body.classList.add('no-scroll')
      : document.body.classList.remove('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isBlocked]);
};
