import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

import { useGetFeatureTogglesQuery } from '../../store/api/feature-toogle';
import { Spinner } from '../../ui';

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Adjust this value to change the centering position */
`;

const FeatureToggleContext = createContext<{
  featureToggles: { alias: string; enabled: boolean }[];
  isEnabledFeature: (featureAlias: string) => boolean;
}>({ featureToggles: [], isEnabledFeature: () => false });

const FeatureToggleProvider = ({ children }: { children: ReactNode }) => {
  const [featureToggles, setFeatureToggles] = useState<
    { alias: string; enabled: boolean }[]
  >([]);
  const { data, isLoading } = useGetFeatureTogglesQuery();

  useEffect(() => {
    if (data) {
      setFeatureToggles(data);
    }
  }, [data]);

  const contextValue = useMemo(() => {
    const isEnabledFeature = (featureAlias: string) => {
      const featureToggle = featureToggles.find(
        (feature) => feature.alias === featureAlias,
      );
      return !!featureToggle?.enabled;
    };

    return { featureToggles, isEnabledFeature };
  }, [featureToggles]);

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <FeatureToggleContext.Provider value={contextValue}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

const useFeatureToggles = () => {
  return useContext(FeatureToggleContext);
};

export { useFeatureToggles, FeatureToggleProvider };
