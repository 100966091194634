import { FC, SVGProps } from 'react';

interface SearchDeviceIconProps extends SVGProps<SVGSVGElement> {
  isSearchActive?: boolean;
}

export const SearchDeviceIcon: FC<SearchDeviceIconProps> = ({
  isSearchActive,
  ...props
}) => (
  <svg
    width={112}
    height={232}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      opacity={0.7}
      stroke={isSearchActive ? '#323232' : 'none'}
      strokeWidth={0.8}
      strokeLinecap="round"
    >
      <path d="M102.776 95.183c6.632 6.446 15.915 23.717 0 41.232" />
      <path d="M96.775 101.185c4.697 4.587 11.272 16.876 0 29.34m-5.779-23.339c2.684 2.676 6.441 9.845 0 17.115M10.03 95.183c-6.632 6.446-15.915 23.717 0 41.232" />
      <path d="M16.03 101.185c-4.696 4.587-11.271 16.876 0 29.34m5.78-23.339c-2.684 2.676-6.441 9.845 0 17.115" />
    </g>
    <g
      opacity={0.7}
      stroke="#323232"
      strokeWidth={0.8}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M54.493 3.049s-3.806.508-5.373.892c-1.567.383-4.022 1.512-5.165 2.46-1.142.95-3.662 3.51-4.387 5.878-.726 2.368-1.194 4.876-1.204 7.27-.018 2.396-.12 191.711-.127 192.661-.006.95.07 2.46.199 3.503.128 1.043.75 3.674 1.228 4.501.48.827 1.661 2.947 2.716 3.811 1.047.865 1.742 1.465 3.162 2.187 1.42.721 2.64.987 3.487 1.141.846.155 8.545.922 9.744 1.046 1.2.123 3.83.445 5.327.421 1.498-.024 3.016-.299 3.809-.55.794-.245 2.39-.948 3.77-1.9 1.38-.951 2.82-2.44 3.895-4.261a13.086 13.086 0 0 0 1.743-5.419c.136-1.62.19-3.167.175-3.949-.007-.777-.05-173.939-.068-176.708-.018-2.77-.16-8.728-.242-11.476-.082-2.747-.15-8.626-.315-10.448-.166-1.822-.323-3.405-.76-4.574-.438-1.169-1.52-3.487-3.335-5.019-1.816-1.531-4.129-2.346-5.566-2.626-1.43-.287-3.921-.165-6.166.126-2.236.296-6.547 1.033-6.547 1.033Z" />
      <path d="M77.41 35.801c-.036-.222-.203-1.047-.986-1.547-.624-.395-1.304-.364-1.776-.332-1.321.084-2.374.65-2.86 1.076-1.666 1.47-2.415 2.334-3.694 3.644-1.002 1.02-4.228 3.732-5.464 4.473-1.237.74-2.745 1.591-4.928 1.901-2.184.304-10.265 1.139-12.128 1.305-1.863.166-3.484-.09-4.367-.48-1.356-.6-2.046-1.29-2.222-1.508a4.323 4.323 0 0 1-.637-.999m39.154 169.199s-.001-.406-.415-.622c-.407-.21-.612-.335-1.44-.231-.83.112-2.041.643-2.692.98-.651.337-2.902 1.584-4.135 2.51-1.234.933-3.003 2.394-3.773 2.973-.769.578-2.835 1.651-4.619 1.869 0 0-2.44.343-4.35.24-1.911-.103-8.66-.873-9.87-1.037-1.211-.163-2.757-.642-3.795-1.027-1.04-.384-2.163-.958-2.87-1.762-.708-.805-1.222-1.916-1.222-1.916M75.95 72.662c-.028-.588-.367-1.038-.757-1.005-.39.034-.684.538-.656 1.127.028.588.367 1.038.757 1.004.39-.033.684-.537.656-1.126Zm-20.733 41.202.029 4.529c.011 1.532-1.343 2.831-2.988 2.867l-2.067.048c-1.592.043-2.86-1.111-2.86-2.596l.002-4.526c.004-1.521 1.338-2.803 2.974-2.852l2.036-.057c1.587-.029 2.86 1.11 2.874 2.587Z" />
      <path d="m54.108 114.471.041 3.442c.014 1.162-.956 2.14-2.148 2.168l-1.49.028c-1.15.023-2.067-.858-2.077-1.983l-.013-3.439c-.006-1.157.958-2.121 2.135-2.154l1.469-.037c1.15-.023 2.073.851 2.083 1.975Z" />
    </g>
  </svg>
);
