import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { InputHTMLAttributes, forwardRef, useId } from 'react';
import styled from 'styled-components';

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
}

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
  ({ className, label, ...props }, ref) => {
    const ownId = useId();
    const id = props.id || ownId;

    return (
      <Root className={className} htmlFor={id}>
        <Track ref={ref} type="checkbox" role="switch" id={id} {...props} />
        {label && <span>{label}</span>}
      </Root>
    );
  },
);

Switch.displayName = 'Switch';

const Root = styled.label`
  cursor: pointer;

  display: flex;
  align-items: center;

  & > span {
    ${TYPOGRAPHY.body1}
    margin-left: 15px;
    color: ${COLORS.black};
  }
`;

const Track = styled.input`
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: content-box;
  width: 100%;
  padding: 1px;
  max-width: 50px;
  min-height: 26px;
  border-radius: 11px;

  user-select: none;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
  appearance: none;

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 26px;
    min-height: 26px;
    border-radius: 10px;
    background-color: ${COLORS.white};

    font-family: 'Inter', 'Arial', sans-serif;
    letter-spacing: -0.1px;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 24px;
  }

  &:not(:checked) {
    ::before {
      content: 'OFF';
      color: ${COLORS.grey5};
    }
    background-color: ${COLORS.lightViolet};
  }

  &:checked {
    ::before {
      content: 'ON';
      transform: translateX(calc(50px - 100%));
      color: ${COLORS.black};
    }
    background-color: ${COLORS.black};
  }
`;
