import { yupResolver } from '@hookform/resolvers/yup';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import dayjs from 'dayjs';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { ERROR_TEXTS, REGEX } from 'lib/constants';
import { checkIsEighteenYearsOld } from 'lib/helpers';
import { StorageService } from 'lib/services';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import styled from 'styled-components';
import { StorageKeys } from 'types';
import { Button, DatePickerForForm, Logo } from 'ui';
import * as yup from 'yup';

export type AgeCheckFormData = {
  age: string;
};

const schema = yup
  .object({
    age: yup
      .string()
      .matches(REGEX.date, { message: ERROR_TEXTS.invalidDate })
      .required(),
  })
  .required();

export const AgeCheck = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<AgeCheckFormData>({
    defaultValues: {
      age: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const dateField = watch('age');
  const isDateNotEmpty = dateField !== '__.__.____' && dateField !== '';

  const onSubmit = ({ age }: AgeCheckFormData) => {
    const birthday = dayjs(age, 'DD-MM-YYYY');
    const isEighteenYearsOld = checkIsEighteenYearsOld(birthday);

    if (isEighteenYearsOld) {
      StorageService.set(StorageKeys.DATE_OF_BIRTH, age);
      navigate(ROUTES.INTRO);
    } else {
      navigate(ROUTES.AGE_LIMIT);
    }
  };

  return (
    <MainLayout>
      <ContentWrapper>
        <StyledLogo />
        <Description>
          Я подтверждаю, что мне исполнилось 18 или более лет, и я являюсь
          потребителем никотиносодержащей продукции.
        </Description>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <StyledDatePickerForForm
            placeholder="Введите вашу дату рождения"
            error={isDateNotEmpty ? errors.age?.message : ''}
            control={control}
          />
          <StyledButton
            type="submit"
            variant="primary"
            isDisabled={!isDateNotEmpty}
          >
            Подтверждаю
          </StyledButton>
        </Form>
      </ContentWrapper>
    </MainLayout>
  );
};

const ContentWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 124px 0 84px 0;
  height: 100%;
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 40px;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 2;
`;

const StyledDatePickerForForm = styled(DatePickerForForm)`
  width: 100%;
  margin-bottom: 235px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.body4}
  margin-bottom: 60px;
  color: ${COLORS.darkBlue};
`;

const StyledButton = styled(Button)`
  margin-top: auto;
`;
