import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled from 'styled-components';

type MenuButtonProps = {
  text: string;
  icon: React.ReactNode;
  isTextUnder?: boolean;
  onClick: () => void;
};

export const MenuButton = ({
  text,
  icon,
  isTextUnder,
  onClick,
}: MenuButtonProps) => {
  return (
    <Root onClick={onClick} $isTextUnder={isTextUnder}>
      <IconWrap>{icon}</IconWrap>
      <ButtonText $isTextUnder={isTextUnder}>{text}</ButtonText>
    </Root>
  );
};

const Root = styled.button<{ $isTextUnder?: boolean }>`
  color: ${COLORS.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 17px;

  ${({ $isTextUnder }) =>
    $isTextUnder &&
    `
    flex-direction: column;
    margin-bottom: 0;
    
  `}
`;

const IconWrap = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 20px;
  background-color: ${COLORS.lightViolet};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.span<{ $isTextUnder?: boolean }>`
  color: ${COLORS.black};
  ${TYPOGRAPHY.body6}
  text-align: center;
  margin-left: 15px;

  ${({ $isTextUnder }) =>
    $isTextUnder &&
    `
    margin-left: 0;
    margin-top: 10px;
    ${TYPOGRAPHY.subline1}
  `}
`;
