import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { ButtonHTMLAttributes, FC } from 'react';
import styled, { css } from 'styled-components';

export interface TagProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive?: boolean;
  className?: string;
}

export const Tag: FC<TagProps> = ({
  className,
  children,
  isActive,
  ...props
}) => {
  return (
    <Root $isActive={isActive} className={className} {...props}>
      {children}
    </Root>
  );
};

interface RootStyleProps {
  $isActive?: boolean;
}

const Root = styled.button<RootStyleProps>`
  border: 0.3px solid ${COLORS.lightBlue};
  border-radius: 4px;
  padding: 4px 6px;
  ${TYPOGRAPHY.subline1}
  line-height: 12px;

  ${({ $isActive }) =>
    $isActive
      ? css`
          background-color: ${COLORS.darkBlue};
          color: ${COLORS.white};
        `
      : css`
          background-color: ${COLORS.white};
          color: ${COLORS.lightBlue};
        `};
`;
