import { COLORS } from 'assets/styles/colors';
import { SIZE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled from 'styled-components';
import { Icon } from 'ui/Icon';
import { SwipeableBottomDrawer } from 'ui/SwipeableBottomDrawer';

type DeviceOccupiedModalProps = {
  isOpen: boolean;
  closeCallBack: () => void;
};

export const DeviceOccupiedModal = ({
  isOpen,
  closeCallBack,
}: DeviceOccupiedModalProps) => {
  return (
    <SwipeableBottomDrawer isOpen={isOpen} onCloseCallback={closeCallBack}>
      <ContentWrapper>
        <Icon type="cantConnect" />
        <ModalTitle>
          Данное устройство используется другим пользователем
        </ModalTitle>
      </ContentWrapper>
    </SwipeableBottomDrawer>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 28px;
  padding: 147px 39px 213px 39px;

  @media (max-width: ${SIZE.mobileM}) {
    padding: 147px 28px 185px 28px;
  }
`;

const ModalTitle = styled.p`
  color: ${COLORS.red};
  ${TYPOGRAPHY.title1}
  text-align: center;
  max-width: 335px;
`;
