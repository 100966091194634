export const ERROR_TEXTS = {
  required: 'Обязательное поле',
  emptyName: 'Введите Имя',
  emptyPassword: 'Введите Пароль',
  email: 'Неверный формат e-mail',
  phone: 'Неверный формат телефона',
  confirmationPassword: 'Пароли не совпадают',
  checkPassword: 'Некорректный пароль',
  invalidCode: 'Введён неверный код',
  invalidDate: 'Неверная дата рождения',
  invalidPhoneOrPassword: 'Неверный номер телефона или пароль',
  somethingWentWrong: 'Что то пошло не так',
  needNoSpaces: 'Пароль не должен содержать пробельные символы',
  needNumbers: 'Пароль должен содержать цифры',
  needSymbols: 'Пароль должен содержать символы: @$!%*#?&.',
  needUpperCase: 'Пароль должен содержать заглавные буквы',
  needLowerCase: 'Пароль должен содержать строчные буквы',
  needLenghtMoreThenSix: 'Пароль должен содержать не менее 6 символов',
  needLenghtMoreThenEight: 'Пароль должен содержать не менее 8 символов',
  needLenghtLessThirtyTwo: 'Пароль должен содержать не более 32 символов',
  needLenghtLessSixtyFour: 'Пароль должен содержать не более 64 символов',
  restrictCyrillicSymbols: 'Пароль не должен содержать кириллицу',
  cantConnectToDevice: 'Не удалось подключиться к устройству',
  deviceIsNotConnected: 'Устройство не подключено',
  userNotAuthorized: 'Пользователь не авторизован',
  notEighteenYearsOld: 'Вам нет 18 лет',
  name: 'Имя может содержать только буквы и пробелы',
  noFirmwareFile: 'Не подготовлен файл обновления',
  cantGetFirmware: 'Не получилось проверить наличие обновлений',
};
