import { TYPOGRAPHY } from 'assets/styles/typography';
import { FC } from 'react';
import styled from 'styled-components';
import { Modal } from 'ui/Modal';
import { ModalButton } from 'ui/ModalButton';

type DeleteDeviceModalProps = {
  text: string;
  isOpen: boolean;
  closeCallback: () => void;
  onYesCallback: () => void;
};

export const ConfirmModal: FC<DeleteDeviceModalProps> = ({
  text,
  isOpen,
  closeCallback,
  onYesCallback,
}) => {
  return (
    <Modal isOpen={isOpen} closeCallback={closeCallback}>
      <Content>
        <Title>{text}</Title>
        <ButtonsContainer>
          <ModalButton isRed onClick={onYesCallback} title="Да" />
          <ModalButton onClick={closeCallback} title="Нет" />
        </ButtonsContainer>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
  width: 316px;
  height: 182px;
  border-radius: 20px;
  background-color: white;
`;

const Title = styled.p`
  text-align: center;
  ${TYPOGRAPHY.popUps}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: center;
`;
