import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from './baseQuery';

const tagTypes = [
  'userData',
  'users',
  'devices',
  'statistics',
  'firmwares',
] as const;

export const baseApi = createApi({
  baseQuery,
  tagTypes,
  endpoints: () => ({}),
});
