import { API_URL } from '../../../lib/constants';
import { HttpMethods } from '../../../types';
import { baseApi } from '../baseApi';

export const featureTogglesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureToggles: builder.query<
      { alias: string; enabled: boolean }[],
      void
    >({
      query: () => ({
        url: API_URL.getFeatureToggles,
        method: HttpMethods.get,
      }),
    }),
  }),
});

export const { useGetFeatureTogglesQuery } = featureTogglesApi;
