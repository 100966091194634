import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import { useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import styled from 'styled-components';
import { Logo } from 'ui';

/**
 * Страница для браузеров не поддерживающих bluetoothAPI.
 */
export const DownloadBrowser = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator?.bluetooth) {
      navigate(ROUTES.ROOT);
    }
  }, []);

  return (
    <MainLayout>
      <ContentWrapper>
        <StyledLogo />
        <Content>
          {isIOS ? (
            <>
              <Title>
                Ваш браузер не поддерживает функцию Bluetooth. Загрузите Plonq
                браузер из App Store, чтобы продолжить
              </Title>
              <StoreLink href="https://apps.apple.com/ca/app/plonq/id1635768769">
                Перейти
              </StoreLink>
            </>
          ) : (
            <Title>
              Ваш браузер не поддерживает функцию Bluetooth. Обновите ваш
              браузер или попробуйте другой
            </Title>
          )}
        </Content>
      </ContentWrapper>
    </MainLayout>
  );
};

const ContentWrapper = styled.div`
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 115px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
`;

const Title = styled.p`
  ${TYPOGRAPHY.title1}
  color: ${COLORS.black};
  text-align: center;
`;

const StoreLink = styled.a`
  color: ${COLORS.darkBlue};
`;
