import { COLORS } from 'assets/styles/colors';
import { DEVICE } from 'assets/styles/devices';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
interface MainLayoutProps {
  children: ReactNode;
  className?: string;
}

export const MainLayout: FC<MainLayoutProps> = ({ children, className }) => {
  return (
    <Root className={className}>
      <Main className="main-layout__main">{children}</Main>
    </Root>
  );
};

const Root = styled.div`
  min-height: var(--100vh-height);

  position: relative;
  max-width: 414px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  padding: 32px 24px 20px 24px;
  background-color: ${COLORS.white};

  @media ${DEVICE.mobileM} {
    padding: 64px 50px 20px 50px;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
`;
