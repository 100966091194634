import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';

import { PlonqDevice } from '../plonq-device';

/**
 * Хук для проверки на подключение устройства.
 * Если устройство не подключено перенаправляет пользователя на экран
 * выбора устройства.
 */
const usePlonqDeviceConnectedCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!PlonqDevice.getInstance().checkIsBleConnected()) {
      navigate(ROUTES.ROOT);
    }
  }, [navigate]);
};

export default usePlonqDeviceConnectedCheck;
