import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { getHiddenPhoneNumber } from 'lib/helpers';
import { FC } from 'react';
import styled from 'styled-components';
import { Spinner, Timer } from 'ui';
import { CodeInput } from 'ui/CodeInput';

interface SmsCodeWithResendProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  canResendSms: boolean;
  error?: string;
  phone: string;
  secondsToNextAttempt: number;
  onCodeTypingFinished: (codeValue: string) => Promise<void>;
  onRepeatSendClick: () => void;
  onEndTimer: () => void;
}

const SmsCodeWithResend: FC<SmsCodeWithResendProps> = ({
  isLoading,
  isDisabled,
  canResendSms,
  error,
  phone,
  secondsToNextAttempt,
  onCodeTypingFinished,
  onRepeatSendClick,
  onEndTimer,
}) => {
  const hiddenPhoneNumber = getHiddenPhoneNumber(phone);

  if (isLoading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <Root>
      <Description>
        Мы отправили SMS с кодом на номер {hiddenPhoneNumber}
      </Description>

      <StyledCodeInput
        isDisabled={isDisabled}
        error={error}
        onComplete={onCodeTypingFinished}
      />

      {canResendSms ? (
        <SendSmsButton onClick={onRepeatSendClick}>
          Отправить SMS повторно
        </SendSmsButton>
      ) : (
        <>
          <SendMessage>Отправить повторное SMS через</SendMessage>
          <Timer
            initialSeconds={secondsToNextAttempt}
            onEndTimer={onEndTimer}
            isStart={!canResendSms}
          />
        </>
      )}
    </Root>
  );
};

export default SmsCodeWithResend;

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
  max-width: 212px;
  margin: 0 auto 32px;
`;

const SendMessage = styled.p`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
  margin: 0 auto 14px;
`;

const StyledCodeInput = styled(CodeInput)`
  margin-bottom: 32px;
`;

const SendSmsButton = styled.button`
  ${TYPOGRAPHY.body1}
  color: ${COLORS.darkBlue};
  margin: 0 auto;
`;
