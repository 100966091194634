import { IDevice } from 'lib/services/interfaces';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useState } from 'react';

import { PlonqDevice } from '../plonq-device';

/**
 * Хук отвечает за хранение характеристик устройства и уведомления
 * о изменениях в характеристиках для UI компонентов.
 *
 * Подписывается на изменения в {@link PlonqDevice}. Получая изменения
 * обновляет стейт, что ведет за собой ререндер UI компонентов.
 *
 * Для оптимизации обновления используется throttle на 3 секунды.
 *
 * Хук используется во всех компонентах где необходимо предоставить
 * информацию об устройстве.
 *
 * @example
 * const [deviceState, setDeviceState] = useDeviceState();
 *
 * if (!PlonqDevice.getInstance().checkIsBleConnected()) {
 *  throw new Error('Device not connected');
 * }
 *
 * setDeviceState(PlonqDevice.getInstance());
 *
 * deviceState && <div>{deviceState.firmwareVersion}</div>
 *
 *
 * @returns React useState обертку над {@link PlonqDevice}
 */
const useDeviceState = () => {
  const [deviceRef, setDeviceRef] = useState<PlonqDevice>();
  const [deviceState, setDeviceState] = useState<IDevice | undefined>();

  const deviceToState = (device: PlonqDevice) => {
    const isBleConnected = device.checkIsBleConnected();

    if (isBleConnected) {
      return {
        name: device.getName(),
        modelName: device.getModelName(),
        macAddress: device.getMacAddress(),
        cartridgeId: device.getCartridgeId(),
        firmwareVersion: device.getFirmware(),
        hardwareVersion: device.getHardwareVersion(),
        batteryLevel: device.getBatteryLevel(),
        isCharging: device.getIsCharging(),
        rssi: device.getRSSI(),
        isChildLockActive: device.getChildLockIsActive(),
        batteryChargingTime: device.getBatteryChargingTime(),
      };
    }
  };

  const onDeviceUpdate = useCallback((device: PlonqDevice) => {
    setDeviceState(() => deviceToState(device));
  }, []);

  const throttledDeviceUpdate = useCallback(throttle(onDeviceUpdate, 32), []);

  const setDevice = (device?: PlonqDevice) => {
    setDeviceRef(device);
  };

  useEffect(() => {
    if (deviceRef) {
      setDeviceState(() => deviceToState(deviceRef));
      deviceRef.subscribeOnUpdates(throttledDeviceUpdate);
    }

    return () => {
      deviceRef?.unsubscribeFromUpdate(throttledDeviceUpdate);
    };
  }, [deviceRef, throttledDeviceUpdate]);

  return [deviceState, setDevice] as const;
};

export default useDeviceState;
