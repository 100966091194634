import { StepsWrapper } from 'components';
import { StepperProvider, useStepper } from 'components/Stepper';
import { withRedirect } from 'lib/hocs';
import { PasswordRestoreSteps } from 'types';

import {
  GetPhoneContainer,
  StepNewPassword,
  StepRestoreSuccess,
  StepSmsCheck,
} from './components';

const passwordRestoreSteps = [
  {
    label: PasswordRestoreSteps.getPhone,
    component: (
      <GetPhoneContainer
        title="Восстановление пароля"
        nextStep={PasswordRestoreSteps.sendCode}
      />
    ),
  },
  {
    label: PasswordRestoreSteps.sendCode,
    component: (
      <StepSmsCheck
        title="Восстановление пароля"
        nextStep={PasswordRestoreSteps.newPassword}
        previousStep={PasswordRestoreSteps.getPhone}
      />
    ),
  },
  {
    label: PasswordRestoreSteps.newPassword,
    component: <StepNewPassword />,
  },
  {
    label: PasswordRestoreSteps.restoreSuccess,
    component: <StepRestoreSuccess />,
  },
];

const RestorePassword = () => {
  const stepper = useStepper({
    steps: passwordRestoreSteps,
    defaultStep: PasswordRestoreSteps.getPhone,
  });

  return (
    <StepperProvider {...stepper}>
      <StepsWrapper />
    </StepperProvider>
  );
};

export default withRedirect(RestorePassword);
