import { ICartridge } from 'lib/services/interfaces';

const cartridges: ICartridge[] = [
  {
    id: 1,
    ridMin: 500,
    ridMax: 2374,
    flavor: 'A1',
    nicotine: 20,
    readableName: 'Pineapple',
    readableRuName: 'Ананас',
    comment: '',
  },
  {
    id: 2,
    ridMin: 2375,
    ridMax: 5624,
    flavor: 'A2',
    nicotine: 20,
    readableName: 'Mango',
    readableRuName: 'Манго',
    comment: '',
  },
  {
    id: 3,
    ridMin: 5625,
    ridMax: 10249,
    flavor: 'A3',
    nicotine: 20,
    readableName: 'Cherry',
    readableRuName: 'Вишня',
    comment: '',
  },
  {
    id: 4,
    ridMin: 10250,
    ridMax: 17824,
    flavor: 'A4',
    nicotine: 20,
    readableName: 'Tabaco',
    readableRuName: 'Табак',
    comment: '',
  },
  {
    id: 5,
    ridMin: 17825,
    ridMax: 31824,
    flavor: 'A5',
    nicotine: 20,
    readableName: 'Mint',
    readableRuName: 'Мята',
    comment: '',
  },
  {
    id: 6,
    ridMin: 3182,
    ridMax: 70499,
    flavor: 'A6',
    nicotine: 20,
    readableName: 'Green Tea',
    readableRuName: 'Зеленый чай',
    comment: '',
  },
];

export const getCartridgeById = (id?: number): ICartridge | undefined => {
  return cartridges.find((cartridge) => cartridge.id === id);
};
