import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserProfileResponseDto, UserTokenizedResponseDto } from 'api';
import {
  getTokenFromCookies,
  removeTokenFromCookies,
  setRefreshTokenToCookies,
  setTokenToCookies,
} from 'lib/services/cookieService';

import { AuthState, CheckCodeContext, User } from './types';

const initialState: AuthState = {
  user: {
    token: '',
  } as User,
  signUpContext: {
    phone: '',
    code: '',
    nextAttempt: '',
  },
  passwordRestoreContext: {
    phone: '',
    code: '',
    nextAttempt: '',
  },
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserWithToken: (
      state,
      { payload }: PayloadAction<UserTokenizedResponseDto>,
    ) => {
      state.user = payload;
      setTokenToCookies(payload.token);
      setRefreshTokenToCookies(payload.refreshToken);
    },

    setToken: (state, { payload }: PayloadAction<string>) => {
      state.user = {
        ...state.user,
        token: payload,
      };
    },

    setUser: (state, { payload }: PayloadAction<UserProfileResponseDto>) => {
      const tokenFromCookies = getTokenFromCookies();

      state.user = { ...state.user, ...payload };

      if (tokenFromCookies) {
        state.user.token = tokenFromCookies;
      }
    },

    setSignUpContext: (state, { payload }: PayloadAction<CheckCodeContext>) => {
      state.signUpContext = payload;
    },

    setPasswordRestoreContext: (
      state,
      { payload }: PayloadAction<CheckCodeContext>,
    ) => {
      state.passwordRestoreContext = payload;
    },

    logOut: (state) => {
      state.user = {} as User;
      removeTokenFromCookies();
    },
  },
});

export const {
  setUserWithToken,
  setUser,
  setToken,
  setSignUpContext,
  setPasswordRestoreContext,
  logOut,
} = slice.actions;

export default slice.reducer;
