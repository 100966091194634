const storage = window.localStorage;

/**
 * Класс для работы с LocalStorage.
 *
 * Используется для сохранения токенов авторизации и указанного возраста.
 */
class StorageService {
  set = (key: string, value: string): void => {
    storage.setItem(key, JSON.stringify(value));
  };

  get = <T>(key: string): T | null => {
    const value = storage.getItem(key);
    return value ? JSON.parse(value) : null;
  };
}

export default new StorageService();
