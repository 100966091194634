export const mergeHexCodes = (hex: string[], isNeedReverse = true) => {
  if (!isNeedReverse) {
    return '0x' + hex.map((hex) => hex.slice(2)).join('');
  } else {
    return (
      '0x' +
      hex
        .map((hex) => hex.slice(2))
        .reverse()
        .join('')
    );
  }
};

export const toHexCode = (value: number, byteLength = 1): string => {
  return '0x' + value.toString(16).padStart(2 * byteLength, '0');
};

export const toHexArray = (value: number, byteLength = 1): string[] => {
  const hexArr: string[] = [];
  const hexString = toHexCode(value, byteLength).slice(2);

  for (let i = 0; i < hexString.length; i += 2) {
    hexArr.push('0x' + hexString.slice(i, i + 2));
  }

  return hexArr;
};
