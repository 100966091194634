export const TYPOGRAPHY = {
  title1: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.26px;
  `,
  title2: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.2px;
  `,
  subtitle1: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.26px;
  `,
  subtitle2: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -0.26px;
  `,
  subtitle3: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.22px;
  `,
  body1: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
  `,
  body2: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.26px;
  `,
  body3: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.26px;
    `,
  body4: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
  `,
  body5: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: -0.002em;
    `,
  body6: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.1px;
  `,
  buttons: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.26px;
  `,
  inputs: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.26px;
  `,
  code: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 25px;
    line-height: 28px;
    letter-spacing: -0.26px;
  `,
  subline1: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 300;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.26px;
  `,
  subline2: `
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.22px;
  `,
  subline3: `
  font-family: 'Inter', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: -0.26px;
  `,
  subline4: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -0.26px;
  `,
  statictic1: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
  `,
  big: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 38px;
    line-height: 40px;
    letter-spacing: -0.26px;
  `,
  popUps: `
    font-family: 'Inter', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.5px;
    letter-spacing: -0.26px;
  `,
};
