import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import styled from 'styled-components';
import { BackButton, Logo } from 'ui';

export const AgeLimit = () => {
  return (
    <MainLayout>
      <ContentWrapper>
        <Header>
          <StyledBackButton />
          <Logo />
        </Header>

        <Description>
          Доступ к приложению разрешен только лицам старше 18 лет
        </Description>
      </ContentWrapper>
    </MainLayout>
  );
};

const ContentWrapper = styled.div`
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: -10px;
  top: -2px;
`;

const Description = styled.p`
  ${TYPOGRAPHY.body4}
  margin-bottom: 60px;
  color: ${COLORS.darkBlue};
`;
