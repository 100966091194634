import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useIsPlonqBrowser } from 'lib/hooks';
import styled from 'styled-components';

type MenuLinkProps = {
  address: string;
  text: string;
  icon: React.ReactNode;
  isTextUnder?: boolean;
};

export const MenuLink = ({
  address,
  text,
  icon,
  isTextUnder,
}: MenuLinkProps) => {
  const isPlonqBrowser = useIsPlonqBrowser();

  return (
    <StyledLink
      target={isPlonqBrowser ? '_self' : '_blank'}
      href={address}
      $isTextUnder={isTextUnder}
    >
      <IconWrap>{icon}</IconWrap>
      <LinkText $isTextUnder={isTextUnder}>{text}</LinkText>
    </StyledLink>
  );
};

const StyledLink = styled.a<{ $isTextUnder?: boolean }>`
  color: ${COLORS.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  ${({ $isTextUnder }) =>
    $isTextUnder &&
    `
    flex-direction: column;
    margin-bottom: 0;
    
  `}
`;

const IconWrap = styled.div`
  width: 52px;
  height: 52px;
  border-radius: 20px;
  background-color: ${COLORS.lightViolet};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinkText = styled.span<{ $isTextUnder?: boolean }>`
  color: ${COLORS.black};
  ${TYPOGRAPHY.body6}
  text-align: center;
  margin-left: 15px;

  ${({ $isTextUnder }) =>
    $isTextUnder &&
    `
    margin-left: 0;
    margin-top: 10px;
    ${TYPOGRAPHY.subline1}
  `}
`;
