import { TYPOGRAPHY } from 'assets/styles/typography';
import { BUTTON_THEMES, ROTATE_RIGHT } from 'lib/constants';
import { ButtonHTMLAttributes, FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import { Icon } from '../Icon/Icon';

type ButtonVariant = 'primary' | 'secondary' | 'transparent';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: ButtonVariant;
  isLoading?: boolean;
  isDisabled?: boolean;
  icon?: React.ReactNode;
  type?: 'button' | 'reset' | 'submit';
}

export const Button: FC<ButtonProps> = ({
  variant,
  icon,
  isLoading = false,
  isDisabled,
  type = 'button',
  children,
  ...props
}) => {
  const buttonTheme = BUTTON_THEMES[variant];
  return (
    <StyledButton
      disabled={isDisabled}
      variant={buttonTheme}
      type={type}
      {...props}
    >
      {isLoading ? (
        <>
          <SpinnerWrap>
            <StyledSpinnerIcon type="buttonLoader" />
          </SpinnerWrap>
          {children}
        </>
      ) : (
        <>
          {icon && <IconWrap>{icon}</IconWrap>}
          {children}
        </>
      )}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ variant: () => string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 314px;
  height: 56px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 50px;
  border: none;
  ${TYPOGRAPHY.buttons}
  ${({ variant }) => variant};
`;

const IconWrap = styled.div`
  display: flex;
  margin-right: 12px;
`;

const SpinnerWrap = styled.div`
  display: flex;
  margin-right: 12px;
`;

const StyledSpinnerIcon = styled(Icon)`
  animation: ${ROTATE_RIGHT} 2s linear infinite;
`;
