export function getBatteryMinuteDeclension(count: number) {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  switch (true) {
    case lastTwoDigits >= 11 && lastTwoDigits <= 14:
      return `${count} минут`;
    case lastDigit === 1:
      return `${count} минута`;
    case lastDigit >= 2 && lastDigit <= 4:
      return `${count} минуты`;
    default:
      return `${count} минут`;
  }
}

export function getBatteryPuffsContDeclension(count: number) {
  const lastDigit = count % 10;
  const lastTwoDigits = count % 100;

  switch (true) {
    case lastTwoDigits >= 11 && lastTwoDigits <= 14:
      return `${count} затяжек`;
    case lastDigit === 1:
      return `${count} затяжка`;
    case lastDigit >= 2 && lastDigit <= 4:
      return `${count} затяжки`;
    default:
      return `${count} затяжек`;
  }
}
