export const getMaskedPhoneNumber = (phone?: string) => {
  if (!phone) return '';

  const firstPart = phone?.slice(0, 2);
  const secondPart = phone?.slice(2, 5);
  const thirdPart = phone?.slice(5, 8);
  const forthPart = phone?.slice(8, 10);
  const fithPart = phone?.slice(10, 12);

  return `${firstPart} ${secondPart} ${thirdPart}-${forthPart}-${fithPart}`;
};
