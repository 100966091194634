import { COLORS } from 'assets/styles/colors';
import { DEVICE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { MainLayout } from 'layouts/MainLayout/MainLayout';
import {
  IUpdateDeviceState,
  UpdateDeviceStates,
} from 'pages/UpdateDevice/UpdateDevice';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import styled from 'styled-components';
import { Button, Icon } from 'ui';

interface Props extends IUpdateDeviceState {
  errorText?: string;
  state: UpdateDeviceStates;
  hasDeviceConnect: boolean;
}

/**
 * Экран с информацией об ошибке обновления прошивки.
 */
export const FailedState: FC<Props> = ({
  errorText,
  state,
  changeState,
  hasDeviceConnect,
}) => {
  const navigate = useNavigate();

  const handelConfirm = () => {
    if (
      state === UpdateDeviceStates.PREPARE ||
      state === UpdateDeviceStates.PROCESS ||
      !hasDeviceConnect
    ) {
      return navigate(ROUTES.ROOT);
    }

    return changeState(UpdateDeviceStates.PREPARE);
  };

  return (
    <Root>
      <ContentWrapper>
        <StyledIcon type="coneBig" />
        <Description>{errorText || 'Что-то пошло не так'}</Description>
      </ContentWrapper>

      <StyledButton type="submit" variant="primary" onClick={handelConfirm}>
        Вернуться
      </StyledButton>
    </Root>
  );
};

const Root = styled(MainLayout)`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media ${DEVICE.mobileM} {
    padding: 60px 50px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 30px;
`;
const StyledButton = styled(Button)`
  max-width: 294px;
  width: 100%;
  margin: 0 auto;
`;

const Description = styled.p`
  ${TYPOGRAPHY.body3}
  color: ${COLORS.black};
  text-align: center;
  max-width: 225px;
  margin-bottom: 24px;
`;
