import { keyframes } from 'styled-components';

export const BUTTON_THEMES = {
  primary: () => `
      color: #ffffff;
      background: #323232;
      &:hover:enabled {
        cursor: pointer;
      };
      &:disabled {
        background: #D3D3D3;
      };
    `,
  secondary: () => `
      color: #ffffff;
      background: #323232;
      &:hover:enabled {
        cursor: pointer;
      };
      &:disabled {
        color: rgba(255, 255, 255, 0.3);
      };
    `,
  transparent: () => `
      color: #323232;
      background: transparent;
      border: 0.8px solid #323232;
      &:hover:enabled {
        cursor: pointer;
      };
      &:disabled {
        background: transparent;
      };
    `,
  none: () => ``,
};

export const ROTATE_RIGHT = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
