import 'react-modern-drawer/dist/index.css';

import { useHandleEscapePress } from 'lib/hooks';
import React from 'react';
import Drawer from 'react-modern-drawer';
import styled from 'styled-components';

type BottomDrawerProps = {
  isOpen: boolean;
  className?: string;
  children?: React.ReactNode;
  onCloseCallback: () => void;
};

export const BottomDrawer = ({
  isOpen,
  children,
  className,
  onCloseCallback,
}: BottomDrawerProps) => {
  useHandleEscapePress(onCloseCallback);

  return (
    <>
      {isOpen && (
        <StyledDrawer
          open={isOpen}
          onClose={onCloseCallback}
          direction="bottom"
          size="498px"
          className={className}
        >
          {children}
        </StyledDrawer>
      )}
    </>
  );
};

const StyledDrawer = styled(Drawer)`
  width: 100%;
  max-width: 414px !important;
  margin: 0 auto;
  border-radius: 40px 40px 0px 0px;
  padding: 50px;
`;
