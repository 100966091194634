import { COLORS, TRANSPARENCY_COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui/Icon';

type OneAverageValueProps = {
  value: number;
  text: string;
  isLight?: boolean;
  isArrowUp?: boolean;
  isAverage?: boolean;
  isNicotine?: boolean;
  openModalCallback?: () => void;
  icon?: ReactNode;
};

export const OneAverageValue = ({
  value,
  text,
  isLight = false,
  isArrowUp = false,
  isAverage = false,
  isNicotine = false,
  openModalCallback,
  icon,
}: OneAverageValueProps) => {
  return (
    <Root>
      <Title isLight={isLight}>
        {icon && <TitleIconWrapper>{icon}</TitleIconWrapper>} {text}
      </Title>
      <ValueContainer>
        <Value>
          {!isAverage && (
            <IconWrap $isArrowUp={isArrowUp}>
              <Icon type="arrowDecline" />
            </IconWrap>
          )}
          {Math.round(value)}
        </Value>
        {isNicotine && <Milligrams>мкг</Milligrams>}
      </ValueContainer>
      {isAverage && (
        <QuestionIconWrap onClick={openModalCallback}>
          <Icon type="questionMark" />
        </QuestionIconWrap>
      )}
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  flex-grow: 2;
  :first-child {
    width: 100%;
    max-width: 126px;
    flex-grow: 0;
  }
`;

const IconWrap = styled.div<{ $isArrowUp: boolean }>`
  position: absolute;
  top: -2px;
  right: -16px;

  ${({ $isArrowUp }) =>
    $isArrowUp &&
    `
      transform: rotate(260deg);
      & svg path {
        fill: ${COLORS.red};
      }
  `}
`;

const ValueContainer = styled.div`
  display: flex;
`;

const Value = styled.div`
  position: relative;
  color: ${COLORS.black};
  ${TYPOGRAPHY.statictic1}
`;

const Milligrams = styled.span`
  margin-left: 4px;
  ${TYPOGRAPHY.subline3}
  color: ${TRANSPARENCY_COLORS.darkBlueSixty};
  align-self: flex-end;
`;

const Title = styled.p<{ isLight: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ isLight }) =>
    isLight ? TRANSPARENCY_COLORS.darkBlueSixty : COLORS.darkBlue};
  ${TYPOGRAPHY.subtitle2}
`;

const QuestionIconWrap = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  opacity: 60%;
`;

const TitleIconWrapper = styled.span`
  margin-right: 6px;
`;
