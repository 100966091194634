import './picker.css';

import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { useState } from 'react';
import Picker from 'react-mobile-picker-scroll';
import styled from 'styled-components';
import { Button } from 'ui/Button';

type DevicesListProps = {
  devices: string[];
  onSubmitClick: (value: string) => void;
  onCloseCallback: () => void;
  title: string;
  buttonText: string;
};

export const DevicesList = ({
  devices,
  onSubmitClick,
  onCloseCallback,
  title,
  buttonText,
}: DevicesListProps) => {
  const [selectedDevice, setSelectedDevice] = useState(devices[0]);

  const [optionGroups] = useState({
    element: devices,
  });

  const [valueGroups, setValueGroups] = useState({
    element: selectedDevice,
  });

  const handleChange = (key: string, value: string) => {
    setSelectedDevice(value);
    setValueGroups({ ...valueGroups, [key]: value });
  };

  return (
    <>
      <Title>{title}</Title>
      <Picker
        optionGroups={optionGroups}
        valueGroups={valueGroups}
        onChange={handleChange}
      />
      <StyledButton
        onClick={() => onSubmitClick(selectedDevice)}
        variant="primary"
      >
        {buttonText}
      </StyledButton>
      <CustomButton onClick={onCloseCallback}>Отмена</CustomButton>
    </>
  );
};

const Title = styled.p`
  ${TYPOGRAPHY.title1}
  margin: 0 auto 15px auto;
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: 0 auto 18px auto;
`;

const CustomButton = styled.button`
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.body1}
  display: flex;
  margin: 0 auto;
`;
