import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled from 'styled-components';
import { Icon } from 'ui/Icon';

type ErrorBlockProps = {
  text: string;
};

export const ErrorBlock = ({ text }: ErrorBlockProps) => {
  return (
    <ErrorContainer>
      <Icon type="coneBig" />
      <Title>Ошибка</Title>
      <Text>{text}</Text>
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 28px;
`;

const Title = styled.p`
  ${TYPOGRAPHY.title1}
  color: ${COLORS.red};
  margin-top: 4px;
`;

const Text = styled.p`
  color: ${COLORS.red};
  ${TYPOGRAPHY.body1}
  text-align: center;
  max-width: 314px;
`;
