export const SIZE = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  mobileXL: '475px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '1200px',
  desktopS: '1366px',
  desktopL: '1920px',
};

export const DEVICE = {
  mobileS: `(min-width: ${SIZE.mobileS})`,
  mobileM: `(min-width: ${SIZE.mobileM})`,
  mobileL: `(min-width: ${SIZE.mobileL})`,
  mobileXL: `(min-width: ${SIZE.mobileXL})`,
  tablet: `(min-width: ${SIZE.tablet})`,
  laptop: `(min-width: ${SIZE.laptop})`,
  desktop: `(min-width: ${SIZE.desktop})`,
  desktopS: `(min-width: ${SIZE.desktopS})`,
  desktopL: `(min-width: ${SIZE.desktopL})`,
};
