import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { FC } from 'react';
import styled from 'styled-components';

type ProgressBarProps = {
  percentNumber: number;
  withPercent: boolean;
  isLight?: boolean;
};

export const ProgressBar: FC<ProgressBarProps> = ({
  percentNumber,
  withPercent,
  isLight = false,
}) => {
  return (
    <>
      <Root>
        <Bar percent={percentNumber + '%'} isLight={isLight}></Bar>
      </Root>
      {withPercent && <PercentNumber>{`${percentNumber} %`}</PercentNumber>}
    </>
  );
};

const Root = styled.div`
  background-color: ${COLORS.grey};
  width: 100%;
  max-width: 294px;
  height: 6px;
  margin: 0 auto;
  border-radius: 4px;
`;

const Bar = styled.div<{ percent: string; isLight: boolean }>`
  background-color: ${COLORS.darkBlue};
  width: ${({ percent }) => percent};
  height: 6px;
  border-radius: 4px;
  opacity: ${({ isLight }) => (isLight ? '30%' : '100%')};
`;

const PercentNumber = styled.div`
  margin-top: 18px;
  ${TYPOGRAPHY.body5}
  letter-spacing: -0.002em;
  color: ${COLORS.black};
  text-align: center;
`;
