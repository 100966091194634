import { useInterval } from 'lib/hooks/useInterval';
import * as _ from 'lodash';
import { useEffect, useState } from 'react';
import { usePostStatisticsMutation } from 'store/api/statistics';
import { PowerSettings } from 'types';

import { PlonqPuffArray } from '../plonq-puff-array';

/**
 * Хук отвечает за выгрузку записей о затяжках на сервер.
 * Использует хук {@link usePuffArrayState} для доступа к массиву записей о затяжках.
 *
 * Каждые 3 секунды проверяет список записей и если он не пустой отправляет его на
 * сервер. После отправки очищает список записей.
 *
 * Для использования нужно просто добавить в необходимый компонент.
 *
 * @example
 * usePuffArrayUpload();
 */
const usePuffArrayUpload = (deviceId: string) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [postStatistics, { isLoading: isSynchronize }] =
    usePostStatisticsMutation();

  const handlePostStatistics = async () => {
    if (!PlonqPuffArray.getInstance().getPuffArrayLength()) {
      return;
    }

    try {
      setIsUploading(true);

      const puffArray = PlonqPuffArray.getInstance().getPuffArray();

      console.log('[UPLOAD_PUFFS]', puffArray);

      for (const chunk of _.chunk(puffArray, 100)) {
        await postStatistics({
          deviceId,
          puffs: chunk.map((puff) => ({
            timestamp: new Date(puff.timestamp).toISOString(),
            puffTime: puff.puffTime,
            powerSettings: PowerSettings.MEDIUM,
            cartridgeFactoryId: puff.cartridgeId,
            nicotineLevel: puff.nicotineLevel,
            number: puff.number,
          })),
        }).unwrap();
      }

      PlonqPuffArray.getInstance().deletePuffs(puffArray.length + 1);
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    PlonqPuffArray.getInstance().syncWithBLE();
  }, []);

  useInterval(() => !isSynchronize && handlePostStatistics(), 3000);

  return isSynchronize || isUploading;
};

export default usePuffArrayUpload;
