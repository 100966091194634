import { wait } from 'lib/helpers';
import { PlonqDevice } from 'lib/services/plonq/plonq-device';
import { PlonqDeviceConnection } from 'lib/services/plonq/plonq-device-connection';

import { DefaultBluetoothGateway } from './default-bluetooth-gateway';
import { GATTGateway } from './gatt-gateway';

export type Handler = () => void;

/**
 * Класс для работы с bluetoothApi.
 * Отвечает за подключения bluetooth устройств,
 * создание bluetoothDevice и инициализацию GATT сервера.
 *
 * Адаптирует BluetoothDevice под экземпляр {@link PlonqDevice}
 * для работы приложения.
 */
export class BluetoothConnection {
  private bluetoothGateway: DefaultBluetoothGateway;
  private gattGateway?: GATTGateway;
  private bluetoothDevice?: BluetoothDevice;
  private disconnectHandlers: Handler[] = [];
  private reconnectHandlers: Handler[] = [];
  private isStopDisconnectHandling = false;

  constructor() {
    this.bluetoothGateway = new DefaultBluetoothGateway();
  }

  public getBluetoothGateway(): DefaultBluetoothGateway {
    return this.bluetoothGateway;
  }

  public getGATTGateway(): GATTGateway {
    if (!this.gattGateway) {
      throw new Error('GATT server not connected');
    }

    return this.gattGateway;
  }

  /**
   * Поиск и подключение bluetooth устройств.
   * При подключении инициализируется {@link GATTGateway} и все
   * сервисы и характеристики имеющиеся у устройства и подпись на событие
   * потери подключения.
   *
   * Используется для подключения новых PLONQ устройств.
   *
   * @param namePrefix - префикс имени устройства.
   * @param serviceUUIDs - массив uuid сервисов которое должно быть у устройств.
   * @returns - экземпляр {@link PlonqDevice}
   */
  public async requestDevice(
    namePrefix: string,
    serviceUUIDs: string[],
  ): Promise<PlonqDevice> {
    const bluetoothDevice = await this.bluetoothGateway.requestDevice(
      [{ namePrefix }],
      serviceUUIDs,
    );
    return this.handleConnectionWithDevice(bluetoothDevice);
  }

  /**
   * Поиск и подключение bluetooth устройств по конкретному имени.
   * При подключении инициализируется {@link GATTGateway} и все
   * сервисы и характеристики имеющиеся у устройства и подпись на событие
   * потери подключения.
   *
   * Используется для подключения к PLONQ устройств уже добавленных в список.
   *
   * @param deviceName - имя устройства.
   * @param serviceUUIDs - массив uuid сервисов которое должно быть у устройств.
   * @returns - экземпляр {@link PlonqDevice}
   */
  public async connectDevice(
    deviceName: string,
    serviceUUIDs: string[],
  ): Promise<PlonqDevice> {
    const bluetoothDevice = await this.bluetoothGateway.connectDevice(
      deviceName,
      serviceUUIDs,
    );
    return this.handleConnectionWithDevice(bluetoothDevice);
  }

  public async getDevice(deviceName: string): Promise<PlonqDevice> {
    const bluetoothDevice = await this.bluetoothGateway.getDevice(deviceName);
    return this.handleConnectionWithDevice(bluetoothDevice);
  }

  /**
   * Обработка подключения устройства.
   * Инициализация всех важных компонентов необходимых
   * для работы с bluetooth устройствами.
   *
   * Подписка на событие потери соединения.
   * Инициализация GATT Server, создание экземпляра {@link PlonqDevice}
   *
   * Используется для подключения к PLONQ устройств уже добавленных в список.
   *
   * @param bluetoothDevice - экземпляр подключенного BluetoothDevice.
   * @returns - экземпляр {@link PlonqDevice}
   */
  private async handleConnectionWithDevice(
    bluetoothDevice: BluetoothDevice,
  ): Promise<PlonqDevice> {
    this.bluetoothDevice = bluetoothDevice;

    this.bluetoothDevice.addEventListener(
      'gattserverdisconnected',
      this.handleDisconnect.bind(this),
    );

    await this.createGATTServer(bluetoothDevice);
    const device = PlonqDevice.getInstance();
    device.setBleConnection(new PlonqDeviceConnection(this));
    await device.syncWithBLE(this.bluetoothDevice.name || 'NO_NAME');

    return device;
  }

  /**
   * Создание и инициализация GATT Server.
   * Для GATT Server читаются все характеристики и сервисы
   * имеющиеся на устройстве и кэшируются в оперативной памяти.
   * @param bluetoothDevice - экземпляр подключенного BluetoothDevice.
   */
  private async createGATTServer(
    bluetoothDevice: BluetoothDevice,
  ): Promise<void> {
    if (!bluetoothDevice.gatt) {
      throw new Error('GATT server not defined for device');
    }

    const gattServer = await bluetoothDevice.gatt.connect();
    this.gattGateway = new GATTGateway(gattServer);
    await this.gattGateway.initServicesAndCharacteristics();
  }

  public getGATTService(uuid: string): BluetoothRemoteGATTService {
    if (!this.gattGateway) {
      throw new Error('GATT Server not connected');
    }

    return this.gattGateway.getService(uuid);
  }

  public getGATTCharacteristic(
    uuid: string,
  ): BluetoothRemoteGATTCharacteristic {
    if (!this.gattGateway) {
      throw new Error('GATT Server not connected');
    }

    return this.gattGateway.getCharacteristic(uuid);
  }

  public onDisconnect(handler: Handler): void {
    this.disconnectHandlers.push(handler);
  }

  public onReconnect(handler: Handler): void {
    this.reconnectHandlers.push(handler);
  }

  private async handleDisconnect(): Promise<void> {
    if (this.isStopDisconnectHandling) {
      return;
    }
    console.log('start disconnect');
    if (await this.reconnect()) {
      return;
    }
    this.disconnectHandlers.forEach((handler) => handler());
    this.disconnectHandlers = [];
    console.log('disconnect done');
  }

  /**
   * Подписка на подключение потери соединения.
   * При потере соединения производится 3 попытки восстановить соединения.
   * Каждая попытка занимает не более 2 секунд.
   *
   * При восстановлении соединения делается пауза в 1 секунду.
   * Пауза необходима для корректной работы на ios устройствах, тк
   * значительно отличается логика подключения.
   *
   * После восстановления реинициализируется GATT Server, тк
   * предыдущие экземпляры сервисов и характеристик становятся не
   * работоспособны.
   *
   * Вызываются все хэндлеры на реконект. Необходимо для всех
   * сервисов работающих с устройством.
   */
  private async reconnect(): Promise<boolean> {
    try {
      console.log('start reconnect');
      for (let i = 0; i < 3; ++i) {
        const gattServer = await Promise.race([
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          this.bluetoothDevice?.gatt?.connect(),
          wait(2000),
        ]);

        console.log('reconnect: ', !!gattServer?.connected);

        if (gattServer?.connected) {
          await wait(1000);
          const gattGateway = new GATTGateway(gattServer);
          await gattGateway.initServicesAndCharacteristics();
          this.gattGateway = gattGateway;

          this.reconnectHandlers.forEach((handler) => handler());
          return true;
        }
      }

      return false;
    } catch (err) {
      return false;
    }
  }

  /**
   * Мануальная реинициализация GATT Server.
   *
   * Необходимо для старых версий прошивок. Решает проблему увеличения
   * времени отклика от устройства. После обновления характеристик
   * время отклика приходит в норму.
   */
  public async refreshGattConnection(): Promise<void> {
    this.isStopDisconnectHandling = true;
    console.log('refresh');
    if (!this.bluetoothDevice || !this.bluetoothDevice.gatt) {
      throw new Error('GATT Server not connected');
    }

    await this.bluetoothDevice.gatt.disconnect();
    await wait(1200);
    const newGatt = await this.bluetoothDevice.gatt.connect();
    await wait(100);
    this.gattGateway = new GATTGateway(newGatt);
    await this.gattGateway.initServicesAndCharacteristics();
    this.isStopDisconnectHandling = false;
  }
}
