import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import styled from 'styled-components';

type ModalButtonProps = {
  title: string;
  onClick: () => void;
  isRed?: boolean;
};

export const ModalButton = ({
  title,
  onClick,
  isRed = false,
}: ModalButtonProps) => {
  return (
    <Button onClick={onClick} isRed={isRed}>
      {title}
    </Button>
  );
};

const Button = styled.button<{ isRed: boolean }>`
  width: 100%;
  max-width: 100px;
  height: 46px;
  border: 1px solid ${({ isRed }) => (isRed ? COLORS.red : COLORS.lightBlue)};
  border-radius: 15px;
  color: ${({ isRed }) => (isRed ? COLORS.red : COLORS.black)};
  ${TYPOGRAPHY.inputs}
  &:focus {
    border: 1px solid ${COLORS.darkBlue};
  }
`;
