import { StepsWrapper } from 'components';
import { StepperProvider, useStepper } from 'components/Stepper';
import { withRedirect } from 'lib/hocs';
import { CreateProfileSteps } from 'types';

import { GetPhoneContainer, StepProfileInfo, StepSmsCheck } from './components';

const createProfileSteps = [
  {
    label: CreateProfileSteps.getPhone,
    component: (
      <GetPhoneContainer
        title="Регистрация профиля"
        nextStep={CreateProfileSteps.sendCode}
      />
    ),
  },
  {
    label: CreateProfileSteps.sendCode,
    component: (
      <StepSmsCheck
        title="Регистрация профиля"
        nextStep={CreateProfileSteps.profileInfo}
        previousStep={CreateProfileSteps.getPhone}
      />
    ),
  },
  {
    label: CreateProfileSteps.profileInfo,
    component: <StepProfileInfo />,
  },
];

const Registration = () => {
  const stepper = useStepper({
    steps: createProfileSteps,
    defaultStep: CreateProfileSteps.getPhone,
  });

  return (
    <StepperProvider {...stepper}>
      <StepsWrapper />
    </StepperProvider>
  );
};

export default withRedirect(Registration);
