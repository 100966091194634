import { UserSendSmsResponseDto } from 'api';
import { COLORS } from 'assets/styles/colors';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { SmsCodeWithResend } from 'components';
import { useStepperDispatcher } from 'components/Stepper';
import { getDiffNowAndDateInSeconds } from 'lib/helpers';
import { FC, useEffect, useState } from 'react';
import {
  useCheckRegistrationVerificationMutation,
  useSendRegistrationVerificationMutation,
} from 'store/api/auth';
import { selectSignUpContext, setSignUpContext } from 'store/features/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { CustomError, Steps } from 'types';
import { BackButton, Logo, Stepper } from 'ui';

interface StepSmsCheckProps {
  title?: string;
  previousStep: Steps;
  nextStep: Steps;
}

export const StepSmsCheck: FC<StepSmsCheckProps> = ({
  title,
  previousStep,
  nextStep,
}) => {
  const dispatch = useAppDispatch();
  const [errorText, setErrorText] = useState('');
  const { switchStep } = useStepperDispatcher();
  const signUpContext = useAppSelector(selectSignUpContext);

  const [sendPhoneVerification, { isLoading: isSending }] =
    useSendRegistrationVerificationMutation();
  const [checkPhoneVerification, { isLoading: isChecking }] =
    useCheckRegistrationVerificationMutation();

  const secondsToNextAttempt = getDiffNowAndDateInSeconds(
    signUpContext.nextAttempt,
  );
  const canResendSms = secondsToNextAttempt <= 0;

  const setNextAttemptInState = (nextAttempt = '') => {
    dispatch(
      setSignUpContext({
        ...signUpContext,
        nextAttempt,
      }),
    );
  };

  const handleCodeTypingFinished = async (codeValue: string) => {
    setErrorText('');

    const data = {
      phone: signUpContext.phone,
      code: codeValue,
    };

    try {
      const { isSuccess, message } = await checkPhoneVerification(
        data,
      ).unwrap();

      if (!isSuccess) {
        return setErrorText(message);
      }

      dispatch(
        setSignUpContext({
          ...signUpContext,
          code: codeValue,
        }),
      );

      switchStep(nextStep);
    } catch (err) {
      const error = err as CustomError;
      setErrorText(error.data.message);
    }
  };

  const sendSms = () => {
    setErrorText('');
    sendPhoneVerification({ phone: signUpContext.phone })
      .unwrap()
      .then(({ isSuccess, nextAttempt }: UserSendSmsResponseDto) => {
        if (isSuccess) {
          setNextAttemptInState(nextAttempt);
        }
      })
      .catch(({ data }: CustomError) => {
        setErrorText(data.message);
      });
  };

  return (
    <Root>
      <Header>
        <StyledBackButton onClick={() => switchStep(previousStep)} />
        <Logo />
      </Header>
      <StyledStepper />

      <Title>{title}</Title>

      <SmsCodeWithResend
        isLoading={isSending}
        isDisabled={isChecking}
        canResendSms={canResendSms}
        secondsToNextAttempt={secondsToNextAttempt}
        error={errorText}
        phone={signUpContext.phone}
        onCodeTypingFinished={handleCodeTypingFinished}
        onRepeatSendClick={sendSms}
        onEndTimer={setNextAttemptInState}
      />
    </Root>
  );
};

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 29px;
`;

const StyledBackButton = styled(BackButton)`
  position: absolute;
  left: -10px;
  top: -2px;
`;

const StyledStepper = styled(Stepper)`
  margin-bottom: 67px;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title1}
  color: ${COLORS.black};
  margin-bottom: 28px;
`;

const Root = styled.div`
  position: relative;
  width: 100%;
`;
