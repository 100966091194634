import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';
import { baseApi } from 'store/api/baseApi';
import { logOut } from 'store/features/auth';

export const authMiddleware =
  ({ dispatch }: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: AnyAction): AnyAction => {
    if (action.type === logOut.type) {
      dispatch(baseApi.util.resetApiState());
    }

    return next(action);
  };
