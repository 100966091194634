import { ChildLockTimer } from 'components/ChildLockTimer';
import { useOnClickOutside } from 'lib/hooks';
import useChildLockSettings from 'lib/services/plonq/hooks/useChildLockSettings';
import { ChildLockSettings } from 'lib/services/plonq/plonq-child-lock';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'ui';
import { SwitchIcon } from 'ui/SwitchIcon';

export interface OverPuffControlProps {
  className?: string;
  isEnabled?: boolean;
}

export type ChildLockTimerValue = '300' | '600' | '900' | '0';

export const ChildLockTimerControl: FC<OverPuffControlProps> = ({
  className,
  isEnabled = false,
}) => {
  const [isOpenEditLimit, setIsOpenEditLimit] = useState(false);
  const overPuffControlWrapperRef = useRef(null);
  useOnClickOutside(overPuffControlWrapperRef, () => setIsOpenEditLimit(false));
  const [{ getSettings, setSettings }, hasChildLock] = useChildLockSettings();

  const [childLockSettings, setChildLockSettings] =
    useState<ChildLockSettings>();

  const [timer, setTimer] = useState<ChildLockTimerValue>('600');
  const [isChecked, setIsChecked] = useState<boolean>(isEnabled);

  const parseIntegerToTimerValue = (integer: number): ChildLockTimerValue => {
    if (integer <= 300) return '300';
    if (integer <= 600) return '600';
    return '900';
  };

  const handleGetChildLockSettings = async () => {
    const settings = await getSettings();

    setIsChecked(settings.time > 0);
    setTimer(parseIntegerToTimerValue(settings.time));
    setChildLockSettings(settings);
  };

  const handleSetChildLockSettings = async (value: ChildLockTimerValue) => {
    if (childLockSettings) {
      const settings = await setSettings({
        ...childLockSettings,
        time: Number.parseInt(value),
      });

      setIsChecked(settings.time > 0);
      setTimer(parseIntegerToTimerValue(settings.time));
      setChildLockSettings(settings);
    }
  };

  const toggleChildLockTimer = () => {
    if (isChecked) {
      handleSetChildLockSettings('0');
    } else {
      handleSetChildLockSettings(timer);
    }
  };

  useEffect(() => {
    handleGetChildLockSettings();
  }, []);

  return (
    /* eslint-disable no-constant-condition */
    <Root
      disabled={!hasChildLock}
      className={className}
      ref={overPuffControlWrapperRef}
    >
      <StyledSwitch
        label="Child lock таймер"
        onChange={toggleChildLockTimer}
        checked={isChecked}
        enabledIcon="childLockSettingsEnabled"
        disabledIcon="childLockSettingsDisabled"
      />
      <button onClick={() => setIsOpenEditLimit(!isOpenEditLimit)}>
        <Icon type="questionMark" />
      </button>
      {isOpenEditLimit && (
        <EditLimitWrapper>
          <ChildLockTimer
            onClose={() => setIsOpenEditLimit(!isOpenEditLimit)}
            timer={timer}
            onChange={handleSetChildLockSettings}
          />
        </EditLimitWrapper>
      )}
    </Root>
  );
};

const Root = styled.div<{ disabled: boolean }>`
  height: 56px;
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  align-items: center;
  padding-right: 2px;
  flex-wrap: wrap;
  position: relative;
  border-top: 1px solid #f2f1f7;
  border-bottom: 1px solid #f2f1f7;
`;

const StyledSwitch = styled(SwitchIcon)`
  flex: 1 1 auto;
`;

const EditLimitWrapper = styled.div`
  position: absolute;
  top: calc(100%);
  left: 0;
`;
