import throttle from 'lodash/throttle';
import { useLayoutEffect } from 'react';

export const use100vhHeight = () => {
  const updateHeight = throttle(() => {
    const doc = document.documentElement;
    doc.style.setProperty('--100vh-height', `${window.innerHeight}px`);
  }, 200);

  useLayoutEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);
};
