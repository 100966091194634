import { UserSendSmsResponseDto } from 'api';
import { StepGetPhone } from 'components';
import { useStepperDispatcher } from 'components/Stepper';
import { ERROR_TEXTS } from 'lib/constants';
import { FC, useState } from 'react';
import { useSendRestorePasswordVerificationMutation } from 'store/api/auth';
import {
  selectPasswordRestoreContext,
  setPasswordRestoreContext,
} from 'store/features/auth';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { CustomError, Steps } from 'types';

interface GetPhoneContainerProps {
  title?: string;
  nextStep: Steps;
}

export const GetPhoneContainer: FC<GetPhoneContainerProps> = ({
  title,
  nextStep,
}) => {
  const { switchStep } = useStepperDispatcher();
  const [errorText, setErrorText] = useState('');
  const dispatch = useAppDispatch();
  const [sendRestoreVerification, { isLoading }] =
    useSendRestorePasswordVerificationMutation();
  const passwordRestoreContext = useAppSelector(selectPasswordRestoreContext);

  const handleSubmit = ({ phone }: { phone: string }) => {
    setErrorText('');
    sendRestoreVerification({ phone })
      .unwrap()
      .then(({ isSuccess, nextAttempt }: UserSendSmsResponseDto) => {
        if (isSuccess) {
          dispatch(
            setPasswordRestoreContext({
              ...passwordRestoreContext,
              nextAttempt,
              phone,
            }),
          );
          switchStep(nextStep);
        } else {
          setErrorText(ERROR_TEXTS.somethingWentWrong);
        }
      })
      .catch(({ data }: CustomError) => {
        setErrorText(data.message);
      });
  };

  return (
    <StepGetPhone
      title={title}
      error={errorText}
      isLoading={isLoading}
      defaultPhoneValue={passwordRestoreContext.phone}
      onSubmit={handleSubmit}
    />
  );
};
