import { FC } from 'react';
import styled from 'styled-components';
import { Tag } from 'ui';

export interface FiltersProps {
  data: { id: string; alias: string }[];
  onSubmit?: (id: string) => void;
  activeItemId: string;
}

export const Filters: FC<FiltersProps> = ({ data, activeItemId, onSubmit }) => {
  return (
    <Root>
      {data?.map((item) => {
        return (
          <ListItem key={item.id}>
            <Tag
              isActive={activeItemId === item.id}
              onClick={() => onSubmit?.(item.id)}
            >
              {item.alias}
            </Tag>
          </ListItem>
        );
      })}
    </Root>
  );
};

const Root = styled.ul`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  margin-right: 5px;
  margin-bottom: 5px;
`;
