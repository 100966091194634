import { COLORS, TRANSPARENCY_COLORS } from 'assets/styles/colors';
import { SIZE } from 'assets/styles/devices';
import { TYPOGRAPHY } from 'assets/styles/typography';
import { ReactComponent as Arrow } from 'assets/svg/arrow-date-back.svg';
import dayjs from 'dayjs';
import { CompareVersion } from 'lib/helpers/compareVersions';
import { VERSION_FRACTURE } from 'lib/services/constants';
import useDeviceState from 'lib/services/plonq/hooks/useDeviceState';
import usePuffArrayUpload from 'lib/services/plonq/hooks/usePuffArrayUpload';
import { PlonqDevice } from 'lib/services/plonq/plonq-device';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/route-paths';
import { useGetDeviceListQuery } from 'store/api/devices';
import { useGetFirstAvailableFirmwareQuery } from 'store/api/firmware';
import {
  useGetStatisticsDailyQuery,
  useGetStatisticsQuery,
} from 'store/api/statistics';
import styled, { css } from 'styled-components';
import { StatisticDateUnits } from 'types';
import { AverageValues } from 'ui/AverageValues';
import { FirmwareUpdateModal } from 'ui/FirmwareUpdateModal';
import { Histogram } from 'ui/Histogram/Histogram';

import { Filters } from './components/Filters';

const FIRST_FILTER_ITEM_ID = 'full';

/**
 * Экран статистики.
 *
 * Требует подключенного {@link PlonqDevice} и инициализированного {@link useDeviceState}
 *
 * Если устройство не подключено пользователя перенаправит на экран со списком устройств.
 * Если версия прошивки устройства ниже необходимой,
 * пользователя перенаправит на страницу обновления прошивки.
 */
export const Statistics = () => {
  const { data } = useGetDeviceListQuery();
  const { deviceId } = useParams();
  const navigate = useNavigate();
  const [deviceState, setDeviceState] = useDeviceState();

  const [firmwareUpdateModalOpen, setIsFirmwareUpdateModalOpen] =
    useState(false);

  const isInitializing = usePuffArrayUpload(deviceId as string);

  const dateNow = dayjs();
  const localDateNow = dayjs().locale('ru');

  const [interval, setInterval] = useState<StatisticDateUnits>(
    StatisticDateUnits.WEEK,
  );

  const [timeToShow, setTimeToShow] = useState({
    start: localDateNow.startOf(interval),
    end: localDateNow.endOf(interval),
  });

  const intervalStart = timeToShow.start.locale('ru').format('DD MMMM');
  const intervalEnd = timeToShow.end.locale('ru').format('DD MMMM');

  const isLaterThanNow = timeToShow.end >= dateNow.subtract(1, 'hour');

  function handleIntervalTypeButtonsClick(event: React.MouseEvent) {
    const target = event.target as HTMLButtonElement;
    const value = target.value as StatisticDateUnits;

    setInterval(value);
    setTimeToShow({
      start: localDateNow.startOf(value),
      end: localDateNow.endOf(value),
    });
  }

  const { data: statistics, isFetching: isStatisticsFetching } =
    useGetStatisticsQuery({
      startDateRange: timeToShow.start.toISOString(),
      endDateRange: timeToShow.end.toISOString(),
      dateUnit: interval,
      timezoneOffset: new Date().getTimezoneOffset() / 60,
    });

  const { data: statisticsDaily, isFetching: isStatisticsDailyFetching } =
    useGetStatisticsDailyQuery({
      timezoneOffset: new Date().getTimezoneOffset() / 60,
    });

  const { data: firmware } = useGetFirstAvailableFirmwareQuery(
    { deviceId: deviceId as string },
    { skip: !deviceId },
  );

  useEffect(() => {
    if (!deviceState) {
      const deviceInstance = PlonqDevice.getInstance();

      if (deviceInstance?.checkIsBleConnected()) {
        setDeviceState(deviceInstance);
      } else {
        navigate(ROUTES.ROOT);
      }
    }
  }, [deviceState]);

  useEffect(() => {
    if (firmware && deviceId) {
      CompareVersion(PlonqDevice.getInstance().getFirmware(), VERSION_FRACTURE)
        ? navigate(ROUTES.UPDATED_DEVICE_ID(deviceId))
        : setIsFirmwareUpdateModalOpen(true);
    }
  }, [deviceId, firmware]);

  const [activeFilter, setActiveFilter] = useState(FIRST_FILTER_ITEM_ID);

  const filtersData = useMemo(() => {
    if (!data) return;

    const mappedDevices = data.devices.map((device) => ({
      id: device.id,
      alias: device.alias ?? device.modelName,
    }));

    const mappedDevicesWithFull = [
      {
        id: FIRST_FILTER_ITEM_ID,
        alias: 'Все',
      },
      ...mappedDevices,
    ];

    return mappedDevicesWithFull;
  }, [data]);

  const statisticsSource = useMemo(() => {
    if (!statistics) return;

    switch (activeFilter) {
      case FIRST_FILTER_ITEM_ID: {
        return statistics.full;
      }
      default: {
        const requiredDevice = statistics.devices.find(
          (device) => device.id === activeFilter,
        );

        return requiredDevice?.statistics;
      }
    }
  }, [statistics, activeFilter]);

  const currentDeviceDailyStatistics = useMemo(() => {
    return statisticsDaily?.devices.find((d) => d.id === deviceId);
  }, [statisticsDaily, deviceId]);

  return (
    <>
      <StatsContainer>
        <AverageValues
          puffsValue={
            currentDeviceDailyStatistics?.statistics?.totalForPeriodPuffs || 0
          }
          puffsAverageValue={
            currentDeviceDailyStatistics?.statistics?.averageForPeriodPuffs || 0
          }
          nicotineValue={
            currentDeviceDailyStatistics?.statistics?.totalForPeriod || 0
          }
          nicotineAverageValue={
            currentDeviceDailyStatistics?.statistics?.averageForPeriod || 0
          }
          isArrowUp={
            currentDeviceDailyStatistics?.statistics?.statDynamic === 'increase'
          }
          isLoading={isStatisticsDailyFetching || isInitializing}
          dateUnit={statisticsDaily?.dateUnit || 'day'}
          intervalStart={localDateNow}
          intervalEnd={timeToShow.end.locale('ru')}
        />
        <HistogramContainer>
          <IntervalsTypesContainer
            onClick={(event) => handleIntervalTypeButtonsClick(event)}
          >
            <IntervalTypeButton
              value={StatisticDateUnits.DAY}
              isActive={interval === StatisticDateUnits.DAY}
            >
              День
            </IntervalTypeButton>
            <IntervalTypeButton
              value={StatisticDateUnits.WEEK}
              isActive={interval === StatisticDateUnits.WEEK}
            >
              Неделя
            </IntervalTypeButton>
            <IntervalTypeButton
              value={StatisticDateUnits.MONTH}
              isActive={interval === StatisticDateUnits.MONTH}
            >
              Месяц
            </IntervalTypeButton>
          </IntervalsTypesContainer>
          <IntervalControlContainer>
            <ArrowButton
              onClick={() => {
                setTimeToShow({
                  start: timeToShow.start
                    .subtract(1, interval)
                    .startOf(interval),
                  end: timeToShow.end.subtract(1, interval).endOf(interval),
                });
              }}
            >
              <Arrow />
            </ArrowButton>
            <IntervalText>
              {interval === StatisticDateUnits.DAY
                ? intervalStart
                : `${intervalStart} - ${intervalEnd}`}
            </IntervalText>
            <ArrowButton
              onClick={() => {
                setTimeToShow({
                  start: timeToShow.start.add(1, interval).startOf(interval),
                  end: timeToShow.end.add(1, interval).endOf(interval),
                });
              }}
              disabled={isLaterThanNow}
            >
              <ArrowForward disabled={isLaterThanNow} />
            </ArrowButton>
          </IntervalControlContainer>
          <HistogramWrap>
            {statistics && statisticsSource && (
              <Histogram
                width={318}
                height={450}
                puffs={statisticsSource.nodes}
                dateUnit={statistics.dateUnit}
                isFetching={isStatisticsFetching}
                startDate={timeToShow.start.toISOString()}
              />
            )}
          </HistogramWrap>
          {data && data.devices.length > 0 && (
            <FiltersWrapper>
              <Filters
                data={filtersData!}
                activeItemId={activeFilter}
                onSubmit={setActiveFilter}
              />
            </FiltersWrapper>
          )}
        </HistogramContainer>
        <AverageValues
          puffsValue={statisticsSource?.totalForPeriodPuffs || 0}
          puffsAverageValue={statisticsSource?.averageForPeriodPuffs || 0}
          nicotineValue={statisticsSource?.totalForPeriod || 0}
          nicotineAverageValue={statisticsSource?.averageForPeriod || 0}
          isArrowUp={statisticsSource?.statDynamic === 'increase'}
          isLoading={isStatisticsFetching || isInitializing}
          dateUnit={statistics?.dateUnit || 'day'}
          intervalStart={timeToShow.start.locale('ru')}
          intervalEnd={timeToShow.end.locale('ru')}
        />
      </StatsContainer>
      {!isInitializing &&
        firmwareUpdateModalOpen &&
        deviceId &&
        deviceState && (
          <FirmwareUpdateModal
            deviceId={deviceId}
            currentVersion={deviceState?.firmwareVersion}
            onClose={() =>
              firmware?.firmwareVersion !== VERSION_FRACTURE &&
              setIsFirmwareUpdateModalOpen(false)
            }
            onSubmit={() => navigate(ROUTES.UPDATED_DEVICE_ID(deviceId))}
          />
        )}
    </>
  );
};

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HistogramContainer = styled.div`
  width: 100%;
  max-width: 366px;
  min-height: 434px;
  background-color: ${COLORS.white};
  border-radius: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const IntervalsTypesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 22px 24px 0;
`;

const IntervalTypeButton = styled.button<{ isActive?: boolean }>`
  color: ${TRANSPARENCY_COLORS.darkBlueHalf};
  width: 100%;
  max-width: 94px;
  height: 36px;
  border-radius: 10px;
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${COLORS.lightViolet};
      color: ${COLORS.black};
    `}
  ${TYPOGRAPHY.subtitle2}
`;

const IntervalControlContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const IntervalText = styled.p`
  color: ${COLORS.darkBlue};
  ${TYPOGRAPHY.subtitle1}
`;

const ArrowButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 50px;
`;

const ArrowForward = styled(Arrow)<{ disabled?: boolean }>`
  transform: rotate(180deg);
  path {
    ${({ disabled }) =>
      disabled &&
      css`
        fill-opacity: 0.25;
      `}
  }
`;

const HistogramWrap = styled.div`
  width: 318px;
  height: 271px;
  margin: 29px 22px 16px 24px;

  @media (max-width: ${SIZE.mobileM}) {
    width: 300px;
  }
`;

const FiltersWrapper = styled.div`
  margin: 0 22px 18px 24px;
`;
