export type Maybe<T> = T | null;

export enum HttpMethods {
  post = 'POST',
  get = 'GET',
  put = 'PUT',
  patch = 'PATCH',
  delete = 'DELETE',
  request = 'REQUEST',
}

export enum StorageKeys {
  DATE_OF_BIRTH = 'date_of_birth ',
}

export enum ElementInputType {
  tel = 'tel',
  password = 'password',
  text = 'text',
}

export enum PasswordRestoreSteps {
  getPhone = 'get-phone',
  sendCode = 'send-code',
  newPassword = 'enter-new-password',
  restoreSuccess = 'restore-success',
}

export enum CreateProfileSteps {
  getPhone = 'get-phone',
  sendCode = 'send-code',
  profileInfo = 'profile-info',
}

export type Steps = PasswordRestoreSteps | CreateProfileSteps;

export interface CustomError {
  data: {
    error: string;
    message: string;
    statusCode: number;
  };
  status: number;
}

export enum PowerSettings {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum StatisticDateUnits {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum LocalizeTimeType {
  day = 'день',
  week = 'неделю',
  month = 'месяц',
}
